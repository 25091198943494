import React, { useContext } from 'react';
import moment from 'moment';
import SignaturePad from 'react-signature-canvas';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip, InputLabel, Grid, TableCell, TableRow, Paper, FormHelperText, Typography, CircularProgress, IconButton, Hidden } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {
    SecondaryButton,
    InputText,
    FailButton,
    PassButton,
    NAButton,
    MButton,
    RButton,
    BackButton,
    DatePicker,
    SelectBox,
    formatters,
    SimpleField
} from '../../../../../Core/Controls';
import DeleteIcon from '@material-ui/icons/Delete';
import EditAxle from './EditInspectionCheck/editAxel';
import '../../controllerStyle.scss';
import ActionTakenModal from './EditInspectionCheck/actionTakenModal';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import ConfirmationModal from '../../../../../Core/Modal/ConfirmationModal';
import ImageModal from '../DriverDailyChecks/imageModal';
import { EditInapectionContaxt, EditInspectionProvide } from './EditInspectionCheck/editInspectionProviderImports';
import { EditInspectionBreakTest } from './EditInspectionCheck/editInspectionbreakTest';
import { EditInspectionBrakePerformance } from './EditInspectionCheck/editInspectionBP';
import { EditInspectionRoadTest } from './EditInspectionCheck/editInspaectionRoadTest';
import { EditInspection } from './EditInspectionCheck/editInspection';
import { EditSInspectionSignOFF } from './EditInspectionCheck/editSignOff';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import ImageUpload from './InspectionImageUpload';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
        // marginTop: 100
    },
    paper: {
        padding: theme.spacing(2),
        // color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        width: '100%'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0,
        marginLeft: -4,
        marginBottom: 20
    }
}));

const CheckButtons = {
    S: PassButton,
    D: FailButton,
    M: MButton,
    N: NAButton,
    R: RButton
};

const CheckButtonsBorder = {
    D: 'red',
    M: 'orange',
    N: 'blue',
    S: 'green',
    R: 'red'
};

const IconColors = {
    fontSize: 25,
    color: 'white'
};
const fontColors = {
    fontSize: 16,
    color: 'white'
};

const ButtonColors = {
    D: { color: '#d32f2f', value: () => <ClearIcon style={IconColors} /> },
    M: { color: '#f57f17', value: () => <span style={fontColors}>M</span> },
    N: { color: '#3f51b5', value: () => <span style={fontColors}>N/A</span> },
    S: { color: '#388e3c', value: () => <DoneIcon style={IconColors} /> }
};

const EditVehicleDailyChecks = (props) => {
    const classes = useStyles();
    const {
        checkhaveAccess,
        widths,
        portalSettings,
        btnLoader,
        confirmBtnLoader,
        state,
        flags,
        isModal,
        handleInput,
        // clear,
        // GetTechnicianSign,
        onImageChange,
        // useDefaultSig,
        submitData,
        closeActionTakenhandle,
        confirmHandle,
        sumbitImage,
        setState,
        // signCanvas,
        imgLoader,
        RemoveImage
    } = useContext(EditInapectionContaxt);

    const GlobalCss = withStyles((theme) => ({
        '@global': {
            '.MuiDialogContent-root': {
                padding: '10px',
                [theme.breakpoints.down('sm')]: {
                    padding: '0px' // Customize the padding for 'sm' and up screens
                }
            }
        }
    }))(() => null);

    return (
        <>
            <GlobalCss />
            <DialogComp onClose={props.onClose} title={'Signoff Inspection'} maxWidth="xl" fullWidth fullScreen>
                <div style={{ height: `calc(100vh - 108px)`, overflow: 'auto', padding: 10 }} className="custom-scroll">
                    {!state.load ? (
                        <Grid container spacing={2} justify="space-between">
                            {props.frompage ? (
                                <Grid item xs={12} sm={12} md={12} style={{ padding: '0px 8px' }}>
                                    <Typography variant={'h5'} style={{ fontSize: widths < 500 ? 17 : '' }}>
                                        <BackButton onClick={props.onClose} />
                                        {state.vehicleInfo?.toUpperCase() || ''}
                                    </Typography>
                                </Grid>
                            ) : null}
                            <Grid item container xs={12} spacing={2} alignItems="center" justify="space-between">
                                {/* <Grid item md={2} lg={2} xl={1}>
                            <InputLabel shrink>Calibration Date</InputLabel>
                            <b>
                                <Typography variant={'h7'}>{formatters.ReadOnlyDateFormatter(state.vehicleNextCalibrationDate) || 'N/A'}</Typography>
                            </b>
                        </Grid>
                        <Grid item md={2} lg={2} xl={1}>
                            <InputLabel shrink>Loler Date</InputLabel>
                            <b>
                                <Typography variant={'h7'}>{formatters.ReadOnlyDateFormatter(state.vehicleNextLolerTestDate) || 'N/A'}</Typography>
                            </b>
                        </Grid> */}
                                <Grid item md={12} lg={8} xl={12}>
                                    <Grid container alignItems="center" justify="flex-end" spacing={2}>
                                        {/* {state.buttonList?.map((k) => {
                                    let Comp = CheckButtons[k.inspectionSheetCheckOptionCode];
                                    return (
                                        <Grid item>
                                            <>
                                                <IconButton
                                                    style={{
                                                        // background: CheckButtonsBorder[k.inspectionSheetCheckOptionCode],
                                                        borderRadius: 0,
                                                        height: 10,
                                                        width: '100%',
                                                        padding: 10,
                                                        fontSize: 12
                                                    }}
                                                >
                                                    <Comp state={true} />
                                                    <div
                                                        item
                                                        style={{
                                                            padding: 8,
                                                            fontSize: 13,
                                                            fontWeight: 600,
                                                            color: CheckButtonsBorder[k.inspectionSheetCheckOptionCode]
                                                            // background: CheckButtonsBorder[k.inspectionSheetCheckOptionCode]
                                                        }}
                                                    >
                                                        {k.inspectionSheetCheckOptionDescription}
                                                    </div>
                                                </IconButton>
                                            </>
                                        </Grid>
                                        // </div>
                                    );
                                })} */}

                                        <Hidden only={['md', 'lg', 'xl']}>
                                            {state.buttonList?.map((k) => {
                                                const Icon = ButtonColors[k.inspectionSheetCheckOptionCode].value;

                                                return (
                                                    <Grid item xs={4} sm={4} md={2} key={k.inspectionSheetCheckOptionCode}>
                                                        <IconButton
                                                            style={{
                                                                background: ButtonColors[k.inspectionSheetCheckOptionCode].color,
                                                                borderRadius: 0,
                                                                height: 30,
                                                                width: '100%',
                                                                padding: 10,
                                                                fontSize: 12,
                                                                color: 'white'
                                                            }}
                                                        >
                                                            {' '}
                                                            <div
                                                                style={{
                                                                    padding: 8,
                                                                    fontSize: 12,
                                                                    fontWeight: 600,
                                                                    color: 'white'
                                                                }}
                                                            >
                                                                {k.inspectionSheetCheckOptionDescription}
                                                            </div>
                                                        </IconButton>
                                                    </Grid>
                                                );
                                            })}
                                        </Hidden>
                                        <Hidden only={['sm', 'xs']}>
                                            {state.buttonList?.map((k) => {
                                                const Icon = ButtonColors[k.inspectionSheetCheckOptionCode].value;

                                                return (
                                                    <Grid item key={k.inspectionSheetCheckOptionCode}>
                                                        <IconButton
                                                            style={{
                                                                background: ButtonColors[k.inspectionSheetCheckOptionCode].color,
                                                                borderRadius: 0,
                                                                height: 20,
                                                                width: '100%',
                                                                padding: 10,
                                                                fontSize: 12,
                                                                color: 'white'
                                                            }}
                                                        >
                                                            <Icon /> {/* Render the icon component here */}
                                                            <div
                                                                style={{
                                                                    padding: 8,
                                                                    fontSize: 13,
                                                                    fontWeight: 600,
                                                                    color: 'white'
                                                                }}
                                                            >
                                                                {k.inspectionSheetCheckOptionDescription}
                                                            </div>
                                                        </IconButton>
                                                    </Grid>
                                                );
                                            })}
                                        </Hidden>
                                        {/* {state.buttonList?.map((k) => {
                                            const Icon = ButtonColors[k.inspectionSheetCheckOptionCode].value;

                                            return (
                                                <Grid item key={k.inspectionSheetCheckOptionCode}>
                                                    <IconButton
                                                        style={{
                                                            background: ButtonColors[k.inspectionSheetCheckOptionCode].color,
                                                            borderRadius: 0,
                                                            height: 20,
                                                            width: '100%',
                                                            padding: 10,
                                                            fontSize: 12,
                                                            color: 'white'
                                                        }}
                                                    >
                                                       
                                                        <div
                                                            style={{
                                                                padding: 8,
                                                                fontSize: 13,
                                                                fontWeight: 600,
                                                                color: 'white'
                                                            }}
                                                        >
                                                            {k.inspectionSheetCheckOptionDescription}
                                                        </div>
                                                    </IconButton>
                                                </Grid>
                                            );
                                        })} */}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} sm md lg xl>
                                <div className="input-style">
                                    <InputLabel shrink className="input_label">
                                        Calibration Date
                                    </InputLabel>
                                    <div className="input_value">{formatters.ReadOnlyDateFormatter(state.vehicleNextCalibrationDate) || 'N/A'}</div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm md lg xl>
                                <div className="input-style">
                                    <InputLabel shrink className="input_label">
                                        Tacho Calibration Date
                                    </InputLabel>
                                    <div className="input_value">{formatters.ReadOnlyDateFormatter(state.vehicleNextTachoCalibrationDate) || 'N/A'}</div>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm md lg xl>
                                <div className="input-style">
                                    <InputLabel shrink className="input_label">
                                        Date Of Check
                                    </InputLabel>
                                    <div className="input_value">{state.date || 'N/A'}</div>
                                </div>
                                {/* <DatePicker
                            placeholder="Date Of Check"
                            name="date"
                            onChange={handleInput}
                            disabled={!checkhaveAccess.CanUpdateSchedules}
                            value={state.date}
                            InputProps={{
                                inputProps: { min: moment().subtract(6, 'd').format('YYYY-MM-DD'), max: moment().format('YYYY-MM-DD') }
                            }}
                        /> */}
                            </Grid>
                            <Grid item xs={6} sm md lg xl>
                                <InputLabel shrink>Check carried out by</InputLabel>
                                <SimpleField
                                    placeholder="Check carried out by"
                                    name="vehicleDailyCheckCheckerName"
                                    value={state.vehicleDailyCheckCheckerName}
                                    onChange={handleInput}
                                    disabled
                                    isDisabled
                                />
                            </Grid>
                            {flags.displayMileage && (
                                <Grid item xs={6} sm md lg xl>
                                    <InputLabel shrink>Current Mileage</InputLabel>
                                    <SimpleField
                                        type="number"
                                        placeholder="Current Mileage"
                                        name="vehicleDailyCheckMileage"
                                        // name="vehicleDailyCheckMileage"
                                        onChange={handleInput}
                                        value={state.vehicleDailyCheckMileage}
                                        // value={state.vehicleDailyCheckMileage}
                                        isDisabled
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={12} lg={6} className="mandatory-fields">
                                <InputLabel shrink>Slippage Reason</InputLabel>
                                <SelectBox
                                    List={state.slippageReasons}
                                    name="vehicleInspectionSlippageReasonID"
                                    onChange={handleInput}
                                    value={state.vehicleInspectionSlippageReasonID}
                                />
                            </Grid>
                            {/* Inspections */}
                            <EditInspection classes={classes} StyledTableCell={StyledTableCell} StyledTableRow={StyledTableRow} CheckButtons={CheckButtons} />
                            {/* Tyre checks column */}
                            {flags.displayTyres && (
                                <Grid item container direction="row" xs={12} sm={12}>
                                    <EditAxle state={state} classes={classes} vehicleNoOfAxles={state.vehicleNoOfAxles} />
                                </Grid>
                            )}
                            {flags.displayBrakeTest && <EditInspectionBreakTest classes={classes} />}
                            {flags.displayBrakePerformance && <EditInspectionBrakePerformance classes={classes} />}
                            {flags.displayRoadTest && <EditInspectionRoadTest classes={classes} />}
                            {/* Vehicle SignOff page */}
                            <Grid item container xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container xs={12}>
                                        <h3 className={classes.headings}>Vehicle Inspector</h3>
                                    </Grid>
                                    <Grid container xs={12}>
                                        <div
                                            className={classes.headings}
                                            style={{ marginTop: -20 }}
                                            dangerouslySetInnerHTML={{ __html: portalSettings.portalSettingTechnicianDeclaration }}
                                        />
                                    </Grid>
                                    <Grid container spacing={1} justify="space-between">
                                        <Grid item container spacing={1} xs={12} md={6}>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <InputLabel shrink>Vehicle Checker Comment</InputLabel>
                                                <Typography variant="button">{state.vehicleInspectionInspectionComment}</Typography>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                        <Grid spacing={1} container direction="row">
                                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                                <InputLabel shrink>Current Mileage</InputLabel>
                                                <Typography variant="button">{state.vehicleDailyCheckMileage}</Typography>
                                            
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                        </Grid>
                                        <Grid item spacing={1} xs={12} md={6}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <div style={{ position: 'relative' }}>
                                                    <InputLabel shrink>Signature</InputLabel>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            right: 0
                                                        }}
                                                    ></div>
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            right: 5,
                                                            bottom: 0,
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'flex-end'
                                                        }}
                                                    >
                                                        <Typography variant="body1">{state.vehicleDailyCheckCheckerName}</Typography>
                                                        <Typography variant="button">
                                                            {moment(state.vehicleInspectionDateInspected).format('DD/MM/YYYY HH:mm')}
                                                        </Typography>
                                                    </div>

                                                    {state.imgtechniciansign ? (
                                                        <div className="sigContainer">
                                                            <img src={state.imgtechniciansign} style={{ width: '100%', height: '100%' }} alt="my signature" />
                                                        </div>
                                                    ) : (
                                                        <div className="sigContainer">
                                                            <Typography variant="button">No Signature Found</Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            {checkhaveAccess?.CanUpdateVehicleInspections && <EditSInspectionSignOFF classes={classes} />}
                            <Grid item container spacing={2} xs={12} lg={12}>
                                {state.isSignOff || state.vehicleInspectionStatusID === 2 ? null : (
                                    <>
                                        {!state.hideSignOff && (
                                            <Grid item xs={12} sm={6}>
                                                {/* {state.isCustomerSignOff ? (
                                            <SecondaryButton
                                                isBtnLoader={btnLoader}
                                                onClick={confirmHandle}
                                                disabled={!checkhaveAccess?.CanUpdateVehicleInspections}
                                            >
                                                Customer Sign Off
                                            </SecondaryButton> */}
                                                {/* // ) : ( */}
                                                <SecondaryButton
                                                    isBtnLoader={btnLoader}
                                                    onClick={confirmHandle}
                                                    disabled={!checkhaveAccess?.CanUpdateVehicleInspections}
                                                >
                                                    Sign Off
                                                </SecondaryButton>
                                                {/* )} */}
                                            </Grid>
                                        )}
                                    </>
                                )}
                                <Grid item xs={12} sm={state.isSignOff || state.vehicleInspectionStatusID === 2 ? 12 : state.hideSignOff ? 12 : 6}>
                                    <SecondaryButton onClick={() => props.onClose()}>Cancel</SecondaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <div className={classes.loader}>
                            <CircularProgress color="secondary" />
                        </div>
                    )}
                    {/* Checks Table */}

                    {isModal.actionTaken ? (
                        <DialogComp title="Defect Action" onClose={() => closeActionTakenhandle(false)} maxWidth="md" overflow="auto" fullWidth>
                            <ActionTakenModal selectedCheck={isModal.selectedCheck} onClose={closeActionTakenhandle} />
                        </DialogComp>
                    ) : null}

                    {isModal.showConfirm ? (
                        <DialogComp
                            title={`Confirm ${state.isCustomerSignOff ? 'Customer' : ''} Sign Off`}
                            onClose={() => closeActionTakenhandle(false)}
                            maxWidth="sm"
                        >
                            <ConfirmationModal
                                message={`Please note that you will not be able to make any changes once submitted. Click the Confirm button to proceed or Cancel to abort.`}
                                handleSubmit={submitData}
                                handleCancel={() => closeActionTakenhandle(false)}
                                title={`Confirm ${state.isCustomerSignOff ? 'Customer' : ''} Sign Off`}
                                isBtnLoader={confirmBtnLoader}
                            />
                        </DialogComp>
                    ) : null}
                    {state.showImageModal ? (
                        // <DialogComp title="Upload Image" onClose={() => setState((st) => ({ ...st, show: false }))} maxWidth="lg">
                        // <ImageModal
                        //     images={state.selectedImages}
                        //     onImageChange={onImageChange}
                        //     submitImage={sumbitImage}
                        //     disabled={state.isSignOff}
                        //     onClose={() => setState((st) => ({ ...st, showImageModal: false }))}
                        //     maxNumber={10}
                        // />
                        <ImageUpload
                            disabled={state.isDisabled}
                            state={state}
                            isAPI={state.fromApi}
                            onImageChange={onImageChange}
                            RemoveImage={RemoveImage}
                            submitImage={sumbitImage}
                            onClose={() => setState((st) => ({ ...st, showImageModal: false }))}
                            maxNumber={10}
                            loader={false}
                        />
                    ) : // </DialogComp>
                    null}
                </div>
            </DialogComp>
        </>
    );
};

const Screen = (props) => {
    return (
        <EditInspectionProvide {...props}>
            <EditVehicleDailyChecks {...props} />
        </EditInspectionProvide>
    );
};
export default Screen;
