import {
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    withStyles
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { formatters, MandatoryField, SecondaryButton, SelectBox, SimpleField } from '../../../../../../Core/Controls';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'lodash';
import { getPartListByPartNumber } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { Pagination } from '@material-ui/lab';
import DepotFilterList from '../../../../../../Core/Controls/LocationField/depotFilterList';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'lightgrey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 12,
        fontWeight: 700,
        padding: 5,
        backgroundColor: 'lightgrey'
    },
    body: {
        fontSize: 14,
        padding: 5
    }
}))(TableCell);

export const MatchingPartList = ({ loading, handleRowSelect, state, masterWarehouseCode = null, ServiceAreaID }) => {
    const { showToastSuccess, showToastError } = useToast();
    const { userRoles } = useLoginInfo();

    const quantityRef = useRef(null);

    const isEngineer = userRoles.includes('engineer');

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10; // Adjust this as needed

    const [list, setList] = useState([]);
    const [filteredData, setFilteredData] = useState(list);
    const [part, setPart] = useState({});
    const [parObj, setPartObj] = useState({
        filterPart: '',
        warehouseCode: masterWarehouseCode || '',
        warehouseDescription: '',
        vehicleJobPartStatus: '',
        PartStatusDescription: '',
        quantity: '',
        partName: '',
        partID: ''
    });
    const [flag, setFlag] = useState({
        loader: false,
        index: -1,
        showOtherFields: false,
        screenLoader: true
    });

    useEffect(() => {
        pullDataByPartNumber();
    }, [parObj.warehouseCode]);

    const pullDataByPartNumber = useCallback(
        debounce(async () => {
            if (parObj.warehouseCode) {
                setFlag((st) => ({ ...st, loader: true }));
                try {
                    let res = await getPartListByPartNumber(parObj.partName, ServiceAreaID, parObj.warehouseCode);
                    if (res.success) {
                        let warehouseDescription = '';
                        if (parObj.warehouseCode) {
                            let warehouseList = [...state.warehouse];
                            warehouseDescription = warehouseList.find((k) => k.id === parObj.warehouseCode)?.name || '';
                        }

                        setList(res.data.partDetails);
                        setFilteredData(res.data.partDetails);
                        setPartObj((st) => ({ ...st, warehouseDescription: warehouseDescription }));
                        setFlag((st) => ({ ...st, screenLoader: false }));
                    } else {
                        console.error('Failed to fetch data');
                        setFlag((st) => ({ ...st, loader: false, screenLoader: false }));
                    }
                } catch (error) {
                    console.error('Error fetching data');
                    setPartObj((st) => ({ ...st, loading: false }));
                }
                setFlag((st) => ({ ...st, loader: false }));
            } else {
                setFlag((st) => ({ ...st, screenLoader: false }));
                console.error('Please enter part number to fetch data');
            }
        }, 500),
        [parObj.partName, parObj.warehouseCode]
    );

    useEffect(() => {
        try {
            if (Array.isArray(list) && parObj.filterPart !== undefined) {
                const filterTerm = parObj.filterPart?.toLowerCase() || '';
                if (filterTerm.length > 0) {
                    const filtered = list.filter(
                        (item) => (item.pn && item.pn.toLowerCase().includes(filterTerm)) || (item.pd && item.pd.toLowerCase().includes(filterTerm))
                    );
                    setFilteredData(filtered);
                } else {
                    setFilteredData(list);
                }
                setCurrentPage(1); // Reset to the first page whenever the filter changes
            } else {
                // Handle the case where `list` is not an array or `parObj.filterPart` is undefined
                console.error('Invalid data or filter parameter');
                setFilteredData([]);
            }
        } catch (error) {
            // Handle any unexpected errors
            console.error('Error filtering data:', error);
            setFilteredData([]);
        }
    }, [list, parObj.filterPart]);

    let selectRow = (id, idx, partNo, partID) => {
        let partObject = list.find((m) => m.id === id);
        setPart(partObject);
        setFlag((st) => ({ ...st, index: idx, showOtherFields: true }));
        if (partID === 9337) {
            setPartObj((st) => ({
                ...st,
                partName: partNo,
                partID: partID,
                warehouseCode: 'M1',
                warehouseDescription: 'M1 - Mileage',
                vehicleJobPartStatus: 'M',
                PartStatusDescription: 'Travel'
            }));
        } else if (partID === 9338) {
            setPartObj((st) => ({
                ...st,
                partName: partNo,
                partID: partID,
                warehouseCode: 'C1',
                warehouseDescription: 'C1 - Consumables',
                vehicleJobPartStatus: 'I',
                PartStatusDescription: 'Fitted'
            }));
        } else {
            setPartObj((st) => ({
                ...st,
                // filterPart: partNo,
                partName: partNo,
                partID: null
            }));
        }

        setPartObj((st) => ({
            ...st,
            // filterPart: partNo,
            partName: partNo
        }));
    };

    const validations = () => {
        let isValid = true;
        const { quantity, warehouseCode, vehicleJobPartStatus, partID } = parObj;
        if (!quantity) {
            showToastError('Quantity is Required');
            isValid = false;
        }
        if (!warehouseCode) {
            showToastError('Warehouse is Required');
            isValid = false;
        }
        if (!partID && !vehicleJobPartStatus) {
            showToastError('Part Status is Required');
            isValid = false;
        }

        return isValid;
    };

    const SubmitParts = () => {
        if (validations()) {
            let data = { ...part, ...parObj };
            handleRowSelect(data);
            setPartObj((st) => ({
                ...st,
                vehicleJobPartStatus: '',
                PartStatusDescription: '',
                quantity: '',
                filterPart: '',
                partName: '',
                partID: null
            }));
            setFlag((st) => ({
                ...st,
                index: -1
            }));
            showToastSuccess('Part Added Successfully');

            if (quantityRef.current) {
                quantityRef.current.focus();
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'vehicleJobPartStatus') {
            let statusList = [...state.partStatus];
            let PartStatusDescription = statusList.find((k) => k.id === value)?.name || '';
            setPartObj((st) => ({ ...st, [name]: value, PartStatusDescription: PartStatusDescription }));
        } else if (name === 'partName') {
            setPartObj((st) => ({ ...st, [name]: value, filterPart: value, partID: null }));
        } else {
            setPartObj((st) => ({ ...st, [name]: value }));
        }
    };

    //Pagination
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return (
        <div style={{ padding: '10px' }}>
            <Grid container spacing={2} alignItems="center">
                {!parObj.partID && (
                    <Grid item xs={12} sm={4} md={4} lg={2} xl={3} className="mandatory-fields">
                        <InputLabel shrink>Warehouse</InputLabel>
                        <SelectBox List={state.warehouse || []} value={parObj.warehouseCode} onChange={handleInputChange} name="warehouseCode" />
                    </Grid>
                )}
                <Grid item xs={12} sm={4} md={4} lg={2} xl={3}>
                    <MandatoryField
                        autoFocus={true}
                        inputLabel="Part"
                        name="partName"
                        placeholder="Part"
                        onChange={handleInputChange}
                        value={parObj.partName}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title={'Fetch Part Details By Number'} arrow>
                                        <Grid>
                                            <IconButton style={{ padding: '0px' }} onClick={pullDataByPartNumber} size="small" state={state}>
                                                <SearchIcon />
                                            </IconButton>
                                        </Grid>
                                    </Tooltip>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                {flag.showOtherFields && (
                    <React.Fragment>
                        <Grid item xs={12} sm={4} md={4} lg={1} xl={2}>
                            <InputLabel shrink error>
                                Quantity
                            </InputLabel>
                            <SimpleField
                                disabled={state.readOnly}
                                name="quantity"
                                value={parObj.quantity}
                                onChange={handleInputChange}
                                autoFocus={true}
                                type="number"
                                inputRef={quantityRef}
                            />
                        </Grid>
                        {!parObj.partID && (
                            <Grid item xs={12} sm={4} md={4} lg={2} xl={3}>
                                <InputLabel shrink error>
                                    Part Status
                                </InputLabel>
                                <SelectBox
                                    List={state.partStatus || []}
                                    value={parObj.vehicleJobPartStatus}
                                    onChange={handleInputChange}
                                    disabled={state.readOnly}
                                    name="vehicleJobPartStatus"
                                    // error={i === index}
                                />
                            </Grid>
                        )}
                        {/* <Grid item xs={12} sm={6} md={4} lg={2} xl={4}>
                            <InputLabel shrink>Please Select Delivery Depot</InputLabel>
                            <DepotFilterList
                                onChange={handleInputChange}
                                name="depotFilterName"
                                value={parObj.depotFilterName}
                                options={state.depots}
                                getVal={getDepotsValue}
                                clearValue={clearVal}
                            />
                        </Grid> */}
                        <Grid item xs={1}>
                            <InputLabel shrink>&nbsp;</InputLabel>
                            <SecondaryButton onClick={SubmitParts}>Add</SecondaryButton>
                        </Grid>
                    </React.Fragment>
                )}

                <Grid item xs={12}>
                    <TableContainer style={{ borderRadius: '5px', maxHeight: '400px', height: '400px', overflow: 'auto', maxWidth: '100%' }} component={Paper}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow className="header">
                                    <StyledTableCell width={'50%'}>Part</StyledTableCell>
                                    <StyledTableCell width={'15%'} align="right">
                                        Warehouse Quantity
                                    </StyledTableCell>
                                    <StyledTableCell width={'15%'} align="right">
                                        Service Area Quantity
                                    </StyledTableCell>
                                    {!isEngineer && (
                                        <StyledTableCell width={'10%'} align="right">
                                            Cost Price{' '}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell width={'10%'} align="right">
                                        Selling Price
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData?.map((j, i) => (
                                    <StyledTableRow
                                        style={{ background: flag.index === i ? '#E0A75E' : 'white', cursor: 'pointer' }}
                                        onClick={() => selectRow(j.id, i, j.pn, j.id)}
                                    >
                                        <StyledTableCell>
                                            {' '}
                                            <div>
                                                <div style={{ fontSize: 14, fontWeight: 500 }}>{j.pn}</div>
                                                <div style={{ fontSize: 12, fontStyle: 'italic' }}>{j.pd}</div>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{j.q || 0}</StyledTableCell>
                                        <StyledTableCell align="right">{j.sq || 0}</StyledTableCell>
                                        {!isEngineer && <StyledTableCell align="right">{formatters.CurrencyNumberWithPound(j.cp) || 0}</StyledTableCell>}
                                        <StyledTableCell align="right">{formatters.CurrencyNumberWithPound(j.sp) || 0}</StyledTableCell>
                                    </StyledTableRow>
                                ))}

                                {paginatedData?.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan="9" align="center">
                                            {flag.screenLoader ? <CircularProgress style={{ height: 20, width: 20 }} /> : 'No record found.'}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        count={Math.ceil(filteredData.length / rowsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                        style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
