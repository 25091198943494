import React, { useEffect, useContext, useState, lazy, Suspense, useCallback } from 'react';
import { getAllServiceAgentDash } from '../../../Core/Services/ServiceAgent/ServiceAgent';
import '../ServiceCommon.scss';
import moment from 'moment';

const LazyComponent = lazy(() => import('../../Technician/dashboardUserInterface'));

const MainDashboard = (props) => (
    <Suspense fallback={<div>please Wait...</div>}>
        <LazyComponent {...props} />
    </Suspense>
);

const ServiceAgentDash = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [loading, setLoading] = useState(true);

    const pullDashboardData = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const res = await getAllServiceAgentDash();

            if (res.success) {
                setDashboardData(res.data.dashboard[0]);
            } else {
                console.warn('Unexpected response:', res);
            }
        } catch (error) {
            console.error('API Error:', error.response.data);
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    }, []);

    useEffect(() => {
        pullDashboardData();
    }, [pullDashboardData]);

    // const singleCardData = [
    //     {
    //         name: 'Not Started ',
    //         value: dashboardData.jobsNotStarted,
    //         // background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
    //         path: '/serviceagent/OJ',
    //         // params: { vehicleJobStatus: 'Not Started' },
    //         search: `?vehicleJobStatus=Not Started`,
    //         valueColor: '#0096FF',
    //         xl: 1
    //     },
    //     { name: 'On Hire ', value: dashboardData.onHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Vehicle Onsite' }, xl: 1 },
    //     { name: 'Off Hire ', value: dashboardData.offHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Off Hire' }, xl: 1 },
    //     {
    //         name: 'Breakdown',
    //         value: dashboardData.breakdowns,
    //         // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
    //         path: '/maintenance/breakdowns/:all',
    //         valueColor: 'red',
    //         xl: 1
    //     },
    //     {
    //         name: 'VOR',
    //         value: dashboardData.vor,
    //         //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
    //         path: '/controller/vor',
    //         valueColor: 'red',
    //         xl: 1
    //     }

    //     // {
    //     //     innerComponent: [
    //     //         { name: 'On Hire ', value: dashboardData.onHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Vehicle Onsite' } },
    //     //         { name: 'Off Hire ', value: dashboardData.offHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Off Hire' } }
    //     //     ],

    //     //     lg: 3,
    //     //     isInnerComp: true
    //     // },
    //     // {
    //     //     innerComponent: [
    //     //         {
    //     //             name: 'Breakdown',
    //     //             value: dashboardData.breakdowns,
    //     //             // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
    //     //             path: '/maintenance/breakdowns/:all',
    //     //             valueColor: 'red'
    //     //         },
    //     //         {
    //     //             name: 'VOR',
    //     //             value: dashboardData.vor,
    //     //             //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
    //     //             path: '/controller/vor',
    //     //             valueColor: 'red'
    //     //         }
    //     //     ],
    //     //     lg: 3,
    //     //     isInnerComp: true
    //     // }
    // ];

    const singleGrpupCardData = [
        {
            title: 'Overview',
            objList: [
                {
                    name: 'Unallocated ',
                    value: dashboardData.jobsUnallocated,
                    // background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Not Started' },
                    search: `?vehicleJobStatus=Unallocated`,
                    valueColor: '#0096FF',
                    xl: 1
                },
                {
                    name: 'Not Started ',
                    value: dashboardData.jobsNotStarted,
                    // background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Not Started' },
                    search: `?vehicleJobStatus=Not Started`,
                    valueColor: '#0096FF',
                    xl: 1
                },
                { name: 'Onhires', value: dashboardData.onHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Vehicle Onsite' }, xl: 1 },
                { name: 'Offhires', value: dashboardData.offHireService, path: '/Contracts_Hire_Fleet', params: { status: 'Off Hire' }, xl: 1 },
                {
                    name: 'Breakdown',
                    value: dashboardData.breakdowns,
                    // background: 'linear-gradient(110deg, #F00000 0%,#f27272 100%)',
                    path: '/maintenance/breakdowns/:all',
                    valueColor: 'red',
                    xl: 1
                },
                {
                    name: 'VOR',
                    value: dashboardData.vor,
                    //  background: 'linear-gradient(110deg, #fc4a1a 0%,#eb856a 100%)',
                    path: '/controller/vor',
                    valueColor: 'red',
                    xl: 1
                }
            ],
            itemSize: {
                lg: 2,
                md: 2,
                sm: 2,
                xs: 'auto'
            }
        }
    ];

    const groupCardData = [
        {
            title: 'JOBS',
            headBackground: '#FA7070',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',

            // path: '/serviceagent/OJ',
            items: [
                { label: 'All jobs', value: dashboardData.jobsTotal, path: '/serviceagent/OJ', params: { vehicleJobStatus: '' } },
                {
                    label: 'Awaiting Parts',
                    value: dashboardData.jobsAwaitingParts,
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Awaiting Parts' },
                    search: `?showAllRecords=3&vehicleJobStatus=Awaiting Parts`
                },
                {
                    label: 'Paused',
                    value: dashboardData.jobsPaused,
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Paused' },
                    search: `?showAllRecords=3&vehicleJobStatus=Paused`
                },
                {
                    label: 'Work in progress',
                    value: dashboardData.jobsWIP,
                    path: '/serviceagent/OJ',
                    // params: { vehicleJobStatus: 'Work In Progress' },
                    search: `?showAllRecords=3&vehicleJobStatus=Work In Progress`
                }
            ],
            cardSize: {
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 3,
                md: 3,
                sm: 3,
                xs: 6
            }
        },
        {
            title: 'EVENTS',
            // path: '/schedule/confirmedbookings',
            items: [
                {
                    label: 'Overdue',
                    value: dashboardData.scheduleOverdue,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&end=${moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}`,
                    valueColor: 'red'
                },
                {
                    label: 'This Week',
                    value: dashboardData.scheduleThisWeek,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment().endOf('isoWeek').format('YYYY-MM-DD')}`
                },
                {
                    label: 'Next Week',
                    value: dashboardData.scheduleNextWeek,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment()
                        .add(1, 'weeks')
                        .endOf('isoWeek')
                        .format('YYYY-MM-DD')}`
                },
                {
                    label: 'Future',
                    value: dashboardData.scheduleFuture,
                    path: `/schedule/confirmedbookings`,
                    search: `?fromDash=true&start=${moment().add(2, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')}`
                }
            ],
            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 6
            }
        },
        {
            title: 'Allocations',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            // path: '/Contracts_Hire_Fleet',
            // itemSize: {
            //     lg: 12,
            //     md: 12,
            //     sm: 12,
            //     xs: 6
            // },

            items: [
                { label: 'Hire Vehicles', value: dashboardData.hireActive, path: '/Contracts_Hire_Fleet', params: { status: '' } },
                { label: 'Contract Maintenance', value: dashboardData.contractMaintenanceActive, path: '/Contracts_Maintenance' }
            ],
            cardSize: {
                lg: 3,
                md: 5,
                sm: 5,
                xs: 12
            },
            itemSize: {
                lg: 12,
                md: 6,
                sm: 6,
                xs: 6
            }
        },

        {
            title: 'Review',
            itemSize: {
                lg: 8,
                md: 4,
                sm: 4,
                xs: 6
            },
            cardSize: {
                lg: 3,
                md: 7,
                sm: 7,
                xs: 12
            },
            // path: '/parts',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            items: [
                {
                    label: 'Job',
                    value: dashboardData.jobsToReview,
                    path: '/serviceagent/FR'
                    // search: `?fromDash=true&start=&end=&scheduleEventName=Summer Service`
                },
                { label: 'Events', value: dashboardData.eventsToReview, path: '/Inspection_Awaiting_Sign_Off', lg: 4 },
                { label: 'Outstanding documents', value: dashboardData.outstandingDocuments, valueColor: 'red', path: '/schedule/outstandingdocuments', lg: 12 }
            ]
        },
        {
            title: 'WAREHOUSE',
            path: '/parts',
            items: [
                { label: 'Parts', value: dashboardData.warehouseParts },
                { label: 'On Order', value: 0 },
                { label: 'In Transit', value: 0 },
                { label: 'Back Order', value: 0 }
            ],
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 6
            },
            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            }
        },
        {
            title: 'EVENTS',
            lg: 9,
            innerItem: 3,
            // path: '/parts',
            // background: 'linear-gradient(110deg,#1D976C 40%,#93F9B9 100%)',
            itemSize: {
                lg: 3,
                md: 3,
                sm: 3,
                xs: 6
            },
            cardSize: {
                lg: 12,
                md: 12,
                sm: 12,
                xs: 12
            },
            items: [
                {
                    label: 'Summer Service',
                    value: dashboardData.eventsSummerService,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=&scheduleEventName=Summer Service`
                    // params: { scheduleEventName: 'Summer Service' }
                },
                {
                    label: 'On Hire Inspection',
                    value: dashboardData.eventsOnHire,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=&scheduleEventName=On Hire Inspection`
                    // params: { scheduleEventName: 'On Hire' }
                },
                {
                    label: 'Off Hire Inspection',
                    value: dashboardData.eventsOffHire,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=&scheduleEventName=Off Hire Inspection`
                    // params: { scheduleEventName: 'Off Hire' }
                },
                {
                    label: 'Inspections Current & Past Week',
                    value: dashboardData.eventsInspectionsCurrent,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=Inspections Current & Past Week`
                    // params: { scheduleEventName: 'DOT' }
                },
                {
                    label: 'Inspections Next week',
                    value: dashboardData.eventsInspectionsNextWeek,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=${moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment()
                        .add(1, 'weeks')
                        .endOf('isoWeek')
                        .format('YYYY-MM-DD')}&scheduleEventName=Inspections Next week`
                    // params: { scheduleEventName: '' }
                },
                {
                    label: ' Tachograph Calibration',
                    value: dashboardData.eventsTachographEvents,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().add(8, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=Tachograph Cali`
                    // params: { scheduleEventName: 'Tachograph' }
                },
                {
                    label: 'Conformity Check',
                    value: dashboardData.eventsConformityCheckEvents,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().add(4, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=Conformity Check`
                    // params: { scheduleEventName: 'Conformity' }
                },
                {
                    label: 'MOT',
                    value: dashboardData.eventsMOT,
                    path: '/schedule/confirmedbookings',
                    search: `?fromDash=true&start=&end=${moment().add(8, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=MOT`
                    // params: { scheduleEventName: 'MOT' }
                }
            ]
        }
    ];

    let MyReportList = [
        {
            value: 'VOR',
            name: ' Reporting',
            path: '/controller/VorReporting/'
        },
        {
            value: 'Breakdown',
            name: 'Reporting ',
            path: '/maintenance/breakdown/reporting/'
        },
        {
            value: 'Job',
            name: ' Reporting ',
            path: '/job/job_Reporting'
        },
        {
            value: 'My Reports',
            name: ' Reporting ',
            path: '/reports/reportlist/'
        }
    ];

    return (
        <div style={{ padding: 10 }}>
            <MainDashboard
                // singleCardData={singleCardData}
                groupCardData={groupCardData}
                loading={loading}
                pullDashboardData={pullDashboardData}
                singleServiceGrpupCardData={singleGrpupCardData}
                MyServiceReportList={MyReportList}
            />
            {/* <Container className="cardGrid" maxWidth="lg">
                <Grid container spacing={4}>
                    {state.tilesData.map((option, index) => (
                        <Grid item key={option.id} xs={12} sm={6} md={4} lg={3} className="upper">
                            <Link
                                style={{ textDecoration: 'none' }}
                                color="inherit"
                                to={
                                    option.code == 'BO'
                                        ? '/maintenance/breakdowns/typebo'
                                        : option.code == 'BR'
                                        ? '/maintenance/breakdowns/typebr'
                                        : option.code == 'DD' || option.code == 'SD'
                                        ? '/'
                                        : `/serviceagent/${option.code}`
                                }
                            >
                                {option.title == 'Awaiting Approvals' ? (
                                    <LocalTaxiRoundedIcon className="icon_Style" />
                                ) : option.title == 'Breakdown Onsite' ? (
                                    <BuildRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Breakdown Roadside' ? (
                                    <LocalShippingRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Driver Defects' ? (
                                    <BuildRoundedIcon className="icon_Style" />
                                ) : option.title == 'Estimates Submitted' ? (
                                    <ExploreRoundedIcon className="yellow_iconStyle" />
                                ) : option.title == 'Open Jobs' ? (
                                    <WorkRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Queried Jobs' ? (
                                    <AssignmentIcon className="yellow_iconStyle" />
                                ) : option.title == 'Schedule Defects' ? (
                                    <PermDataSettingIcon className="red_iconStyle" />
                                ) : option.title == 'Test123' ? (
                                    <AirportShuttleIcon className="purple_iconStyle" />
                                ) : option.title == 'TestTS' ? (
                                    <AccountTreeIcon className="purple_iconStyle" />
                                ) : null}
                                <Card className={`c${index}`}>
                                    <CardContent className="cardContent">
                                        <Typography className=" numaricData">{option.cnt}</Typography>
                                        <Typography component="h1">{option.title}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container> */}
        </div>
    );
};
export default ServiceAgentDash;
