import { createContext, useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Typography, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {
    getContracts,
    getContractsDropDown,
    getVehicleContract,
    postContracts,
    postVehicleContract,
    VehicleContracts_GetNotes
} from '../../../../../Core/Services/InternalUser/Vehicle/contractVechicleService';
import moment from 'moment';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { useHistory } from 'react-router-dom';
import { getJobEnggById } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

export const ContractContext = createContext();

export const ContractProvider = (props) => {
    const history = useHistory();
    const [state, setState] = useState({
        errors: {},
        vehicleData: {},
        contractID: props.vehicleContractID,
        contractVehicleID: props.vehicleID,
        showLoader: props.vehicleContractID ? true : false,
        contractStartDate: '',
        ApiContractStartDate: '',
        contractEndDate: '',
        contractExtendedDate: '',
        contractTerm: '',
        contractMileage: '',
        contractExcessMileage: '',
        contractCollectionChargeDate: '',
        contractWinterTerms: '',
        contractWinterStartDate: '',
        contractWinterEndDate: '',
        contractWeeklyRental: '',
        contractInvoiceFrequency: '',
        contractInvoiceAllYear: false,
        contractDeliveryArranged: false,
        contractDeliveryDate: '',
        contractDeliveryCharge: '',
        contractOnsiteDate: '',
        contractCollectionArranged: false,
        contractCollectionDate: '',
        contractCollectionCharge: '',
        contractOffhireOverride: false,
        contractReturnDate: '',
        contractPloughRequired: false,
        contractPloughCharge: '',
        contractCamera: '',
        contractCameraCharge: '',
        contractTracker: '',
        contractTrackerCharge: '',
        contractNavigation: '',
        contractNavigationCharge: '',
        contractOnboardWeighing: false,
        contractOnboardWeighingCharge: '',
        contractTempProbe: false,
        contractTempProberCharge: '',
        contractMOT: '',
        contractMOTCharge: '',
        contractMidSeasonCalibration: false,
        contractMidSeasonCalibrationCharge: '',
        contract12WeeklyBrakeRollerTests: false,
        contract12WeeklyBrakeRollerTestsCharge: '',
        contractCabBeacons: false,
        contractBespoke: '',
        contractBespokeCharge: '',
        contractPurchaseOrder: '',
        contractFramework: '',
        contractNotes: '',
        contractAnnualRate: '',
        contractDepotID: null,
        contractTypeID: null,
        contractNotesList: []
    });
    const [tempOptions, setTempOptions] = useState({
        contractID: null,
        contractDepotID: null,
        contractServiceAreaID: null,
        contractEngineerUserID: null,
        contractSummerServiceAreaID: null
    });
    const [dropdown, setDropdown] = useState({
        contractDurationTypes: [],
        contractTypes: [],
        agreementTypes: [],
        maintenanceProviders: [],
        contractStatus: [],
        serviceAgents: [],
        depotsList: [],
        technicianList: []
    });
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    // DropDown Api side
    useEffect(() => {
        const fetchDropDownData = async () => {
            const res = await getContractsDropDown();
            if (res.success) {
                const { contractTypes, maintenanceProviders, contractStatus, serviceAgents, depots, technician } = res.data;
                setDropdown((st) => ({
                    ...st,

                    contractTypes: contractTypes.map(({ contractTypeID, contractTypeDescription }) => ({
                        id: contractTypeID,
                        name: contractTypeDescription
                    })),

                    maintenanceProviders: maintenanceProviders.map(({ maintenanceProviderID, maintenanceProviderName }) => ({
                        id: maintenanceProviderID,
                        name: maintenanceProviderName
                    })),
                    contractStatus: contractStatus.map(({ contractStatusID, contractStatusDescription }) => ({
                        id: contractStatusID,
                        name: contractStatusDescription
                    })),
                    serviceAgents: serviceAgents.map(({ id, name }) => ({
                        id,
                        name
                    })),
                    depotsList: depots.map(({ id, name }) => ({
                        id,
                        name
                    }))
                    // technicianList: technician.map(({ id, name }) => ({
                    //     id,
                    //     name
                    // }))
                }));
            }
        };

        fetchDropDownData();
    }, []);

    useEffect(async () => {
        pullEditData();
    }, [props.vehicleContractID, props.isStepperReload]);

    const pullEditData = async () => {
        if (props.vehicleContractID) {
            let res = await getVehicleContract(state.contractVehicleID);
            let data = res.data?.table[0] || {};
            if (res.success) {
                let vehicleData = res.data?.table1[0] || {};
                props.getHeaderData(vehicleData);
                setState((st) => ({
                    ...st,

                    contractStartDate: data.contractStartDate || '',
                    ApiContractStartDate: data.contractStartDate || '',
                    contractEndDate: data.contractEndDate || '',
                    contractWinterTerms: data.contractWinterTerms || '',
                    contractWinterStartDate: data.contractWinterStartDate || '',
                    ApicontractWinterStartDate: data.contractWinterStartDate || '',
                    contractWinterEndDate: data.contractWinterEndDate || '',
                    contractExtendedDate: data.contractExtendedDate || '',
                    contractPurchaseOrder: data.contractPurchaseOrder || '',
                    contractInvoiceFrequency: data.contractInvoiceFrequency || '',
                    contractInvoiceAllYear: data.contractInvoiceAllYear || false,
                    contractFramework: data.contractFramework || '',
                    contractAnnualPriceIncrease: data.contractAnnualPriceIncrease || false,
                    contractIncreaseAmount: data.contractIncreaseAmount || '',
                    contractIncreaseDate: data.contractIncreaseDate || '',
                    contractDepotID: data.contractDepotID || '',
                    contractServiceAreaID: data.contractServiceAreaID || '',
                    contractEngineerUserID: data.contractEngineerUserID || '',
                    contractSummerServiceAreaID: data.contractSummerServiceAreaID || '',
                    contractDeliveryCharge: data.contractDeliveryCharge || '',
                    contractDeliveryArranged: data.contractDeliveryArranged || false,
                    contractDeliveryDate: data.contractDeliveryDate || '',
                    contractOnsiteDate: data.contractOnsiteDate || '',
                    contractOffhireOverride: data.contractOffhireOverride || false,
                    contractCollectionCharge: data.contractCollectionCharge || '',
                    contractCollectionArranged: data.contractCollectionArranged || false,
                    contractCollectionDate: data.contractCollectionDate || '',
                    contractReturnDate: data.contractReturnDate || '',
                    contractCollectionChargeDate: data.contractCollectionChargeDate || '',
                    contractWeeklyRental: data.contractWeeklyRental || 0,
                    contractMileage: data.contractMileage || '',
                    contractExcessMileage: data.contractExcessMileage || '',
                    contractNotes: data.contractNotes || '',
                    contractPloughRequired: data.contractPloughRequired || false,
                    contractPloughCharge: data.contractPloughCharge || '',
                    contractPloughDescription: data.contractPloughDescription || '',
                    contractCamera: data.contractCamera || false,
                    contractCameraCharge: data.contractCameraCharge || '',
                    contractCameraDescription: data.contractCameraDescription || '',
                    contractTracker: data.contractTracker || false,
                    contractTrackerCharge: data.contractTrackerCharge || '',
                    contractTrackerDescription: data.contractTrackerDescription || '',
                    contractNavigation: data.contractNavigation || false,
                    contractNavigationCharge: data.contractNavigationCharge || '',
                    contractNavigationDescription: data.contractNavigationDescription || '',
                    contractOnboardWeighing: data.contractOnboardWeighing || false,
                    contractOnboardWeighingCharge: data.contractOnboardWeighingCharge || '',
                    contractOnboardWeighingDescription: data.contractOnboardWeighingDescription || '',
                    contractTempProbe: data.contractTempProbe || false,
                    contractTempProberCharge: data.contractTempProberCharge || '',
                    contractTempProberDescription: data.contractTempProberDescription || '',
                    contractMOT: data.contractMOT || false,
                    contractMOTCharge: data.contractMOTCharge || '',
                    contractMOTDescription: data.contractMOTDescription || '',
                    contractMidSeasonCalibration: data.contractMidSeasonCalibration || false,
                    contractMidSeasonCalibrationCharge: data.contractMidSeasonCalibrationCharge || '',
                    contractMidSeasonCalibrationDescription: data.contractMidSeasonCalibrationDescription || '',
                    contract12WeeklyBrakeRollerTests: data.contract12WeeklyBrakeRollerTests || false,
                    contract12WeeklyBrakeRollerTestsCharge: data.contract12WeeklyBrakeRollerTestsCharge || '',
                    contract12WeeklyBrakeRollerTestsDescription: data.contract12WeeklyBrakeRollerTestsDescription || '',
                    contractBespoke: data.contractBespoke || false,
                    contractBespokeCharge: data.contractBespokeCharge || '',
                    contractBespokeDescription: data.contractBespokeDescription || '',
                    contractAnnualRate: data.contractAnnualRate || '',
                    contractCabBeacons: data.contractCabBeacons || false,
                    //////
                    contractTypeID: vehicleData.contractTypeID,
                    vehicleData: vehicleData,
                    showLoader: false
                }));
            }
            let contractNotesRes = await VehicleContracts_GetNotes(data.vehicleContractID);

            setState((st) => ({ ...st, contractNotesList: contractNotesRes?.data?.notes?.table || [] }));
        }
    };

    useEffect(() => {
        const updateTempOptions = () => {
            const newSt = {};
            if (state.contractServiceAreaID && dropdown.serviceAgents?.length > 0) {
                newSt.contractServiceAreaID = dropdown.serviceAgents.find(({ id }) => id === state.contractServiceAreaID);
            }
            if (state.contractSummerServiceAreaID && dropdown.serviceAgents?.length > 0) {
                newSt.contractSummerServiceAreaID = dropdown.serviceAgents.find(({ id }) => id === state.contractSummerServiceAreaID);
            }
            if (state.contractDepotID && dropdown.depotsList?.length > 0) {
                newSt.contractDepotID = dropdown.depotsList.find(({ id }) => id === state.contractDepotID);
            }
            // if (state.contractEngineerUserID && dropdown.technicianList?.length > 0) {
            //     newSt.contractEngineerUserID = dropdown.technicianList.find(({ id }) => id === state.contractEngineerUserID);
            // }
            setTempOptions((st) => ({
                ...st,
                ...newSt
            }));
        };

        updateTempOptions();
    }, [
        state.contractServiceAreaID,
        state.contractSummerServiceAreaID,
        state.contractDepotID,
        // state.contractEngineerUserID,
        dropdown.serviceAgents,
        dropdown.depotsList
        // dropdown.technicianList
    ]);

    useEffect(async () => {
        if (tempOptions.contractServiceAreaID?.id) {
            let newSt = {};
            let res = await getJobEnggById(tempOptions.contractServiceAreaID?.id);
            if (res.success) {
                setDropdown((d) => ({ ...d, technicianList: res.data.list }));
                if (state.contractEngineerUserID) {
                    newSt.technicianUserIDValue = res.data.list.find(({ id }) => id === state.contractEngineerUserID);
                }
                setTempOptions((st) => ({
                    ...st,
                    contractEngineerUserID: newSt.technicianUserIDValue || {}
                }));
            }
        }
    }, [tempOptions.contractServiceAreaID?.id]);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const validation = () => {
        let isValid = true;
        let errors = {};
        let errorMessage = '';
        let {
            contractStartDate,
            contractEndDate,
            contractWinterTerms,
            contractWinterStartDate,
            contractPurchaseOrder,
            contractInvoiceFrequency,
            contractDeliveryCharge,
            contractCollectionCharge,
            contractWeeklyRental
        } = state;

        if (!contractStartDate) {
            isValid = false;
            errors.contractStartDate = 'This field is required';
            errorMessage = errorMessage.concat(errors.contractStartDate) + '\n';
        }
        // if (!contractEndDate) {
        //     isValid = false;
        //     errors.contractEndDate = 'This field is required';
        // }
        if (!contractWinterTerms && state.contractTypeID == 1) {
            isValid = false;
            errors.contractWinterTerms = 'Winter Term (Weeks) is required';
            errorMessage = errorMessage.concat(errors.contractWinterTerms) + '\n';
        }
        if (!contractWinterStartDate && state.contractTypeID == 1) {
            isValid = false;
            errors.contractWinterStartDate = 'Winter Start Date is required';
            errorMessage = errorMessage.concat(errors.contractWinterStartDate) + '\n';
        }
        if (!contractPurchaseOrder && state.contractTypeID == 1) {
            isValid = false;
            errors.contractPurchaseOrder = 'Purchase Order is required';
            errorMessage = errorMessage.concat(errors.contractPurchaseOrder) + '\n';
        }
        if (!contractInvoiceFrequency) {
            isValid = false;
            errors.contractInvoiceFrequency = 'Invoice Frequency is required';
            errorMessage = errorMessage.concat(errors.contractInvoiceFrequency) + '\n';
        }
        if (!tempOptions.contractSummerServiceAreaID?.id && state.contractTypeID == 1) {
            isValid = false;
            errors.contractSummerServiceAreaID = 'Summer Service Area is required';
            errorMessage = errorMessage.concat(errors.contractSummerServiceAreaID) + '\n';
        }
        if (!tempOptions.contractServiceAreaID?.id) {
            isValid = false;
            errors.contractServiceAreaID = 'Service Area is required';
            errorMessage = errorMessage.concat(errors.contractServiceAreaID) + '\n';
        }
        if (!tempOptions.contractDepotID?.id) {
            isValid = false;
            errors.contractDepotID = 'Depot is required';
            errorMessage = errorMessage.concat(errors.contractDepotID) + '\n';
        }
        if (!tempOptions.contractEngineerUserID?.id && state.contractTypeID == 2) {
            isValid = false;
            errors.contractEngineerUserID = 'Engineer field is required';
            errorMessage = errorMessage.concat(errors.contractEngineerUserID) + '\n';
        }
        if (!contractDeliveryCharge && state.contractTypeID == 1) {
            isValid = false;
            errors.contractDeliveryCharge = 'Delivery Charge is required';
            errorMessage = errorMessage.concat(errors.contractDeliveryCharge) + '\n';
        }
        if (!contractCollectionCharge && state.contractTypeID == 1) {
            isValid = false;
            errors.contractCollectionCharge = 'Collection Charge is required';
            errorMessage = errorMessage.concat(errors.contractCollectionCharge) + '\n';
        }
        if (!contractWeeklyRental && state.contractTypeID == 1) {
            isValid = false;
            errors.contractWeeklyRental = 'Rate (Weekly) is required';
            errorMessage = errorMessage.concat(errors.contractWeeklyRental) + '\n';
        }

        console.log(errors, 'errors');
        setState((st) => ({
            ...st,
            errors: errors
        }));
        if (errorMessage) {
            alert(errorMessage);
        }
        return isValid;
    };

    // submit data
    const submitData = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                contractVehicleID: state.contractVehicleID,
                ///////////////////////////
                contractStartDate: state.contractStartDate,
                contractEndDate: state.contractEndDate,
                contractWinterTerms: state.contractWinterTerms,
                contractWinterStartDate: state.contractWinterStartDate,
                contractWinterEndDate: state.contractWinterEndDate,
                contractExtendedDate: state.contractExtendedDate,
                contractPurchaseOrder: state.contractPurchaseOrder,
                contractInvoiceFrequency: state.contractInvoiceFrequency,
                contractInvoiceAllYear: state.contractInvoiceAllYear,
                contractFramework: state.contractFramework,
                contractAnnualPriceIncrease: state.contractAnnualPriceIncrease,
                contractIncreaseAmount: state.contractIncreaseAmount,
                contractIncreaseDate: state.contractIncreaseDate,
                contractDepotID: tempOptions.contractDepotID?.id,
                contractServiceAreaID: tempOptions.contractServiceAreaID?.id,
                contractEngineerUserID: tempOptions.contractEngineerUserID?.id,
                contractSummerServiceAreaID: tempOptions.contractSummerServiceAreaID?.id,
                contractDeliveryCharge: state.contractDeliveryCharge,
                contractDeliveryArranged: state.contractDeliveryArranged,
                contractDeliveryDate: state.contractDeliveryDate,
                contractOnsiteDate: state.contractOnsiteDate,
                contractOffhireOverride: state.contractOffhireOverride,
                contractCollectionCharge: state.contractCollectionCharge,
                contractCollectionArranged: state.contractCollectionArranged,
                contractCollectionDate: state.contractCollectionDate,
                contractReturnDate: state.contractReturnDate,
                contractCollectionChargeDate: state.contractCollectionChargeDate,
                contractWeeklyRental: state.contractWeeklyRental,
                contractMileage: state.contractMileage,
                contractExcessMileage: state.contractExcessMileage,
                contractNotes: state.contractNotes,
                contractPloughRequired: state.contractPloughRequired,
                contractPloughCharge: state.contractPloughCharge,
                contractPloughDescription: state.contractPloughDescription,
                contractCamera: state.contractCamera,
                contractCameraCharge: state.contractCameraCharge,
                contractCameraDescription: state.contractCameraDescription,
                contractTracker: state.contractTracker,
                contractTrackerCharge: state.contractTrackerCharge,
                contractTrackerDescription: state.contractTrackerDescription,
                contractNavigation: state.contractNavigation,
                contractNavigationCharge: state.contractNavigationCharge,
                contractNavigationDescription: state.contractNavigationDescription,
                contractOnboardWeighing: state.contractOnboardWeighing,
                contractOnboardWeighingCharge: state.contractOnboardWeighingCharge,
                contractOnboardWeighingDescription: state.contractOnboardWeighingDescription,
                contractTempProbe: state.contractTempProbe,
                contractTempProberCharge: state.contractTempProberCharge,
                contractTempProberDescription: state.contractTempProberDescription,
                contractMOT: state.contractMOT,
                contractMOTCharge: state.contractMOTCharge,
                contractMOTDescription: state.contractMOTDescription,
                contractMidSeasonCalibration: state.contractMidSeasonCalibration,
                contractMidSeasonCalibrationCharge: state.contractMidSeasonCalibrationCharge,
                contractMidSeasonCalibrationDescription: state.contractMidSeasonCalibrationDescription,
                contract12WeeklyBrakeRollerTests: state.contract12WeeklyBrakeRollerTests,
                contract12WeeklyBrakeRollerTestsCharge: state.contract12WeeklyBrakeRollerTestsCharge,
                contract12WeeklyBrakeRollerTestsDescription: state.contract12WeeklyBrakeRollerTestsDescription,
                contractBespoke: state.contractBespoke,
                contractBespokeCharge: state.contractBespokeCharge,
                contractBespokeDescription: state.contractBespokeDescription,
                contractAnnualRate: state.contractAnnualRate,
                contractCabBeacons: state.contractCabBeacons
            };

            let message = !state.contractID ? 'Contract Added Successfully' : 'Contract Update Successfully';

            let res = await postVehicleContract(data);
            if (res.success) {
                showToastSuccess(res.message);
                props.pullDetails();
                setBtnLoader(false);
            } else {
                showToastError(res.message);
                setBtnLoader(false);
            }
        }
    };

    useEffect(() => {
        if (state.contractTerm) {
            let changeDate = moment(state.contractStartDate).add(state.contractTerm, 'months').format('YYYY-MM-DD');
            setState((st) => ({
                ...st,
                contractEndDate: changeDate
            }));
        }
    }, [state.contractTerm]);

    const YesNoRadio = (props) => {
        return (
            // <Grid container>
            // <Grid container spacing={1} style={{ marginLeft: '2px' }}>
            <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" />
                <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" />
            </RadioGroup>
            // </Grid>
            // </Grid>
        );
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({
            ...st,
            [nm]: val
        }));
    };

    const RedirectToContract = useCallback(() => {
        console.log('object');
        try {
            history.push(`/contractList/editcontractList/${state.contractID}`);
        } catch (error) {
            console.error(error);
        }
    }, [history, state.contractID]);

    return (
        <ContractContext.Provider
            value={{
                state,
                btnLoader,
                YesNoRadio,
                dropdown,
                inputChange,
                submitData,
                tempOptions,
                singleSelectChange,
                RedirectToContract
            }}
        >
            {props.children}
        </ContractContext.Provider>
    );
};
