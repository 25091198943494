import React, { useState, useEffect, useMemo } from 'react';
import { getAllVehicleJob, getAllJob, getAllCustomerJob } from '../../../../Core/Services/InternalUser/JOB/jobService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { EditButton, BreadCrumbs, formatters } from '../../../../Core/Controls';
import JobScreenModal from './JobScreenModal';
import { Link } from 'react-router-dom';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton, SecondaryButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import JobQueryModal from './JobStatusBtn/JobQuery';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import ReactDOM from 'react-dom';
import DepotDetails from '../DetailsModal/depotDetails';
import CustomerDetails from '../DetailsModal/customerDetails';
import ServiceAgentDetails from '../DetailsModal/serviceAgent';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { getVechicleIDByRegNO } from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import { useWindowSize } from '../../../../Core/Hooks/useWindowSize';
const base_columns = [
    // { key: 'action', name: 'Action', width: 120, addFileUploaderIcon: true },
    { key: 'jobDescription', name: 'Job Description', width: 150 },
    { key: 'depotName', name: 'Depot Name', width: 150 },
    { key: 'vehicleJobStatus', name: 'Status', width: 130 },
    { key: 'serviceAgentName', name: 'Service Area', width: 130 },
    { key: 'technician', name: 'Engineer', width: 150 },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 90 },
    { key: 'agentOrderNo', name: 'Job Ref', width: 145 },
    { key: 'jobPriority', name: 'Job Priority', width: 140 },
    { key: 'costCentre', name: 'Cost Centre', width: 110 },
    {
        key: 'labourTotal',
        name: 'Labour',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 110
    },
    {
        key: 'partsTotal',
        name: 'Parts',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 110
    },
    {
        key: 'chargableTotal',
        name: 'Total',
        formatter: formatters.NumberWithPound,
        align: 'right',
        width: 110
    }
    // { key: 'vehicleJobStatus', name: 'Job Status', width: 110 }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'All Jobs', active: true }
];

function JobScreen(props) {
    console.log(props, 'props');

    const params = new URLSearchParams(window.location.search);
    const serviceAgentName = params.get('agentName') || '';
    const vehicleJobStatus = params.get('vehicleJobStatus') || '';
    const fromDash = Boolean(params.get('fromDash')) || false;

    const [width, height] = useWindowSize();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const isInternal = useLoginInfo().userRoles.includes('internal');
    const isEngg = useLoginInfo().userRoles.includes('engineer');
    const isServicearea = useLoginInfo().userRoles.includes('servicearea');
    const history = useHistory();
    // let getUrl = `VehicleJob/${props.VehicleID ? `?VehicleID=${props.VehicleID}` : ''}${props.customerID ? `?customerID=${props.customerID}` : ''}`;
    // let getUrl = `VehicleJob${props.VehicleID || props.customerID ? '?' : ''}${props.VehicleID ? `VehicleID=${props.VehicleID}` : ''}${
    //     props.VehicleID && props.customerID ? '&' : ''
    // }${props.customerID && !isInternal ? `customerID=${props.customerID}` : ''}`;

    const [state, setState] = useState({
        jobList: [],
        loader: true,
        open: false,
        filterVal: '',
        getUrl: fromDash
            ? `VehicleJob?vehicleJobStatus=${vehicleJobStatus}${serviceAgentName && `&serviceAgentName=${serviceAgentName}`}`
            : `VehicleJob${props.VehicleID || props.customerID ? '?' : ''}${props.VehicleID ? `VehicleID=${props.VehicleID}` : ''}${
                  props.VehicleID && props.customerID ? '&' : ''
              }${props.customerID && !isInternal ? `customerID=${props.customerID}` : ''}`,
        customerID: props.customerID || null,
        depotID: props.depotID || null,
        engineerID: props.technicianUserID || null,
        serviceAgentID: props.ServiceAgentID || null,
        vorVehicleID: props.VORId || null,
        filterVehicleID: props.VehicleID || null
    });
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    useEffect(async () => {
        pullJobList();
    }, [props.VehicleID]);

    const pullJobList = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const jobHandler = (params) => {
        setState((state) => ({ ...state, open: true }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${props.VehicleID ? props.VehicleID : val?.vehicleID}`,
            search: '',
            state: { position: 'jobs', vorVehicleID: val.vorVehicleID }
        });
    };

    const handleShowQueries = (val) => {
        setState((state) => ({ ...state, showJobQuery: true, jobID: val?.jobID }));
    };

    const closeTheDialog = (val) => {
        setState((state) => ({ ...state, showJobQuery: false, jobID: '' }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <CustomIconButton
                    icon={SpeakerNotesIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Show Queries"
                    onClick={() => handleShowQueries(row)}
                />
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}
            </div>
        );
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.serviceAgentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    useEffect(() => {
        const fetchVehicleData = async () => {
            if (!props.VehicleID) {
                if (state.filterVal.length > 4) {
                    try {
                        let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                        setState((st) => {
                            let newSt = { ...st };
                            if (resBrekDown.data?.list) {
                                newSt.show = resBrekDown.success;
                                newSt.filterVehicleID = resBrekDown.data.list[0].vehicleID;
                                newSt.customerID = resBrekDown.data.list[0].customerID;
                                newSt.depotID = resBrekDown.data.list[0].depotID;
                                newSt.engineerID = resBrekDown.data.list[0].engineerID;
                                newSt.serviceAgentID = resBrekDown.data.list[0].serviceAgentID;
                                newSt.vehicleMakeModel = resBrekDown.data.list[0].vehicleMakeModel;
                                newSt.vorVehicleID = resBrekDown.data.list[0].vorVehicleID;
                            } else {
                                newSt.show = false;
                            }
                            return newSt;
                        });
                    } catch (error) {
                        console.error('Error fetching vehicle data:', error);
                        setState((st) => ({ ...st, show: false }));
                    }
                } else {
                    setState((st) => ({ ...st, show: false }));
                }
            }
        };

        fetchVehicleData();
    }, [props.VehicleID, state.filterVal]);

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        let agentNameCol = newColumn.find((element) => element.key === 'serviceAgentName');
        let depotNameCol = newColumn.find((element) => element.key === 'depotName');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        if (isEngg) {
            newColumn.splice(0, 1);
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        if (!props.VehicleID) {
            newColumn.splice(0, 0, { key: 'vehicleRegNumber', name: 'Reg Number', width: 110, formatter: renderRegNumberEdit });
        }
        if (!props.customerID && !isCustomer && !props.VehicleID) {
            newColumn.splice(3, 0, { key: 'customerCompanyName', name: 'Customer Name', formatter: renderCustomerName, width: 200 });
        }
        if (isServicearea) {
            newColumn.splice(
                0,
                newColumn.length,
                { key: 'vehicleRegNumber', name: 'Reg Number', width: 110, formatter: renderRegNumberEdit },
                { key: 'jobDescription', name: 'Job Description', width: 150 },
                { key: 'jobPriority', name: 'Job Priority', width: 140 },
                { key: 'depotName', name: 'Depot Name', width: 150 },
                { key: 'customerCompanyName', name: 'Customer Name', formatter: renderCustomerName, width: 200 },
                { key: 'vehicleJobStatus', name: 'Status', width: 130 },
                { key: 'technician', name: 'Engineer', width: 150 },
                { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 90 },
                { key: 'internalTotal', name: 'Internal Total', formatter: formatters.NumberWithPound, width: 150 },
                { key: 'chargableTotal', name: 'Chargeable Total', formatter: formatters.NumberWithPound, width: 150 },
                { key: 'agentOrderNo', name: 'Job Ref', width: 145 }
            );
        }
        return newColumn;
    }, []);

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, show: false, filterVal: '' }));
        res && pullJobList();
        res && props.pullDetails && props.pullDetails();
    };
    const gridBtnTitle = !props.VehicleID ? '' : isCustomer ? '' : 'Add Job';
    const page = props.VehicleID ? true : props.customerID ? true : false;
    return (
        <div className={`${!page && 'screen'}` + ' controller-screen'} style={{ marginTop: 8 }}>
            {state.open && (
                <DialogComp open={state.open} onClose={() => closeModal(false)} title="Add Job" maxWidth="md">
                    <JobScreenModal
                        VehicleID={state.filterVehicleID}
                        customerID={state.customerID}
                        depotID={state.depotID}
                        technicianUserID={state.engineerID}
                        serviceAgentID={state.serviceAgentID}
                        VORId={state.vorVehicleID}
                        onClose={closeModal}
                    />
                </DialogComp>
            )}
            {!page && (
                <Grid container spacing={2}>
                    <Grid item>
                        {' '}
                        <BreadCrumbs crumbs={crumbs} />
                    </Grid>
                    <Grid item>
                        {' '}
                        <div style={{ width: 'auto' }}>
                            <input type="text" onChange={inputChange} placeholder="Enter Reg Number" value={state.filterVal} style={{ padding: 5 }} />
                        </div>
                    </Grid>
                    <Grid item>
                        {' '}
                        {state.show && (
                            <div style={{ marginLeft: '4px' }}>
                                <SecondaryButton
                                    style={{ padding: '2px 16px' }}
                                    onClick={jobHandler}
                                    fullWidth
                                    // className="submit_btn"
                                >
                                    Add
                                </SecondaryButton>
                            </div>
                        )}
                    </Grid>
                </Grid>
            )}

            {state.showJobQuery && (
                <DialogComp title="Job Queries" maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobQueryModal title="" jobQueryJobID={state.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
            <GijgoGrid
                columns={columns}
                btnTitle={gridBtnTitle}
                onBtnClick={jobHandler}
                idFieldName={`${checkhaveAccess?.CanUploadJobDocuments ? 'jobID' : null}`}
                fileUploaderConfigName={`${checkhaveAccess?.CanUploadJobDocuments ? 'JobScreen' : null}`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                getUrl={state.getUrl}
            />
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.jobServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.jobDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default JobScreen;
