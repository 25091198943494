import React, { useContext, useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { InputLabel, Grid, TableCell, TableRow, Paper, FormHelperText, Typography, CircularProgress, IconButton, Button, Hidden } from '@material-ui/core';
import {
    SecondaryButton,
    InputText,
    FailButton,
    PassButton,
    NAButton,
    MButton,
    RButton,
    DatePicker,
    PassAllbutton,
    BackButton,
    formatters,
    SimpleField,
    PrimaryButton
} from '../../../../../Core/Controls';
import Axle from './AddInspectionCheck/vehicleAxel';
import '../../controllerStyle.scss';
import ImageModal from '../DriverDailyChecks/imageModal';
import ConfirmationModal from '../../../../../Core/Modal/ConfirmationModal';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { InspectionCheckProvider as StateProvider, InspectionCheCkContaxt } from './AddInspectionCheck/InspectionCheckProvide';
import { AddCheckTableHeader } from './AddInspectionCheck/InspectionHeaders';
import { InspectionCheckBody } from './AddInspectionCheck/InspectionBody';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import EditVehicleDailyChecks from './editVehicleDailyCheck';
import { InspectionBreakTest } from './AddInspectionCheck/BreakTest';
import { InspectionBreakPerfomance } from './AddInspectionCheck/BreakPerformance';
import { InspectionRoadTest } from './AddInspectionCheck/RoadTest';
import { usePortalSettings } from '../../../../../Core/Hooks/usePortalSettings';
import { InSpectionSignOff } from './AddInspectionCheck/signOff';
import { FocusTextBox } from '../../../../../Core/Controls/Inputs';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import ImageUpload from './InspectionImageUpload';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '5px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 10px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
        // marginTop: 300
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        [theme.breakpoints.down('xs')]: {
            padding: '10px'
        }
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0,
        marginLeft: -4,
        marginBottom: 20
    }
}));

const IconColors = {
    fontSize: 25,
    color: 'white'
};
const fontColors = {
    fontSize: 16,
    color: 'white'
};

const CheckButtons = {
    D: FailButton,
    M: MButton,
    N: NAButton,
    S: PassButton,
    R: RButton
};
const CheckButtonsBorder = {
    D: 'red',
    M: 'orange',
    N: 'blue',
    S: 'green',
    R: 'red'
};

const ButtonColors = {
    D: { color: '#d32f2f', value: () => <ClearIcon style={IconColors} /> },
    M: { color: '#f57f17', value: () => <span style={fontColors}>M</span> },
    N: { color: '#3f51b5', value: () => <span style={fontColors}>N/A</span> },
    S: { color: '#388e3c', value: () => <DoneIcon style={IconColors} /> }
};

const VehicleDailyChecks = (props) => {
    const portalSettings = usePortalSettings();
    const {
        btnLoader,
        state,
        flags,
        widths,
        handleInput,
        signCanvas,
        clear,
        GetTechnicianSign,
        imageHandler,
        onImageChange,
        changeState,
        changeNotesState,
        changeResutNotesState,
        changeAllChildChecks,
        handleInputs,
        useDefaultSig,
        handleConfirmPopup,
        submitData,
        sumbitImage,
        setState,
        HandleColspan,
        handleInspection,
        handleInpectionRow,
        closeInspection,
        InHire_imageHandler,
        getPreviousTyreData,
        onImageChangeAPI,
        RemoveImage,
        sumbitImageApi,
        imgLoader
    } = useContext(InspectionCheCkContaxt);
    let checkAccess = AppStorage.getAllowAcces();

    const classes = useStyles();

    // const GlobalCss = withStyles({
    //     '@global': {
    //         '.MuiDialogContent-root': {
    //             padding: '0px 0px',
    //             [theme.breakpoints.up('sm')]: {
    //                 padding: '16px 24px' // Customize the padding for 'sm' and up screens
    //             }
    //         }
    //     }
    // })(() => null);

    const GlobalCss = withStyles((theme) => ({
        '@global': {
            '.MuiDialogContent-root': {
                padding: '10px',
                [theme.breakpoints.down('sm')]: {
                    padding: '0px' // Customize the padding for 'sm' and up screens
                }
            }
        }
    }))(() => null);

    return (
        <>
            <GlobalCss />
            <DialogComp onClose={props.onClose} title={'Inspection'} maxWidth="xl" fullWidth fullScreen>
                <div style={{ height: `calc(100vh - 108px)`, overflow: 'auto', padding: 10 }} className="custom-scroll">
                    {state.loader ? (
                        <div className={classes.loader}>
                            <CircularProgress color="secondary" />
                        </div>
                    ) : (
                        <Grid container spacing={2}>
                            {props.frompage ? (
                                <Grid item xs={12} sm={12} md={12} style={{ padding: '0px 8px' }}>
                                    <Typography variant={'h5'} style={{ fontSize: widths < 500 ? 17 : '' }}>
                                        <BackButton onClick={props.onClose} />
                                        {state.vehicleInfo?.toUpperCase() || ''}
                                    </Typography>
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs md={2} lg={3} xl={2}>
                                        <InputLabel shrink>Calibration Date</InputLabel>
                                        <b>
                                            <Typography variant={'h7'}>
                                                {formatters.ReadOnlyDateFormatter(state.vehicleNextCalibrationDate) || 'N/A'}
                                            </Typography>
                                        </b>
                                    </Grid>
                                    <Grid item xs md={2} lg={3} xl={2}>
                                        <InputLabel shrink>Tacho Calibration Date</InputLabel>
                                        <b>
                                            <Typography variant={'h7'}>
                                                {formatters.ReadOnlyDateFormatter(state.vehicleNextTachoCalibrationDate) || 'N/A'}
                                            </Typography>
                                        </b>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6} xl={8}>
                                        <Grid container alignItems="center" justify="flex-end" spacing={2}>
                                            <Hidden only={['md', 'lg', 'xl']}>
                                                {state.buttonList?.map((k) => {
                                                    const Icon = ButtonColors[k.inspectionSheetCheckOptionCode].value;

                                                    return (
                                                        <Grid item xs={4} sm={4} md={2} key={k.inspectionSheetCheckOptionCode}>
                                                            <IconButton
                                                                style={{
                                                                    background: ButtonColors[k.inspectionSheetCheckOptionCode].color,
                                                                    borderRadius: 0,
                                                                    height: 30,
                                                                    width: '100%',
                                                                    padding: 10,
                                                                    fontSize: 12,
                                                                    color: 'white'
                                                                }}
                                                            >
                                                                {' '}
                                                                <div
                                                                    style={{
                                                                        padding: 8,
                                                                        fontSize: 12,
                                                                        fontWeight: 600,
                                                                        color: 'white'
                                                                    }}
                                                                >
                                                                    {k.inspectionSheetCheckOptionDescription}
                                                                </div>
                                                            </IconButton>
                                                        </Grid>
                                                    );
                                                })}
                                            </Hidden>
                                            <Hidden only={['sm', 'xs']}>
                                                {state.buttonList?.map((k) => {
                                                    const Icon = ButtonColors[k.inspectionSheetCheckOptionCode].value;

                                                    return (
                                                        <Grid item xs={4} sm={4} md={2} key={k.inspectionSheetCheckOptionCode}>
                                                            <IconButton
                                                                style={{
                                                                    background: ButtonColors[k.inspectionSheetCheckOptionCode].color,
                                                                    borderRadius: 0,
                                                                    height: 20,
                                                                    width: '100%',
                                                                    padding: 10,
                                                                    fontSize: 12,
                                                                    color: 'white'
                                                                }}
                                                            >
                                                                <Icon /> {/* Render the icon component here */}
                                                                <div
                                                                    style={{
                                                                        padding: 8,
                                                                        fontSize: 13,
                                                                        fontWeight: 600,
                                                                        color: 'white'
                                                                    }}
                                                                >
                                                                    {k.inspectionSheetCheckOptionDescription}
                                                                </div>
                                                            </IconButton>
                                                        </Grid>
                                                    );
                                                })}
                                            </Hidden>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <InputLabel shrink>Date Of Check</InputLabel>
                                <DatePicker
                                    placeholder="Date Of Check"
                                    name="date"
                                    onChange={handleInput}
                                    disabled={!checkAccess.CanUpdateSchedules}
                                    value={state.date}
                                    InputProps={{
                                        inputProps: { min: moment().subtract(6, 'd').format('YYYY-MM-DD'), max: moment().format('YYYY-MM-DD') }
                                    }}
                                    // disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <InputLabel shrink>Check carried out by</InputLabel>
                                <InputText
                                    placeholder="Check carried out by"
                                    name="vehicleDailyCheckCheckerName"
                                    value={state.vehicleDailyCheckCheckerName}
                                    onChange={handleInput}
                                />
                            </Grid>
                            {flags?.displayMileage && (
                                <Grid item xs={12} sm={4} md={4} lg={4} className="mandatory-fields">
                                    <InputLabel shrink>Current Mileage</InputLabel>
                                    <FocusTextBox
                                        type="number"
                                        placeholder="Current Mileage"
                                        name="vehicleDailyCheckMileage"
                                        onChange={handleInput}
                                        value={state.vehicleDailyCheckMileage}
                                        autofocus
                                    />
                                    <FormHelperText error>{state.errors?.vehicleDailyCheckMileage}</FormHelperText>
                                </Grid>
                            )}
                            {state.checksList && state.checksList?.length > 0 ? (
                                <Grid item xs={12} sm={12}>
                                    <Paper className={classes.paper}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {state.checksList?.map((m, i) => (
                                                    <>
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                            style={{
                                                                border: '1px solid rgba(0,0,0,0.1)',
                                                                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 8px'
                                                            }}
                                                        >
                                                            {flags?.displayStartEvent ? (
                                                                <>
                                                                    <Grid
                                                                        container
                                                                        item
                                                                        xs={12}
                                                                        style={{
                                                                            borderBottom: '1px solid rgba(0,0,0,0.1)'
                                                                        }}
                                                                    >
                                                                        <Grid item xs={12} xl={5} lg={5} sm={12} md={12}>
                                                                            {' '}
                                                                            <h3 className={classes.title}>{m.inspectionSheetSectionDescription}</h3>
                                                                        </Grid>
                                                                        {flags?.startEventLabel && (
                                                                            <Grid
                                                                                item
                                                                                xs={6}
                                                                                xl={1}
                                                                                lg={1}
                                                                                sm={3}
                                                                                md={3}
                                                                                style={{
                                                                                    background: '#F8EDE3'
                                                                                    // '#F5EDED'
                                                                                }}
                                                                            >
                                                                                <h4 className={classes.title} style={{ textAlign: 'center' }}>
                                                                                    {flags?.startEventLabel}
                                                                                </h4>
                                                                            </Grid>
                                                                        )}
                                                                        {flags?.endEventLabel && (
                                                                            <Grid item>
                                                                                {' '}
                                                                                <h4 className={classes.title} style={{ textAlign: 'center' }}>
                                                                                    {flags?.endEventLabel}
                                                                                </h4>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    container
                                                                    item
                                                                    xs={12}
                                                                    style={{
                                                                        borderBottom: '1px solid rgba(0,0,0,0.1)'
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        xl={!flags?.startEventLabel ? 12 : 5}
                                                                        lg={!flags?.startEventLabel ? 12 : 5}
                                                                        sm={12}
                                                                        md={12}
                                                                    >
                                                                        {' '}
                                                                        <h3 className={classes.title}>{m.inspectionSheetSectionDescription}</h3>
                                                                    </Grid>
                                                                    {flags?.startEventLabel && (
                                                                        <Grid item>
                                                                            <h4 className={classes.title} style={{ textAlign: 'center' }}>
                                                                                {flags?.startEventLabel}
                                                                            </h4>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <AddCheckTableHeader
                                                                    className={classes.title}
                                                                    widths={widths}
                                                                    m={m}
                                                                    classes={classes}
                                                                    StyledTableCell={StyledTableCell}
                                                                    PassAllbutton={PassAllbutton}
                                                                    changeAllChildChecks={changeAllChildChecks}
                                                                    flags={flags}
                                                                />
                                                                {m.inspectionSheetChecksVM?.map((p, i) => {
                                                                    return (
                                                                        <InspectionCheckBody
                                                                            handleInpectionRow={handleInpectionRow}
                                                                            state={state}
                                                                            HandleColspan={HandleColspan}
                                                                            p={p}
                                                                            m={m}
                                                                            index={i}
                                                                            changeResutNotesState={changeResutNotesState}
                                                                            CheckButtons={CheckButtons}
                                                                            changeState={changeState}
                                                                            imageHandler={imageHandler}
                                                                            InHire_imageHandler={InHire_imageHandler}
                                                                            changeNotesState={changeNotesState}
                                                                            StyledTableRow={StyledTableRow}
                                                                            StyledTableCell={StyledTableCell}
                                                                            flags={flags}
                                                                        />
                                                                    );
                                                                })}
                                                            </Grid>
                                                        </Grid>
                                                        <br />
                                                    </>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            ) : null}

                            {flags?.displayTyres && (
                                <Grid item container direction="row" xs={12} sm={12}>
                                    {/* <Paper className={classes.paper}> */}
                                    <Grid container xs={12} spacing={2} alignItems="center">
                                        <Grid item>
                                            <h3 className={classes.title}>Tyres Checks</h3>
                                        </Grid>
                                        <Grid item>
                                            <PrimaryButton size="small" onClick={getPreviousTyreData} style={{ textTransform: 'capitalize' }}>
                                                Use last saved data
                                            </PrimaryButton>
                                        </Grid>
                                    </Grid>
                                    <Axle
                                        state={state}
                                        handleInputs={handleInputs}
                                        classes={classes}
                                        handleInput={handleInput}
                                        vehicleNoOfAxles={state.vehicleNoOfAxles}
                                        handleInspection={handleInspection}
                                    />
                                    {/* </Paper> */}
                                </Grid>
                            )}
                            {flags?.displayBrakeTest && <InspectionBreakTest state={state} classes={classes} handleInput={handleInput} setState={setState} />}
                            {flags?.displayBrakePerformance && (
                                <InspectionBreakPerfomance Paper={Paper} classes={classes} state={state} handleInput={handleInput} />
                            )}
                            {flags?.displayRoadTest && <InspectionRoadTest Paper={Paper} classes={classes} handleInputs={handleInputs} state={state} />}
                            <InSpectionSignOff
                                classes={classes}
                                handleInput={handleInput}
                                state={state}
                                useDefaultSig={useDefaultSig}
                                clear={clear}
                                GetTechnicianSign={GetTechnicianSign}
                                signCanvas={signCanvas}
                                portalSettings={portalSettings}
                                flags={flags}
                            />
                            <Grid item xs={12} sm={4}>
                                <SecondaryButton onClick={() => submitData(0)} isBtnLoader={btnLoader}>
                                    Save
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SecondaryButton onClick={handleConfirmPopup} isBtnLoader={btnLoader}>
                                    Submit{' '}
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <SecondaryButton onClick={() => props.onClose()}>Cancel</SecondaryButton>
                            </Grid>
                        </Grid>
                    )}
                    {state.showImageModal ? (
                        <ImageUpload
                            disabled={state.isDisabled}
                            state={state}
                            onImageChange={onImageChange}
                            submitImage={sumbitImage}
                            onClose={() => setState((st) => ({ ...st, showImageModal: false }))}
                            maxNumber={10}
                            isAPI={false}
                            loader={imgLoader}
                        />
                    ) : null}

                    {state.showImageModalAPI ? (
                        <ImageUpload
                            disabled={state.isDisabled}
                            state={state}
                            isAPI={true}
                            onImageChange={onImageChangeAPI}
                            RemoveImage={RemoveImage}
                            submitImage={sumbitImageApi}
                            onClose={() => setState((st) => ({ ...st, showImageModalAPI: false }))}
                            maxNumber={10}
                            loader={imgLoader}
                        />
                    ) : null}

                    {state.showConfirmMessage ? (
                        <DialogComp title={'Confirm Vehicle Inspector Sign-Off'} onClose={handleConfirmPopup} maxWidth="lg">
                            <ConfirmationModal
                                message={
                                    'Please note that you will not be able to make any changes once submitted. Click the Confirm button to proceed or Cancel to abort.'
                                }
                                handleCancel={handleConfirmPopup}
                                handleSubmit={() => submitData(null)}
                                title={'Confirm Vehicle Inspector Sign-Off'}
                                isBtnLoader={btnLoader}
                            />
                        </DialogComp>
                    ) : null}

                    {state.openDefectInspection ? (
                        <EditVehicleDailyChecks
                            checkID={state.selectedRowInspectionID}
                            displayCheck={props.displayCheck}
                            onClose={closeInspection}
                            frompage={false}
                        />
                    ) : null}
                </div>
            </DialogComp>
        </>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <VehicleDailyChecks {...props} />
        </StateProvider>
    );
};
export default Screen;
