import { createContext, useEffect, useRef, useState } from 'react';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import { usePortalSettings } from '../../../../../../Core/Hooks/usePortalSettings';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import {
    deleteImageByID,
    getChecksEditByID,
    getImageByID,
    postChecksData,
    PostImage
} from '../../../../../../Core/Services/InternalUser/Vehicle/vehicleTabChecks';
import moment from 'moment';
import { getTitleData } from '../../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { getCompleteScheduleDropdowns } from '../../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';

export const EditInapectionContaxt = createContext();

export const EditInspectionProvide = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [widths, heights] = useWindowSize();
    const portalSettings = usePortalSettings();
    const history = useHistory();
    const [btnLoader, setBtnLoader] = useState(false);
    const [confirmBtnLoader, setConfirmBtnLoader] = useState(false);
    const [state, setState] = useState({
        vehicleDailyChecks: [],
        tyreAxles: [],
        buttonList: [],
        load: true,
        showbtn: true,
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        isSignOff: false,
        isCustomerSignOff: false,
        submitArray: [],
        vehicleNoOfAxles: null,
        hideSignOff: null,
        vehicleInspectionBrakeTestPhoto: '',
        slippageReasons: [],
        vehicleInspectionSlippageReasonID: null,
        signOffsignature: null,
        showSubmit: false,
        fromApi: false
    });

    const [flags, setFlags] = useState({});

    const [isModal, setIsModal] = useState({ actionTaken: false, selectedCheck: null, showConfirm: false });

    const { showToastSuccess, showToastError } = useToast();
    const [imgLoader, setImgLoader] = useState(false);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    useEffect(() => {
        getCheckDetails();
    }, []);

    const getCheckDetails = async () => {
        if (props.checkID) {
            try {
                let isSignOff = false;
                let isCustomerSignOff = false;
                let res = await getChecksEditByID(props.checkID, props.displayCheck);
                if (res.success) {
                    res.data.vehicleDailyChecksResult.map((m, i) => {
                        if (!m.inspectionSheetCheckResultStatusID) {
                            m.inspectionSheetCheckResultStatusID = 1;
                        }
                    });
                    let TempData = [...res.data.vehicleDailyChecksResult];

                    let checksList = TempData.map((m, i) => {
                        let checksObject;
                        if (TempData.length > 0) {
                            checksObject = TempData.filter((p) => {
                                if (m.inspectionSheetSectionDescription === p.inspectionSheetSectionDescription) {
                                    if (p.defectStatusID === 3) {
                                        isCustomerSignOff = true;
                                    } else if (p.defectStatusID === 1) {
                                        isSignOff = true;
                                    }
                                    return m;
                                }
                            });
                        }
                        checksObject.name = m.inspectionSheetSectionDescription;
                        return checksObject;
                    });

                    let vehicleChecksList = Object.values(checksList.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));
                    const axles = [1, 2, 3, 4].map((num) => res.data.tyreAxles.find((x) => x.axleNumber === num) || { axleNumber: num });

                    setState((st) => {
                        if (res.success) {
                            return {
                                ...st,
                                load: false,
                                vehicleDailyChecks: vehicleChecksList,
                                tyreAxles: axles,
                                buttonList: res.data.vehicleDailyCheckOptions,
                                ChecksObj: res.data.vehicleDailyChecksResult,
                                statusList: res.data.inspectionSheetCheckResultStatus,
                                isSignOff: isSignOff,
                                isCustomerSignOff: isCustomerSignOff
                            };
                        }
                    });
                    res.data.vehicleDailyChecks.map((item) => {
                        if (item.vehicleInspectionSignedOffSignature) {
                            setState((st) => ({
                                ...st,
                                showbtn: false
                            }));
                        }
                        setState((st) => ({
                            ...st,
                            vehicleID: item.vehicleID,
                            vehicleRegNumber: item.vehicleRegNumber,
                            date: moment(item.vehicleInspectionDateInspected).format('YYYY-MM-DD'),
                            makeID: item.vehicleMake,
                            modelID: item.vehicleModel,
                            imgtechniciansign: item.vehicleInspectionInspectorSignature,
                            vehicleInspectionScheduleID: item.vehicleInspectionScheduleID,
                            vehicleInspectionStatusID: item.vehicleInspectionStatusID,
                            vehicleInspectionDateInspected: moment(item.vehicleInspectionDateInspected).format('YYYY-MM-DD hh:mm'),
                            vehicleInspectionResult: item.vehicleInspectionResult,
                            vehicleDailyCheckMileage: item.vehicleInspectionMileage,
                            vehicleInspectionSlippageReasonID: item.vehicleInspectionSlippageReasonID,
                            vehicleDailyCheckCheckerName: item.vehicleInspectionInspectorName,
                            vehicleInspectionInspectionComment: item.vehicleInspectionInspectionComment,
                            vehicleInspectionSignedOffName: item.vehicleInspectionSignedOffName,
                            vehicleInspectionSignedOffSignature: item.vehicleInspectionSignedOffSignature,
                            signOffsignature: item.vehicleInspectionSignedOffSignature,
                            hideSignOff: item.vehicleInspectionSignedOffSignature,
                            vehicleInspectionSignedOffDate: item.vehicleInspectionSignedOffDate,
                            vehicleInspectionSignedOffIPAddress: item.vehicleInspectionSignedOffIPAddress,
                            vehicleInspectionID: item.vehicleInspectionID,
                            vehicleInspectionSignedOffComment: item.vehicleInspectionSignedOffComment,

                            vehicleInspectionBrakeTestTypeID: item.vehicleInspectionBrakeTestTypeID, // 2,
                            vehicleInspectionBrakeTestNumber: item.vehicleInspectionBrakeTestNumber, // "12345",
                            vehicleInspectionRoadCondition: item.vehicleInspectionRoadCondition, // "Test Road Conditions",
                            vehicleInspectionServiceBrakePerformance: item.vehicleInspectionServiceBrakePerformance, // 71,
                            vehicleInspectionSecondaryBrakePerformance: item.vehicleInspectionSecondaryBrakePerformance, // 72,
                            vehicleInspectionParkingBrakePerformance: item.vehicleInspectionParkingBrakePerformance, // 73,
                            vehicleInspectionBrakeTestPhoto: item.vehicleInspectionBrakeTestPhoto
                        }));
                    });

                    let flagData = res.data.inspectionSheetTypesVM[0];

                    setFlags((prevFlag) => ({
                        ...prevFlag,
                        displayBrakePerformance: flagData.displayBrakePerformance,
                        displayBrakeTest: flagData.displayBrakeTest,
                        displayMileage: flagData.displayMileage,
                        displayRoadTest: flagData.displayRoadTest,
                        displayTyres: flagData.displayTyres,
                        displayCustomerDeclaration: flagData.displayCustomerDeclaration
                    }));
                }
            } catch (error) {
                showToastError('Error fetching inspection Data ');
            }
        }
    };

    useEffect(() => {
        const fetchTitle = async () => {
            if (state.vehicleID) {
                await getTitle();
            }
        };

        fetchTitle();
    }, [state.vehicleID]);

    const getTitle = async () => {
        let vehicleInformation = await getTitleData(state.vehicleID);
        let DropDownRes = await getCompleteScheduleDropdowns();
        if (vehicleInformation.success) {
            setState((st) => ({
                ...st,
                vehicleInfo: vehicleInformation.data.details[0]?.vehicleDescription,
                vehicleNoOfAxles: vehicleInformation.data.details[0]?.vehicleNoOfAxles,
                slippageReasons: DropDownRes.data?.slippageReasons,
                vehicleNextCalibrationDate: vehicleInformation.data.details[0]?.vehicleNextCalibrationDate,
                vehicleNextLolerTestDate: vehicleInformation.data.details[0]?.vehicleNextLolerTestDate,
                vehicleNextTachoCalibrationDate: vehicleInformation.data.details[0]?.vehicleNextTachoCalibrationDate
            }));
        }
    };

    const signCanvas = useRef({});
    const clear = () => {
        if (state.vehicleInspectionSignedOffSignature) {
            setState((st) => ({
                ...st,
                vehicleInspectionSignedOffSignature: null,
                signOffsignature: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const GetTechnicianSign = () => {
        let fields = state.fields;
        setState((st) => {
            const nst = {
                ...st,
                vehicleInspectionSignedOffSignature: signCanvas.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, img: true, vehicleInspectionSignedOffSignature: AppStorage.getSignature(), signOffsignature: AppStorage.getSignature() }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};

        // if (!state.vehicleInspectionSignedOffComment) {
        //     errors.vehicleInspectionSignedOffComment = `Signed Off Comment is required`;
        //     formIsValid = false;
        // }
        if (!state.vehicleInspectionSignedOffSignature) {
            errors.vehicleInspectionSignedOffSignature = `Signature is required`;
            formIsValid = false;
        }
        if (flags.displayCustomerDeclaration === 2 || flags.displayCustomerDeclaration === 1) {
            if (!state.vehicleInspectionSignedOffName) {
                errors.vehicleInspectionSignedOffName = `Customer name is required`;
                formIsValid = false;
            }
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const submitData = async () => {
        if (validations()) {
            let data = {
                vehicleInspectionScheduleID: state.vehicleInspectionScheduleID,
                vehicleInspectionStatusID: 4, //signoff status //state.vehicleInspectionStatusID,
                vehicleInspectionDateInspected: state.vehicleInspectionDateInspected,
                vehicleInspectionResult: state.vehicleInspectionResult,
                vehicleInspectionMileage: state.vehicleDailyCheckMileage,
                vehicleInspectionSlippageReasonID: state.vehicleInspectionSlippageReasonID,
                vehicleInspectionInspectorName: state.vehicleDailyCheckCheckerName,
                vehicleInspectionInspectorSignature: state.techniciansign,
                vehicleInspectionInspectionComment: state.vehicleInspectionInspectionComment,
                vehicleInspectionSignedOffName: state.vehicleInspectionSignedOffName, //loged user name
                vehicleInspectionSignedOffSignature: state.vehicleInspectionSignedOffSignature, //current date and time
                vehicleInspectionSignedOffDate: state.vehicleInspectionSignedOffDate,
                vehicleInspectionSignedOffIPAddress: state.vehicleInspectionSignedOffIPAddress,
                vehicleInspectionID: state.vehicleInspectionID,
                vehicleInspectionBrakeTestTypeID: state.vehicleInspectionBrakeTestTypeID,
                vehicleInspectionBrakeTestNumber: state.vehicleInspectionBrakeTestNumber,
                vehicleInspectionRoadCondition: state.vehicleInspectionRoadCondition,
                vehicleInspectionServiceBrakePerformance: state.vehicleInspectionServiceBrakePerformance,
                vehicleInspectionSecondaryBrakePerformance: state.vehicleInspectionSecondaryBrakePerformance,
                vehicleInspectionParkingBrakePerformance: state.vehicleInspectionParkingBrakePerformance,
                vehicleInspectionSignedOffComment: state.vehicleInspectionSignedOffComment,
                // inspectionSheetCheckResultsVM: resultVM,
                // inspectionSheetAxlesVM: state.tyreAxles,
                vehicleInspectionBrakeTestPhoto: state.vehicleInspectionBrakeTestPhoto
            };
            setConfirmBtnLoader(true);
            let res = await postChecksData(data);
            if (res.success) {
                showToastSuccess('Inspection Check signed Off successfully');
                if (checkhaveAccess?.CanViewJobs && res?.data?.jobID) {
                    history.push({
                        pathname: `/controller/jobTabScreen/${res.data?.jobID}/${state.vehicleID}`,
                        state: 'jobs',
                        search: 'tab_testing_tabs=1'
                    });
                    props.onClose(true);
                } else {
                    props.onClose(true);
                }
            } else {
                showToastError(res.message);
            }
            setConfirmBtnLoader(false);
        }
    };

    const actionTakenHandle = (check) => {
        setIsModal((st) => ({
            ...st,
            actionTaken: true,
            selectedCheck: check
        }));
    };

    const closeActionTakenhandle = (res) => {
        setIsModal((st) => ({
            ...st,
            actionTaken: false,
            showConfirm: false
        }));
        res && getCheckDetails();
    };

    const confirmHandle = () => {
        if (validations()) {
            setBtnLoader(true);
            setIsModal((st) => ({
                ...st,
                showConfirm: true
            }));
            setBtnLoader(false);
        }
    };

    const onImageChange = (imageList, addUpdateIndex) => {
        let changedArray = [...state.ChecksObj];
        let FilterimagesList;
        let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);
        console.log(imageList, 'imageList');
        if (objIndex > -1) {
            FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
            changedArray[objIndex].images = FilterimagesList;
        }
        let UploadedPhoto = imageList.filter((k) => k.data_url);
        let uniqueImages = UploadedPhoto.map((k) => k.data_url);

        setState((st) => ({ ...st, ChecksObj: changedArray, selectedImages: FilterimagesList, submitArray: uniqueImages, showSubmit: true }));
    };

    const sumbitImage = async () => {
        let tempArray = [...state.submitArray];
        let data = {
            vehicleInspectionsPhotoID: null,
            vehicleInspectionsPhotoResultID: state.inspectionCheckResultID,
            vehicleInspectionsPhotoObject: tempArray
        };
        let res = await PostImage(data);
        if (res.success) {
            let changedArray = [...state.ChecksObj];
            let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);
            changedArray[objIndex].basicCount = state.selectedImages?.length || 0;
            changedArray[objIndex].photosUploaded = state.selectedImages?.length || 0;
            showToastSuccess('image Uploaded successfully');
            setState((st) => ({
                ...st,
                showImageModal: false,
                ChecksObj: changedArray,
                submitArray: [],
                showSubmit: true
            }));
        }
    };

    console.log(state, 'state');

    const imageHandler = async (inspectionCheckResultID) => {
        try {
            let res = await getImageByID(inspectionCheckResultID);
            if (res.data.list.length === 0) {
                let changedArray = [...state.ChecksObj];
                let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckResultID === inspectionCheckResultID);
                setState((st) => ({
                    ...st,
                    showImageModal: true,
                    inspectionCheckResultID: inspectionCheckResultID,
                    selectedImages: changedArray[objIndex].images || [],
                    fromApi: false
                }));
            } else {
                setState((st) => ({
                    ...st,
                    showImageModal: true,
                    inspectionCheckResultID: inspectionCheckResultID,
                    selectedImages: res.data.list.map((m) => m.vehicleInspectionsPhotoObject) || [],
                    getImageList: res.data.list || [],
                    fromApi: true
                }));
            }
        } catch (error) {
            showToastError('Error fetching images');
        }
    };

    const RemoveImage = async (val, i) => {
        setImgLoader(true);
        const tempList = [...state.getImageList];
        let changedArray = [...state.ChecksObj];
        let FilterimagesList = [...state.selectedImages];

        let tempObj = tempList.findIndex((k) => k.vehicleInspectionsPhotoObject == val);

        if (tempObj === i) {
            let res = await deleteImageByID(tempList[i].vehicleInspectionPhotoID);
            if (res.success) {
                let objIndex = changedArray.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);
                console.log(objIndex);
                if (objIndex > -1) {
                    // let index = FilterimagesList.findIndex((k) => k === val);
                    FilterimagesList.splice(i, 1);
                    changedArray[objIndex].images = FilterimagesList;
                    changedArray[objIndex].basicCount = FilterimagesList.length;
                    changedArray[objIndex].photosUploaded = FilterimagesList.length;
                }
                setState((st) => ({
                    ...st,
                    ChecksObj: changedArray,
                    selectedImages: FilterimagesList
                }));
                // pullEditInpectionChecks();
                showToastSuccess('Image Removed Successfully');
            }
        } else {
            let objIndex = changedArray.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);
            console.log(objIndex);
            if (objIndex > -1) {
                // let index = FilterimagesList.findIndex((k) => k === val);
                FilterimagesList.splice(i, 1);
                changedArray[objIndex].images = FilterimagesList;
                changedArray[objIndex].basicCount = FilterimagesList.length;
                changedArray[objIndex].photosUploaded = FilterimagesList.length;
            }
            setState((st) => ({
                ...st,
                ChecksObj: changedArray,
                selectedImages: FilterimagesList
            }));
        }
        setImgLoader(false);
    };

    return (
        <EditInapectionContaxt.Provider
            value={{
                checkhaveAccess,
                widths,
                portalSettings,
                btnLoader,
                confirmBtnLoader,
                state,
                flags,
                isModal,
                handleInput,
                clear,
                GetTechnicianSign,
                imageHandler,
                onImageChange,
                useDefaultSig,
                submitData,
                actionTakenHandle,
                closeActionTakenhandle,
                confirmHandle,
                sumbitImage,
                setState,
                signCanvas,
                imgLoader,
                RemoveImage
            }}
        >
            {props.children}
        </EditInapectionContaxt.Provider>
    );
};
