import { FormHelperText, Grid, Typography, InputLabel, Button } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { CustomChip, formatters, PrimaryButton } from '../../../../../Core/Controls';
import '../../../../commonStyle.scss';
import '../../controllerStyle.scss';
import { getJobDetailsById, getJobRequiredDocuments } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import '../../Notes/NotesandSticky.scss';
import { useHistory } from 'react-router-dom';
import StickyNotes from '../../Notes/stickyNotes';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import FileUploaderButton from '../../../../../Core/Controls/GenericFileUploader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BuildIcon from '@material-ui/icons/Build';

import JobCustomizeStepper from './JobSteppers';

import CustomerDetails from '../../DetailsModal/customerDetails';
import DepotDetails from '../../DetailsModal/depotDetails';
import VehicleDailyChecks from '../../Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../../Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import { CustomButtonChip } from '../../../../../Core/Controls/Inputs/DatePicker';
import { useWindowSize } from '../../../../../Core/Hooks/useWindowSize';
import AllStickyNotes from '../../Notes/stickyAllNotes';

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

const JobDetail = (props) => {
    const [ScreenWidth, ScreenHeight] = useWindowSize();
    const history = useHistory();
    const [state, setState] = useState({ showModal: false, refresherFlag: null });
    const [jobDetails, setJobDetails] = useState(props.jobDetails);
    // const [jobdocument, setJobDocument] = useState({
    //     list: []
    // });
    // if(props?.jobdocument?.inspectionDoc)
    //     const { scheduleDocID, scheduleEventID, scheduleID, vehicleInspectionID, displayEditInspectionCheck } = props?.jobdocument?.inspectionDoc;

    const dataReload = () => {
        setState((state) => ({ ...state, refresherFlag: new Date() }));
    };
    const closetModal = (res) => {
        setState({ show: false, showCustomerModal: false, showDepotModal: false, showAddInspection: false, showEditInspection: false });
        res && props.pullJobDetail();
    };
    useEffect(() => {
        setJobDetails(props.jobDetails);
        setState((st) => ({ ...st, showModal: props?.jobDetails?.customerIsOnStop }));
    }, [props.jobDetails]);

    const handleInspectionModal = () => {
        setState((st) => {
            let newSt = { ...st };
            newSt.showAddInspection = props?.jobdocument?.inspectionDoc?.displayAddInspectionCheck === 'Y' ? true : false;
            newSt.showEditInspection = props?.jobdocument?.inspectionDoc?.displayEditInspectionCheck === 'Y' ? true : false;
            return newSt;
        });
    };

    // useEffect(async () => {
    //     if (props.jobDetails?.jobID && props.showDoc) {
    //         let res = await getJobRequiredDocuments(props.jobDetails?.jobID);
    //         setJobDocument((state) => ({ ...state, list: res.data.list }));
    //     }
    // }, [props.jobDetails?.jobID]);

    // const getMakeModel =
    //     jobDetails?.vehicleMake || jobDetails?.vehicleModel
    //         ? ` ${jobDetails?.vehicleMake ? jobDetails?.vehicleMake : ''} ${jobDetails?.vehicleModel ? jobDetails?.vehicleModel : ''}`
    //         : '';
    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${jobDetails?.jobVehicleID}`,
            search: ''
        });
    };
    const handleShowDetails = (val) => {
        setState({ [val]: true });
    };
    return (
        <div className="controller-screen">
            {/* <StickyNotes frompage="Customers" customerID={jobDetails?.customerID} bgColor="#ffcf89" />
            <StickyNotes frompage="Depots" depotID={jobDetails?.jobDepotID} bgColor="#ffcf89" />
            <StickyNotes frompage="Vehicle" NoteVehicleID={jobDetails?.jobVehicleID} bgColor="#ffcf89" /> */}
            <AllStickyNotes
                frompage="Jobs"
                jobID={props?.scheduleJobID}
                customerID={jobDetails?.customerID}
                depotID={jobDetails?.jobDepotID}
                NoteVehicleID={jobDetails?.jobVehicleID}
                // variant="filled"
            />
            <Grid container>
                <Grid item xs={12} style={{ paddingTop: '5px' }}>
                    {jobDetails?.customerCreditAccountMessage && (
                        <Alert variant="filled" severity="error" style={{ padding: 7 }}>
                            {jobDetails?.customerCreditAccountMessage}
                        </Alert>
                    )}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '5px' }}>
                    {jobDetails?.customerIsOnStop && (
                        <Alert variant="filled" severity="error" style={{ padding: 7 }}>
                            The customer - {jobDetails?.customerName} is on stop
                        </Alert>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={0} className="mt_10">
                <Grid item xs={12} lg={12}>
                    <Grid item container xs={12} justify="flex-start" style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                        {jobDetails?.jobOrderNo ? (
                            <Grid xs={12} sm={12} md={4} lg={4} xl>
                                <FormHelperText shrink>Final Order No </FormHelperText>
                                <Typography variant="button">
                                    {jobDetails?.jobOrderNo || ''}({jobDetails?.jobAgentOrderNo})
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid xs={6} sm={4} md={4} lg={2} xl>
                                <FormHelperText shrink>Job Reference</FormHelperText>
                                <Typography variant="button">{jobDetails?.jobAgentOrderNo || ''}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={6} sm={2} md={3} lg={1} xl>
                            <FormHelperText shrink>Order Date</FormHelperText>
                            <Typography variant="button">{jobDetails?.jobBookingDate}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={5} lg={2} xl>
                            <FormHelperText shrink>Customer</FormHelperText>
                            <Typography variant="button">
                                <span onClick={() => handleShowDetails('showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                    {jobDetails?.customerName}
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} lg={2} xl>
                            <FormHelperText shrink>Depot</FormHelperText>
                            <Typography variant="button">
                                <span onClick={() => handleShowDetails('showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                    {jobDetails?.depotName}
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2} md={3} lg xl>
                            <FormHelperText shrink>Reg No</FormHelperText>
                            <Typography variant="button">
                                <span onClick={handleEditRegClick} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                    {jobDetails?.jobRegNumber}
                                </span>
                                {/* &nbsp;({getMakeModel.trim()}) */}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <FormHelperText shrink>Job Status</FormHelperText>
                            <Typography variant="button">{jobDetails?.vehicleJobStatus}</Typography>
                        </Grid> */}
                        <Grid item xs={6} sm={'auto'} lg xl>
                            <FormHelperText shrink>Job Priority</FormHelperText>
                            <Typography variant="button">{jobDetails?.jobTypeDescription}</Typography>
                        </Grid>
                        <Grid item xs sm={'auto'} lg xl={1} style={{ marginLeft: ScreenWidth > 450 ? '10px' : '' }}>
                            <div style={{ width: '100%', textAlign: ScreenWidth > 450 ? 'end' : 'left' }}>
                                <FormHelperText style={{ textAlign: ScreenWidth > 450 ? 'end' : 'left' }}>Chargeable </FormHelperText>
                                <Typography align="right" variant="button" style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
                                    {/* £{jobDetails?.jobCustomerOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} */}
                                    {formatters.CurrencyNumberWithPound(jobDetails?.chargeable)}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs sm={'auto'} lg xl style={{ marginLeft: ScreenWidth > 450 ? '10px' : '' }}>
                            <div style={{ width: '100%', textAlign: ScreenWidth > 450 ? 'end' : 'left' }}>
                                <FormHelperText style={{ textAlign: ScreenWidth > 450 ? 'end' : 'left' }}>Non Chargeable </FormHelperText>
                                <Typography variant="button" style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
                                    {formatters.CurrencyNumberWithPound(jobDetails.nonChargable)}
                                </Typography>
                            </div>
                        </Grid>
                        {/* <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Reg Number</FormHelperText>
                            <Typography variant="button">{jobDetails?.jobRegNumber}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Make</FormHelperText>
                            <Typography variant="button">{jobDetails?.vehicleMake}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Model</FormHelperText>
                            <Typography variant="button">{jobDetails?.vehicleModel}</Typography>
                        </Grid> */}
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} style={{ marginTop: 3 }}>
                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                        {jobDetails?.jobDescription}
                    </Alert>
                </Grid> */}
                <Grid item xs={12} style={{ marginTop: 3 }}>
                    <JobCustomizeStepper VehicleID={jobDetails?.jobVehicleID} jobDetails={jobDetails} pullJobDetail={props.pullJobDetail} />
                </Grid>
                {props.showDoc &&
                    props.jobdocument?.list.map((m, i) => {
                        return (
                            <Grid item>
                                {/* // <PrimaryButton variant="outlined">
                                    //     {m.docName}
                                    //     <span style={{ position: 'relative', top: '-1px', left: '7px', marginRight: '15px' }}>
                                    //         <FileUploaderButton
                                    //             recordId={m.scheduleDocID}
                                    //             fileUploaderConfigName="ScheduleDocumentsRequired"
                                    //             fileCount={m.fileCount}
                                    //             gridRelaod={dataReload}
                                    //             docIDPassed={true}
                                    //             // isDash={true}
                                    //             // docName={m.docName}
                                    //         />
                                    //     </span>
                                    // </PrimaryButton> */}
                                <div style={{ paddingTop: '5px' }}>
                                    {/* {m.docName} */}
                                    {i === 0 && (
                                        <>
                                            <span style={{ position: 'relative', top: '0px', left: '0px', marginRight: '15px' }}>
                                                <CustomButtonChip
                                                    label={'Inspection'}
                                                    icon={BuildIcon}
                                                    style={{
                                                        marginTop: 10,
                                                        backgroundColor: props?.jobdocument?.inspectionDoc?.displayEditInspectionCheck === 'Y' ? 'green' : '',
                                                        color: props?.jobdocument?.inspectionDoc?.displayEditInspectionCheck === 'Y' ? 'white' : ''
                                                    }}
                                                    onClick={handleInspectionModal}
                                                />
                                            </span>
                                            &nbsp;
                                        </>
                                    )}
                                    <span style={{ position: 'relative', top: '-7px', left: '-3px', marginRight: '15px' }}>
                                        <FileUploaderButton
                                            recordId={m.scheduleDocID}
                                            fileUploaderConfigName="ScheduleDocumentsRequired"
                                            fileCount={m.fileCount}
                                            gridRelaod={dataReload}
                                            docIDPassed={true}
                                            isDash={true}
                                            docName={m.docName}
                                            style={{ minWidth: '157px', justifyContent: 'flex-start' }}
                                        />
                                    </span>
                                </div>
                                {/* )} */}
                            </Grid>
                        );
                    })}
                {state.showModal ? (
                    <DialogComp title={`Warning`} maxWidth="md" onClose={() => setState((st) => ({ ...st, showModal: false }))} fullWidth>
                        <Alert severity="warning">Customer is on stop</Alert>
                        <br />
                    </DialogComp>
                ) : null}
            </Grid>
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={() => closetModal(false)} title={'Customer Details'} maxWidth="sm" fullWidth>
                    <CustomerDetails customerID={jobDetails.customerID} onFormSubmit={() => closetModal(false)} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={() => closetModal(false)} title={'Depot Details'} maxWidth="sm" fullWidth>
                    <DepotDetails customerID={jobDetails.jobDepotID} onFormSubmit={() => closetModal(false)} />
                </DialogComp>
            ) : null}
            {state.showAddInspection && (
                <VehicleDailyChecks
                    sheduleVechicleID={jobDetails?.jobVehicleID}
                    sheduleID={props?.jobdocument?.inspectionDoc?.scheduleID}
                    scheduleEventID={props?.jobdocument?.inspectionDoc?.scheduleEventID}
                    onClose={closetModal}
                    displayCheck={props?.jobdocument?.inspectionDoc?.displayCheck}
                    frompage={false}
                    checkID={props?.jobdocument?.inspectionDoc?.vehicleInspectionID}
                />
            )}
            {state.showEditInspection && (
                <EditVehicleDailyChecks
                    checkID={props?.jobdocument?.inspectionDoc?.vehicleInspectionID}
                    displayCheck={props?.jobdocument?.inspectionDoc?.displayCheck}
                    onClose={closetModal}
                    frompage={false}
                />
            )}
        </div>
    );
};
export default JobDetail;
