import React, { useEffect, useState, useMemo } from 'react';
import { BreadCrumbs, DataGrid, EditButton, formatters, InputText, SimpleField } from '../../../../Core/Controls';
import { getVechicleIDByRegNO } from '../../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import AddBreaddownModal from './AddBreakdown';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton, SecondaryButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import { useHistory } from 'react-router-dom';
import ReactDOM from 'react-dom';
import ServiceAgentDetails from '../../Controllers/DetailsModal/serviceAgent';
import DepotDetails from '../../Controllers/DetailsModal/depotDetails';
import CustomerDetails from '../../Controllers/DetailsModal/customerDetails';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Maintenance', active: true },
    { name: 'Breakdowns', path: '/maintenance/breakdowns/:all', active: true }
];
const baseColumns = [
    // { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'depotName', name: 'Depot Name', width: 180 },
    { key: 'agent', name: 'Service Area', width: 110 },
    { key: 'vor', name: 'VOR', width: 60 },
    { key: 'accidentDamge', name: 'Chargable Accident Damage', width: 180 },
    { key: 'status', name: 'Status', width: 110 },
    { key: 'defectContactName', name: 'Reported By', width: 100 },
    {
        key: 'defectDate',
        name: 'Date',
        formatter: formatters.Date_DDMMYYYY,
        width: 80
    },
    { key: 'model', name: 'Make/Model', width: 100 }
    // {
    //     key: 'defectNumberGenerated',
    //     name: 'Defect No',
    //     headerRenderer: formatters.ColumnHeaderAlignLeft,
    //     formatter: formatters.ColumnTextAlignLeft,
    //     width: '6%'
    // },
    // { key: 'defectDescription', name: 'Details' }
    // { key: 'defectDriverName', name: 'Driver Name', width: 160 },
    // { key: 'defectDriverTelephone', name: 'Driver Phone', width: 120 },
    // { key: 'defectType', name: 'Defect Type', width: 160 }
];

const BreakDownDashboard = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    let history = useHistory();
    const [state, setState] = useState({
        breakdownList: [],
        loader: true,
        modalToggle: false,
        breakID: null,
        rowVehicleID: null,
        show: false,
        filterVal: ''
    });

    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');
    const isEngineer = userRoles.includes('engineer');
    const isServicearea = userRoles.includes('servicearea');

    const handleBreakDown = (params, VehicleID, modalName) => {
        setState((st) => ({
            ...st,
            modalName: modalName,
            modalToggle: true,
            breakID: params,
            rowVehicleID: VehicleID
        }));
    };
    const closeModal = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.modalToggle = false;
            newSt.show = false;
            if (res) newSt.filterVal = '';
            return newSt;
        });

        res && pullBreakDownAndUpdateState();
    };

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    const getRegNumberVal = (setRegNumber) => {
        // setState((st) => ({
        //     ...st,
        //     filterVal: setRegNumber
        // }));
    };

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = resBrekDown.success;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                    } else {
                        newSt.show = false;
                    }
                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }

        pullBreakDownAndUpdateState();
    }, [props.VehicleID, state.filterVal, props?.match?.params?.all]);

    const pullBreakDownAndUpdateState = async () => {
        let defecttypecode = '';
        if (props.VehicleID) {
            defecttypecode = '';
        }
        if (props?.match?.params?.all == 'typebo') {
            defecttypecode = 'bo';
        }
        if (props?.match?.params?.all == 'typebr') {
            defecttypecode = 'br';
        }
        if (props?.match?.params?.all == ':all') {
            defecttypecode = '';
        }

        setState((st) => ({
            ...st,
            isReload: new Date(),
            defecttypecode: defecttypecode
        }));
    };

    let urlForcondition = `VehicleBreakdowns/?${props.VehicleID ? `VehicleID=${props.VehicleID}` : ''}defecttypecode=${state.defecttypecode}`;

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.defectJobID}/${val?.defectVehicleID}`,
            state: 'breakdown'
        });
    };

    const handleEditBreakdown = (val) => {
        history.push({
            pathname: `/maintenance/breakdowns/Edit/${val?.defectID}/${val?.defectVehicleID}`,
            state: 'Edit'
        });
    };

    // const dataFormatter = (data, row) => {
    //     return (
    //         <Grid item>
    //             <CustomIconButton
    //                 icon={BuildIcon}
    //                 color="secondary"
    //                 className="modal-btn-update btn_space"
    //                 toolTipTitle="Edit Breakdown"
    //                 // onClick={() => handleBreakDown(row.defectID, row.defectVehicleID, 'Edit')}
    //                 onClick={() => handleEditBreakdown(row)}
    //             />
    //             {/* <EditButton toolTipTitle="Edit Breakdown" onClick={() => handleBreakDown(row.defectID, row.defectVehicleID, 'Edit')} /> */}
    //             {/* {isDriver ? null : (
    //                 <CustomIconButton
    //                     icon={WorkIcon}
    //                     color="secondary"
    //                     className="modal-btn-update btn_space"
    //                     toolTipTitle="Job Details"
    //                     onClick={() => handleEditClick(row)}
    //                 />
    //             )} */}
    //         </Grid>
    //     );
    // };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.defectVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditBreakdown(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.defectRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agent || ''}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer || ''}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        // let actionCol = tempCols.find((element) => element.key === 'action');
        let agentNameCol = tempCols.find((element) => element.key === 'agent');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        // if (actionCol) {
        //     actionCol.formatter = dataFormatter;
        // }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (!props.VehicleID) {
            tempCols.splice(0, 0, { key: 'defectRegNumber', name: 'Reg Number', width: 95, formatter: renderRegNumberEdit });
            tempCols.splice(2, 0, { key: 'customer', name: 'Customer Name', width: 210, formatter: renderCustomerName });
        }
        if (isEngineer || isServicearea) {
            tempCols.splice(
                2,
                tempCols.length,
                { key: 'agent', name: 'Service Area', width: 110 },
                { key: 'status', name: 'Status', width: 110 },
                { key: 'defectDescription', name: 'Problem Reported (Description)', width: 130 },
                { key: 'vor', name: 'VOR', width: 60 },
                { key: 'customer', name: 'Customer Name', width: 210, formatter: renderCustomerName },
                { key: 'depotName', name: 'Depot Name', width: 180 },
                {
                    key: 'defectDate',
                    name: 'Date',
                    formatter: formatters.Date_DDMMYYYY,
                    width: 80
                }
            );
        }
        return tempCols;
    }, []);
    const gridBtnTitle = state.show || (props.VehicleID && checkhaveAccess?.CanAddBreakdowns) ? 'Add Breakdown' : null;

    return (
        <div className={!props.frompage ? `screen` : ''}>
            {state.modalToggle ? (
                <>
                    <br />
                    <AddBreaddownModal
                        VehicleID={props.VehicleID || state.rowVehicleID}
                        onClose={() => closeModal(true)}
                        breakDownID={state.breakID}
                        modalName={state.modalName}
                        frompage={props.frompage || 'Breakdown'}
                    />
                </>
            ) : (
                <>
                    {props.frompage !== 'Vehicles' ? (
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                            <div style={{ width: 'auto' }}>
                                <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                            </div>
                            &nbsp;&nbsp;
                            {checkhaveAccess?.CanAddBreakdowns && (
                                <div style={{ width: 'auto' }}>
                                    <input type="text" onChange={inputChange} placeholder="Enter Reg Number" value={state.filterVal} style={{ padding: 5 }} />
                                </div>
                            )}
                            {gridBtnTitle && (
                                <div style={{ marginLeft: '4px' }}>
                                    <SecondaryButton
                                        style={{ padding: '2px 16px' }}
                                        onClick={() => handleBreakDown(null, state.filterVehicleID, 'Add')}
                                        fullWidth
                                        // className="submit_btn"
                                    >
                                        Add
                                    </SecondaryButton>
                                </div>
                            )}
                        </div>
                    ) : null}

                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        idFieldName={`${checkhaveAccess?.CanUploadBreakdownsDocuments ? 'defectID' : null}`}
                        fileUploaderConfigName={`${checkhaveAccess?.CanUploadBreakdownsDocuments ? 'BreakdownScreen' : null}`}
                        getUrl={`VehicleBreakdowns/?${props.VehicleID ? `VehicleID=${props.VehicleID}&` : ''}defecttypecode=${
                            state.defecttypecode ? state.defecttypecode : ''
                        }`}
                        // btnTitle={gridBtnTitle}
                        // onBtnClick={() => handleBreakDown(null, state.filterVehicleID, 'Add')}
                        isReload={state.isReload}
                        getRegNumberVal={getRegNumberVal}
                    />
                </>
            )}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.defectHomeDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default BreakDownDashboard;
