import { Button, ButtonGroup, Grid, Switch } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { BreadCrumbs, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import HireFleetLink from './HireFleetLinkModal';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import LinkIcon from '@material-ui/icons/Link';
import ReactDOM from 'react-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import moment from 'moment';
import { GetHireContractExcel } from '../../../../Core/Services/InternalUser/excelDownload';

var $ = window.$;

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Hire Fleet', path: '/', active: true }
];

const baseColumns = [
    // {
    //     key: 'action',
    //     name: 'Action',
    //     width: 80
    //     // addFileUploaderIcon: true
    // },
    {
        key: 'regNo',
        name: 'Reg Number',
        width: 90
        // events: {
        //     click: function (e) {
        //         props.history.push(`/controller/editVehicle/${e.data.record.vehicleID}`);
        //     }
        // }
    },
    { key: 'ref', name: 'Ref', width: 90 },
    { key: 'vehicleType', name: 'Vehicle Type', width: 100 },
    { key: 'customer', name: 'Customer', width: 200 },
    { key: 'depot', name: 'Depot', width: 200 },
    {
        key: 'startDate',
        name: 'Start Date',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    { key: 'onSiteDate', name: 'Onsite Date', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'earliestOffHire', name: 'Earliest Off Hire', formatter: formatters.Date_DDMMYYYY, width: 110 },
    { key: 'endDate', name: 'End', formatter: formatters.Date_DDMMYYYY, width: 100 },
    { key: 'serviceAgentName', name: 'Service Area', width: 150 },
    { key: 'tech', name: 'Engineer', width: 150 },
    // { key: 'purchaseOrder', name: 'Purchase Order', width: 100 },
    { key: 'rate', name: 'Rate', formatter: formatters.NumberWithPound, width: 100, align: 'right' },
    { key: 'status', name: 'Status', width: 150, filterDropDownColName: 'vehicleContractStatus' }
];

const HireFleetScreen = (props) => {
    let DummyList = [];
    const query = new URLSearchParams(props.history.location.search);
    const isFreeStock = query.get('isFreeStock');
    const isSetAll = Boolean(query.get('isSetAll'));
    const isContractID = query.get('contractID');
    const vehicleJobStatusParam = query.get('vehicleJobStatus') || '';
    const status = query.get('status') || '';
    const serviceAgentName = query.get('agentName') || '';
    const { userRoles } = useLoginInfo();
    const isEngineer = userRoles.includes('engineer');
    const isServicearea = userRoles.includes('servicearea');

    const [state, setState] = useState({
        filterFlag: isSetAll ? '' : !isFreeStock,
        checkedVehicleList: [],
        buttonState: isSetAll ? 3 : isFreeStock ? 2 : 1
    });

    let checkhaveAccess = AppStorage.getAllowAcces();

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setState((st) => ({ ...st, [name]: checked }));
    };

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            show: true
        }));
    };

    let getUrl = `HireFleetContract/HireFleet_List?FreeStock=${state.filterFlag}&vehicleJobStatus=${vehicleJobStatusParam}${status && `&status=${status}`}${
        serviceAgentName && `&serviceAgentName=${serviceAgentName}`
    }`;

    const handleModal = (res, ContractID = '') => {
        setState((st) => {
            const newSt = { ...st };
            newSt.showModal = false;
            // if (res) newSt.isReload = new Date();
            return newSt;
        });
        if (res) {
            props.history.push({
                pathname: `/contractList/editcontractList/${ContractID}`,
                search: ``
            });
        }
    };

    const getVal = () => {
        setState((st) => ({
            ...st,
            checkedVehicleList: DummyList,
            showModal: true
        }));
    };

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <ChipWithoutLable icon={LinkIcon} onClick={getVal} style={{ fontWeight: 'bold' }} toolTipTitle={'Link Contract'} />
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        props.history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: `${val.contractID ? '?redirectLabel=Contract' : ''}`
        });
    };

    const renderRegNo = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row, 'showCustomerModal')} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
                {row.regNo}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        let regNoCol = tempCols.find((element) => element.key === 'regNo');
        if (isEngineer) {
            tempCols.splice(
                0,
                tempCols.length,
                { key: 'customer', name: 'Customer', width: '15%' },
                { key: 'depot', name: 'Depot', width: '15%' },
                { key: 'regNo', name: 'Reg Number', width: '15%' },
                { key: 'winterStartDate', name: 'Winter Start Date', formatter: formatters.Date_DDMMYYYY, width: '15%' },
                { key: 'vehicleMake', name: 'Vehicle Make', width: '15%' },
                { key: 'vehicleType', name: 'Vehicle Type', width: '15%' },
                { key: 'status', name: 'Status', width: '15%' }
            );
        }
        if (isServicearea) {
            tempCols.splice(
                0,
                tempCols.length,
                { key: 'regNo', name: 'Reg Number', width: '15%' },
                { key: 'customer', name: 'Customer', width: '15%' },
                { key: 'depot', name: 'Depot', width: '15%' },
                { key: 'winterStartDate', name: 'Winter Start Date', formatter: formatters.Date_DDMMYYYY, width: '15%' },
                { key: 'onSiteDate', name: 'Onsite Date', formatter: formatters.Date_DDMMYYYY, width: '15%' },
                { key: 'earliestOffHire', name: 'Earliest Off Hire', formatter: formatters.Date_DDMMYYYY, width: '20%' },
                { key: 'vehicleMake', name: 'Vehicle Make', width: '15%' },
                { key: 'vehicleType', name: 'Vehicle Type', width: '15%' },
                { key: 'rate', name: 'Rate', formatter: formatters.NumberWithPound, width: '15%', align: 'right' },
                { key: 'status', name: 'Status', width: '15%' }
            );
        }
        if (regNoCol) {
            regNoCol.formatter = renderRegNo;
        }
        return tempCols;
    }, []);

    const onRowDataBound = (e, $row, id, record, GridRef) => {
        if (DummyList.some((q) => record.vehicleID == q.vehicleID)) {
            GridRef.setSelected(id);
        }
        if (record.contractID) {
            var checkboxCell = $row.find('input[type="checkbox"][data-role="selectRow"]').closest('td');
            checkboxCell.empty().append('<span></span>');
        }

        $row.css('background-color', record.background);
        $row.css('color', record.colour);
    };

    const onCellDataBound = (e, $row, id, column, record) => {
        // if (record.offHireDate) {
        //     $row.css('color', `#F5F5F5`);
        // }
        // if (!record.offHireDate && moment(record.earliestOffHire).isBefore(moment())) {
        //     $row.css({
        //         color: '#F5F5F5'
        //     });
        // }
        // if (!record.offHireDate && moment(record.earliestOffHire).isAfter(moment())) {
        //     $row.css({
        //         color: '#F5F5F5'
        //     });
        // }
    };
    const onRowSelect = (e, $row, id, record) => {
        let vehicleIndex = DummyList.findIndex((q) => q.vehicleID === record.vehicleID);
        if (vehicleIndex == -1) {
            DummyList.push(record);
        }
    };
    const onRowUnSelect = (e, $row, id, record) => {
        let vehicleIndex = DummyList.findIndex((q) => q.vehicleID === record.vehicleID);
        if (vehicleIndex > -1) {
            DummyList.splice(vehicleIndex, 1);
        }
    };

    const handleFilter = (cond, val) => {
        setState((st) => ({
            ...st,
            buttonState: val,
            filterFlag: cond
        }));
    };

    return (
        <div className="screen">
            <Grid container>
                {/* <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    <Grid component="label" item className="childSwitchBtnContainer" alignItems="center">
                        <Grid container alignItems="center">
                            <Grid item>Freestock</Grid>
                            <Grid item>
                                <Switch checked={state.filterFlag} onChange={handleChange} value="checked" name="filterFlag" />
                            </Grid>
                            <Grid item>All</Grid>
                        </Grid>
                    </Grid>
                </Grid> */}

                <Grid container justify="space-between">
                    <Grid item>
                        <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                    </Grid>
                    {!isEngineer && (
                        <Grid component="label" item alignItems="center">
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 1 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter(true, 1)}
                                >
                                    Hired
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 2 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter(false, 2)}
                                >
                                    Freestock
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.buttonState === 3 ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter('', 3)}
                                >
                                    All
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    )}
                </Grid>

                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    btnTitle={'Link Contarct'}
                    // onBtnClick={handleAdd}
                    isReload={state.isReload}
                    selectionType={checkhaveAccess.CanAddHireContracts ? 'multiple' : ''}
                    selectionMethod={checkhaveAccess.CanAddHireContracts ? 'checkbox' : ''}
                    showCheckboxColFilterBtn={checkhaveAccess.CanAddHireContracts ? mailButton : ''}
                    onRowDataBound={(e, $row, id, record, GridRef) => onRowDataBound(e, $row, id, record, GridRef)}
                    onRowSelect={(e, $row, id, record) => onRowSelect(e, $row, id, record)}
                    onRowUnSelect={(e, $row, id, record) => onRowUnSelect(e, $row, id, record)}
                    // idFieldName="serviceAgentID"
                    // fileUploaderConfigName="ServiceAgentScreen"
                    // mapBtnToolTipTitle={mapBtnTitle}
                    // mapBtnIcon={RoomIcon}
                    // mapBtnLabel={mapBtnTitle}
                    // onMapBtnClick={handleShowLocation}
                    downloadBtnName={!isEngineer ? 'Download xlsx' : null}
                    downloadCustomUrl={(params) =>
                        GetHireContractExcel({
                            FreeStock: state.filterFlag,
                            vehicleJobStatus: vehicleJobStatusParam,
                            fileExt: 'xlsx',
                            filterValues: params || {}
                        })
                    }
                />
            </Grid>
            {state.showModal ? (
                <DialogComp title={'Setup Vehicle Hire Contract'} onClose={() => handleModal(false)} maxWidth="xl" fullWidth overflow="auto" fullScreen>
                    <HireFleetLink onClose={handleModal} selectedRecords={state.checkedVehicleList || []} contractID={isContractID} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default HireFleetScreen;
