import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';

import StepConnector from '@material-ui/core/StepConnector';

import DialogComp from '../../../../Core/Dialog/DialogComp';
import { getVehicleContractStatusStepper } from '../../../../Core/Services/InternalUser/InternalDashService';
import CompleteScheduleModalForContract from './statusModals/completeSchedule';
import ContractSetDeliveryDateModal from './statusModals/ContractSetDeliveryDateModal';
import ContractSetCollectedModal from './statusModals/ContractSetCollectedModal';
import ContractSetOnsiteDateModal from './statusModals/ContractSetOnsiteDateModal';
import ContractSetOffHireModal from './statusModals/ContractSetOffHireModal';
import ContractSetVehiclePickedModal from './statusModals/ContractSetVehiclePickedModal';
import ContractSetAwaitingFactoryInspection from './statusModals/ContractSetAwaitingFactoryInspection';
import { Grid, Tooltip } from '@material-ui/core';

import BuildIcon from '@material-ui/icons/Build';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SnoozeIcon from '@material-ui/icons/Snooze';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import ContractSetPauseCompleted from './statusModals/ContractSetPausedComplete';
import ContractSetResume from './statusModals/ContractSetResumeContract';
import ContractOffHireOverRide from './statusModals/OffHireOverRide';
import moment from 'moment';

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            // backgroundImage: 'linear-gradient( 136deg, #0f9b0f  0%, #0b4d0b 80%, #031a03 100%)'
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 5,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: 'black',
        width: 35,
        height: 35,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, #0f9b0f  30%, #0b4d0b 80%, #031a03 100%)',
        // backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        color: '#fff'
    },
    completed: {
        // backgroundImage: 'linear-gradient( 136deg, #0f9b0f  30%, #0b4d0b 80%, #031a03 100%)',
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',

        color: '#fff'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const styles = {
        backgroundColor: active ? 'green' : '#ccc', // Green when active, grey otherwise
        width: 50,
        height: 50,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const icons = {
        1: <BuildIcon style={{ fontSize: 16 }} />,
        2: <LocalShippingIcon style={{ fontSize: 16 }} />,
        3: <EmojiTransportationIcon style={{ fontSize: 16 }} />,
        4: <BuildIcon style={{ fontSize: 16 }} />,
        5: <EmojiTransportationIcon style={{ fontSize: 16 }} />,
        6: <SnoozeIcon style={{ fontSize: 16 }} />,
        7: <BuildIcon style={{ fontSize: 16 }} />,
        8: <LocalShippingIcon style={{ fontSize: 16 }} />,
        9: <BuildIcon style={{ fontSize: 16 }} />,
        10: <SnoozeIcon style={{ fontSize: 16 }} />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function ContractStepper(props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(1);
    const [openDialog, setOpenDialog] = useState({});
    const [modalStep, setModalStep] = React.useState(-1);
    const [state, setState] = useState({
        modalIndex: 0,
        modalLabel: '',
        65: [],
        resData: {},
        checkedVehicleList: [props.vehicleID],
        isUpdate: '',
        summerServiceScheduleID: null,
        onhireScheduleID: null,
        offhireScheduleID: null,
        factoryInspectionScheduleID: null,
        steps: []
    });

    useEffect(() => {
        async function fetchDropdown() {
            try {
                if (props.vehicleID) {
                    let res = await getVehicleContractStatusStepper(props.vehicleID);
                    if (res.success) {
                        let data = res?.data?.details.table[0];
                        switch (data.contractStatusID) {
                            case 1:
                                setActiveStep(0);
                                break;
                            case 2:
                                setActiveStep(1);
                                break;
                            case 3:
                                setActiveStep(1);
                                break;
                            case 4:
                                setActiveStep(2);
                                break;
                            case 5:
                                setActiveStep(3);
                                break;
                            case 6:
                                setActiveStep(data.contractWinterEndDate && moment(data.contractWinterEndDate).isSameOrBefore(moment()) ? 5 : 4);
                                break;
                            case 7:
                                setActiveStep(6);
                                break;
                            case 8:
                                setActiveStep(7);
                                break;
                            case 9:
                                setActiveStep(7);
                                break;
                            case 10:
                                setActiveStep(8);
                                break;
                            // case 11:
                            //     setActiveStep(9);
                            //     break;
                            default:
                                setActiveStep(-1);
                                break;
                        }
                        setState((st) => ({
                            ...st,
                            resData: data,
                            contractStatusID: data.contractStatusID,
                            summerServiceScheduleID: data.summerServiceScheduleID,
                            onhireScheduleID: data.onhireScheduleID,
                            offhireScheduleID: data.offhireScheduleID,
                            factoryInspectionScheduleID: data.factoryInspectionScheduleID,
                            authorityReference: data.authorityReference,
                            winterEndDate: data.winterEndDate,
                            contractSummerServiceAreaID: data.contractSummerServiceAreaID,
                            contractDeliveryTransportCompanyID: data.contractDeliveryTransportCompanyID,
                            contractCollectionTransportCompanyID: data.contractCollectionTransportCompanyID
                        }));
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }

        return fetchDropdown();
    }, [props.vehicleID, state.isUpdate, props.isReload]);

    useEffect(() => {
        try {
            let data = state.resData;
            let Steps = [
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step1}
                            </Grid>
                            <Grid item xs={12}>
                                <small style={{ fontSize: 10, fontWeight: 700 }}>
                                    <b>{data.summerServiceDue ? data.summerServiceDue : 'Schedule Not Set'}</b>
                                </small>
                            </Grid>
                            {data.summerServiceDue && (
                                <Grid item xs={12}>
                                    <small style={{ fontSize: 10, fontWeight: 700, color: data.summerServicCompleted ? 'black' : 'grey' }}>
                                        <b>{data.summerServicCompleted ? data.summerServicCompleted : 'Not Completed'}</b>
                                    </small>
                                </Grid>
                            )}
                        </Grid>
                    )
                },
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step2}
                            </Grid>
                            {activeStep >= 1 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.deliveryDate ? 'black' : 'grey' }}>
                                            <b>{data.deliveryDate}</b>
                                        </small>
                                    </Grid>
                                    {data.deliveryCollectionDate && (
                                        <Grid item xs={12}>
                                            <small style={{ fontSize: 10, fontWeight: 700, color: data.deliveryDate ? 'black' : 'grey' }}>
                                                <b>{data.deliveryCollectionDate} </b>
                                            </small>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    )
                },
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step3}
                            </Grid>
                            {activeStep >= 2 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.onsiteDate ? 'black' : 'grey' }}>
                                            <b>{data.deliveredBy}</b>
                                        </small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.onsiteDate ? 'black' : 'grey' }}>
                                            <b>{data.onsiteDate}</b>
                                        </small>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    ),
                    isClickable: data.onsiteDate === 'Onsite: Not set' && activeStep > 2 ? true : false
                },
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step4}
                            </Grid>
                            {activeStep >= 3 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.onhireServiceDue ? 'black' : 'grey' }}>
                                            <b>{data.onhireServiceDue}</b>
                                        </small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.onhireServiceCompleted ? 'black' : 'grey' }}>
                                            <b>{data.onhireServiceCompleted}</b>
                                        </small>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )
                },
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step5}
                            </Grid>
                            {activeStep >= 4 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.onhireServiceCompleted ? 'black' : 'grey' }}>
                                            <b>{data.onhireDate}</b>
                                        </small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.onhireServiceCompleted ? 'black' : 'grey' }}>
                                            <b>{data.winterEndDate}</b>
                                        </small>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )
                },
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step6}
                            </Grid>
                            {activeStep >= 4 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                                            <b>{data.offhireSetDate}</b>
                                        </small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                                            <b> {data.offhireDate}</b>
                                        </small>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )
                },

                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step7}
                            </Grid>
                            {activeStep >= 6 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.offhireServiceDue ? 'black' : 'grey' }}>
                                            <b>{data.offhireServiceDue}</b>
                                        </small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.offhireServiceCompleted ? 'black' : 'grey' }}>
                                            <b>{data.offhireServiceCompleted} </b>
                                        </small>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )
                },
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step8}
                            </Grid>
                            {activeStep >= 7 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.collectionDate ? 'black' : 'grey' }}>
                                            <b>{data.collectionDate}</b>
                                        </small>
                                    </Grid>
                                    {data.collectionStatus && (
                                        <Grid item xs={12}>
                                            <small style={{ fontSize: 10, fontWeight: 700, color: data.collectionStatus ? 'black' : 'grey' }}>
                                                <b>{data.collectionStatus} </b>
                                            </small>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    )
                },
                {
                    label: (
                        <Grid container>
                            <Grid item xs={12}>
                                {data.step9}
                            </Grid>
                            {activeStep >= 8 && (
                                <>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.factoryInspectionDue ? 'black' : 'grey' }}>
                                            Due: <b>{data.factoryInspectionDue ? data.factoryInspectionDue : 'Not Completed'}</b>
                                        </small>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <small style={{ fontSize: 10, fontWeight: 700, color: data.factoryInspectionCompleted ? 'black' : 'grey' }}>
                                            Completed: <b>{data.factoryInspectionCompleted ? data.factoryInspectionCompleted : 'Not Completed'} </b>
                                        </small>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )
                }
                // {
                //     label: (
                //         <Grid container>
                //             <Grid item xs={12}>
                //                 {data.step10}
                //             </Grid>
                //             {activeStep >= 8 && (
                //                 <>
                //                     <Grid item xs={12}>
                //                         <small style={{ fontSize: 10, fontWeight: 700, color: data.pausedDate ? 'black' : 'grey' }}>
                //                             Paused: <b>{data.pausedDate ? data.pausedDate : 'Not Completed'}</b>
                //                         </small>
                //                     </Grid>
                //                 </>
                //             )}
                //         </Grid>
                //     )
                // }
            ];

            setState((st) => ({ ...st, steps: Steps }));
        } catch (error) {
            console.error(error);
        }
    }, [state.resData]);

    const handleSteps = (id = false) => {
        if (id) {
            setOpenDialog({ setOnsiteDateModal: true });
        } else {
            try {
                setState((st) => {
                    let newSt = { ...st };
                    let scheduleID = null;
                    if (st.contractStatusID == 1) newSt.scheduleID = st.summerServiceScheduleID;
                    if (st.contractStatusID == 5) newSt.scheduleID = st.onhireScheduleID;
                    if (st.contractStatusID == 7) newSt.scheduleID = st.offhireScheduleID;
                    // if (st.contractStatusID == 10) newSt.scheduleID = st.factoryInspectionScheduleID;
                    return newSt;
                });
            } catch (error) {
                console.warn(error);
            }
            try {
                let statusNextModal = '';

                if (state.contractStatusID == 1) statusNextModal = !state.summerServiceScheduleID ? '' : 'complete';
                if (state.contractStatusID == 2) statusNextModal = 'setDeliveryDateModal';
                if (state.contractStatusID == 3) statusNextModal = 'setCollectedModal';
                if (state.contractStatusID == 4) statusNextModal = 'setOnsiteDateModal';
                if (state.contractStatusID == 5) statusNextModal = 'complete';
                if (state.contractStatusID == 6) statusNextModal = 'setOffHireModal';
                if (state.contractStatusID == 7) statusNextModal = 'OffHirecomplete';
                if (state.contractStatusID == 8) statusNextModal = 'setVehiclePickedModal';
                if (state.contractStatusID == 9) statusNextModal = 'setAwaitingFactoryInspection';
                if (state.contractStatusID == 10) statusNextModal = 'setPauseCompleted';
                if (state.contractStatusID == 11) statusNextModal = 'setResumeContract';
                // if (state.contractStatusID == 10) statusNextModal = 'complete';

                setOpenDialog({ [statusNextModal]: true });
            } catch (error) {
                console.warn(error);
            }
        }
    };

    const closeSteps = () => {
        try {
            setState((st) => ({ ...st, modalIndex: -1, modalLabel: '' }));
            setModalStep(-1);
        } catch (error) {
            console.warn(error);
        }
    };

    const closeTheDialog = (res) => {
        setOpenDialog({});

        setState((st) => {
            const newSt = { ...st };
            newSt.isUpdate = new Date();
            return newSt;
        });

        // res && props.pullDetails();
        res && props.handleStepperContract(new Date());
    };

    return (
        <div className={classes.root}>
            {/* {state.resData.length > 0 && ( */}
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {state.steps?.map((label, i) => {
                    return (
                        <Step key={label} onClick={i === activeStep || label.isClickable ? () => handleSteps(label.isClickable) : () => {}}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {/* )} */}

            {openDialog.OffHirecomplete ? (
                <DialogComp title={`Update Vehicle Location`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractOffHireOverRide
                        scheduleID={state.scheduleID}
                        onClose={closeTheDialog}
                        vehicleID={props.vehicleID}
                        checkedVehicleList={state.checkedVehicleList}
                    />
                </DialogComp>
            ) : null}

            {openDialog.complete ? (
                <DialogComp title={`Complete Schedule`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <CompleteScheduleModalForContract scheduleID={state.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.setDeliveryDateModal ? (
                <DialogComp title={`Arrange Delivery`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetDeliveryDateModal onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                </DialogComp>
            ) : null}
            {openDialog.setCollectedModal ? (
                <DialogComp title={`Collected`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetCollectedModal
                        onClose={closeTheDialog}
                        checkedVehicleList={state.checkedVehicleList}
                        contractDeliveryTransportCompanyID={state.contractDeliveryTransportCompanyID}
                    />
                </DialogComp>
            ) : null}
            {openDialog.setOnsiteDateModal ? (
                <DialogComp title={`Set Vehicle Onsite`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetOnsiteDateModal onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                </DialogComp>
            ) : null}
            {openDialog.setOffHireModal ? (
                <DialogComp title={`Set Winter Off Hire`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetOffHireModal
                        onClose={closeTheDialog}
                        checkedVehicleList={state.checkedVehicleList}
                        authorityReference={state.resData.authorityReference}
                        winterEndDate={state.resData.contractWinterEndDate}
                    />
                </DialogComp>
            ) : null}
            {openDialog.setVehiclePickedModal ? (
                <DialogComp title={`Arranged Collection`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetVehiclePickedModal
                        onClose={closeTheDialog}
                        contractSummerServiceAreaID={state.contractSummerServiceAreaID}
                        checkedVehicleList={state.checkedVehicleList}
                    />
                </DialogComp>
            ) : null}
            {openDialog.setAwaitingFactoryInspection ? (
                <DialogComp title={`Set Vehicle Arrived Date`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetAwaitingFactoryInspection
                        onClose={closeTheDialog}
                        checkedVehicleList={state.checkedVehicleList}
                        contractCollectionTransportCompanyID={state.contractCollectionTransportCompanyID}
                    />
                </DialogComp>
            ) : null}
            {openDialog.setPauseCompleted ? (
                <DialogComp title={`Contract Pause / Complete`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetPauseCompleted
                        onClose={closeTheDialog}
                        checkedVehicleList={state.checkedVehicleList}
                        vehicleID={props.vehicleID}
                        fromStepper={true}
                    />
                </DialogComp>
            ) : null}
            {openDialog.setResumeContract ? (
                <DialogComp title={`Resume Contract`} maxWidth="sm" fullWidth onClose={() => closeTheDialog(false)}>
                    <ContractSetResume onClose={closeTheDialog} checkedVehicleList={state.checkedVehicleList} />
                </DialogComp>
            ) : null}

            {/* {getStepContent(modalStep)} */}
        </div>
    );
}
