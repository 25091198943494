import {
    Button,
    ButtonGroup,
    CircularProgress,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    withStyles
} from '@material-ui/core';
import { DatePicker, formatters, MandatoryField, PrimaryButton, SecondaryButton, SelectBox, SimpleField } from '../../../../../../Core/Controls';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useToast } from '../../../../../../Core/Hooks/useToast';
import { GetAllPartsByPartNumber } from '../../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { Alert } from '@material-ui/lab';
import { Backup } from '@material-ui/icons';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import ConfirmationModal from '../../../../../../Core/Modal/ConfirmationModal';
import { useWindowSize } from '../../../../../../Core/Hooks/useWindowSize';
import CloseIcon from '@material-ui/icons/Close';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'grey',
        color: theme.palette.common.white,
        padding: '10px 5px'
    },
    body: {
        fontSize: 15,
        padding: '2px 5px',
        color: theme.palette.common.black
    }
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover
        },
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px'
    }
});

export const JobItemPurchaseParts = ({
    state,
    handleInputChange,
    handleFilter,
    handleImage,
    clearImage,
    addPartToLocally,
    patchSelectedRow,
    pullPurchasedPart,
    selectedPurchaedPart
}) => {
    const classes = useStyles();
    const [partState, setPartState] = useState({ matchingPartNumber: [], errors: {} });
    const { showToastSuccess, showToastError } = useToast();
    const [ScreenWidth, ScreenHeight] = useWindowSize();

    useEffect(() => {
        if (selectedPurchaedPart.isLocallyUpdated || selectedPurchaedPart?.vehicleJobPartID) {
            pullPurchasedPart(selectedPurchaedPart.vehicleJobPartID, selectedPurchaedPart);
        }
    }, []);

    const validate = () => {
        let {
            partNumber,
            partDescription,
            vehicleJobPartPaymentType,
            partQuantity,
            vehicleJobPartWIPNumber,
            partUnitPrice,
            retailPrice,
            vehicleJobPartDatePurchased,
            vehicleJobPartSupplier
        } = state;
        let noErrors = true;
        let err = {};
        // if (!partNumber) {
        //     err.partNumber = 'This is required';
        //     noErrors = false;
        // }
        if (!partDescription) {
            err.partDescription = 'This is required';
            noErrors = false;
        }
        // if (!vehicleJobPartPaymentType) {
        //     err.vehicleJobPartPaymentType = 'This is required';
        //     noErrors = false;
        // }
        if (!partQuantity) {
            err.partQuantity = 'This is required';
            noErrors = false;
        }
        // if (!vehicleJobPartWIPNumber) {
        //     err.vehicleJobPartWIPNumber = 'This is required';
        //     noErrors = false;
        // }
        // if (!partUnitPrice) {
        //     err.partUnitPrice = 'This is required';
        //     noErrors = false;
        // }
        // if (!retailPrice) {
        //     err.retailPrice = 'This is required';
        //     noErrors = false;
        // }
        // if (!vehicleJobPartDatePurchased) {
        //     err.vehicleJobPartDatePurchased = 'This is required';
        //     noErrors = false;
        // }
        // if (!vehicleJobPartSupplier) {
        //     err.vehicleJobPartSupplier = 'This is required';
        //     noErrors = false;
        // }

        setPartState((st) => ({ ...st, errors: err }));
        return noErrors;
    };

    const confirmAndSubmit = () => {
        if (validate()) {
            if (!state.vehicleJobPartImage) {
                setPartState((st) => ({ ...st, showConfirmationModal: true }));
            } else {
                addPartToLocally(selectedPurchaedPart?.vehicleJobPartID, selectedPurchaedPart.id);
            }
        }
    };

    const handleImageModal = () => {
        setPartState((st) => ({ ...st, showImageModal: true }));
    };

    const pullDataByPartNumber = useCallback(
        debounce(async (e, type) => {
            const isPartSearch = type === 'P';
            const searchValue = isPartSearch ? state.partNumber : state.partDescription;
            const emptyMessage = isPartSearch ? 'Please enter part number to fetch data' : 'Please enter description to fetch data';

            if (!searchValue) {
                showToastError(emptyMessage);
                return;
            }
            setPartState((st) => ({ ...st, loading: true }));
            try {
                const res = await GetAllPartsByPartNumber(state.partNumber, state.partDescription);
                if (res.success) {
                    // let selectedParts = new Set(props.stdParts.map((q) => q.standardLabourPartPartID));
                    // const filteredArray1 = res.data.partDetails.filter((item) => !selectedParts.has(item.partID));
                    setPartState((st) => ({
                        ...st,
                        matchingPartNumber: res.data.partDetails,
                        loading: false
                        // openModal: true
                    }));
                } else {
                    showToastError('Failed to fetch data');
                    setPartState((st) => ({ ...st, loading: false }));
                }
            } catch (error) {
                showToastError('Error fetching data');
                setPartState((st) => ({ ...st, loading: false }));
            }
        }, 500),
        [state.partNumber, state.partDescription]
    );

    const closeConfirmationModal = () => {
        setPartState((st) => ({ ...st, showConfirmationModal: false, showImageModal: false }));
    };

    return (
        <>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <SimpleField
                                autoFocus
                                isDisabled={state.readOnly}
                                inputLabel="Part Number"
                                name="partNumber"
                                placeholder="Part Number"
                                onChange={handleInputChange}
                                value={state.partNumber}
                                errors={partState.errors.partNumber}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={'Fetch Part Details By Number'} arrow>
                                                <Grid>
                                                    <IconButton
                                                        style={{ padding: '0px' }}
                                                        onClick={(e) => pullDataByPartNumber(e, 'P')}
                                                        size="small"
                                                        state={state}
                                                    >
                                                        {state.loading ? <CircularProgress style={{ height: 20, width: 20 }} /> : <SearchIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={8} className="mandatory-fields">
                            <MandatoryField
                                isDisabled={state.readOnly}
                                inputLabel="Part Description"
                                name="partDescription"
                                placeholder="Part Description"
                                onChange={handleInputChange}
                                value={state.partDescription}
                                errors={partState.errors.partDescription}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title={'Fetch Part Details By Description'} arrow>
                                                <Grid>
                                                    <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'D')} size="small">
                                                        {state.loading ? <CircularProgress style={{ height: 20, width: 20 }} /> : <SearchIcon />}
                                                    </IconButton>
                                                </Grid>
                                            </Tooltip>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={2} md={4} lg={4}>
                            <MandatoryField
                                isDisabled={state.readOnly}
                                inputLabel="Quantity"
                                name="partQuantity"
                                type="number"
                                placeholder="Qty"
                                onChange={handleInputChange}
                                value={state.partQuantity}
                                autoFocus={state.focus}
                                errors={partState.errors.partQuantity}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Retail Price"
                                name="retailPrice"
                                type="number"
                                placeholder="Price"
                                onChange={handleInputChange}
                                value={state.retailPrice}
                                errors={partState.errors.retailPrice}
                            />
                        </Grid>

                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Cost Price"
                                name="partUnitPrice"
                                type="number"
                                placeholder="Price"
                                onChange={handleInputChange}
                                value={state.partUnitPrice}
                                errors={partState.errors.partUnitPrice}
                            />
                        </Grid>

                        <Grid item className="date_range" xs={6} sm={4} md={4} lg={4}>
                            <InputLabel shrink>Date</InputLabel>
                            <DatePicker
                                placeholder="Date"
                                value={state.vehicleJobPartDatePurchased}
                                onChange={handleInputChange}
                                name="vehicleJobPartDatePurchased"
                            />
                            <FormHelperText error>{partState.errors.vehicleJobPartDatePurchased}</FormHelperText>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4} lg={4}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Supplier Invoice Number"
                                name="vehicleJobPartWIPNumber"
                                placeholder="Invoice Number"
                                onChange={handleInputChange}
                                value={state.vehicleJobPartWIPNumber}
                                errors={partState.errors.vehicleJobPartWIPNumber}
                            />
                        </Grid>
                        <Grid item xs={8} sm={6} md={4} lg={4} component="label" alignItems="center">
                            <InputLabel shrink>Payment Method</InputLabel>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button
                                    style={{ fontSize: 12, height: '40px' }}
                                    variant={state.vehicleJobPartPaymentType === 'A' ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter('A')}
                                >
                                    Account
                                </Button>
                                <Button
                                    style={{ fontSize: 12 }}
                                    variant={state.vehicleJobPartPaymentType === 'C' ? 'contained' : 'outlined'}
                                    onClick={() => handleFilter('C')}
                                >
                                    Credit Card
                                </Button>
                            </ButtonGroup>
                            <FormHelperText error>{partState.errors.vehicleJobPartPaymentType}</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleField
                                isDisabled={state.readOnly}
                                inputLabel="Purchased From"
                                name="vehicleJobPartSupplier"
                                placeholder="Purchased From"
                                onChange={handleInputChange}
                                value={state.vehicleJobPartSupplier}
                                errors={partState.errors.vehicleJobPartSupplier}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {ScreenWidth > 500 ? (
                    <Grid item xs={12} sm={4} lg={4}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                {/* <InputLabel shrink error required>
                                Receipt (Primary Battery)
                            </InputLabel> */}

                                <Grid item xs={12} className="gridcontainer" style={{ height: '100%', position: 'relative', border: '1px dashed #5e87f8' }}>
                                    {state?.vehicleJobPartImage && (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0
                                            }}
                                        >
                                            <Tooltip title="Remove Receipt">
                                                <CloseIcon fontSize="small" onClick={clearImage} />
                                            </Tooltip>
                                        </span>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        style={{
                                            height: '350px'
                                        }}
                                    >
                                        {state?.vehicleJobPartImage ? (
                                            <img
                                                src={state?.vehicleJobPartImage}
                                                alt={'Receipt'}
                                                style={{
                                                    objectFit: 'contain',
                                                    objectPosition: '50% 50%',
                                                    width: '100%',
                                                    height: '100%'
                                                    // border: 'solid 1px #CCC'
                                                }}
                                            />
                                        ) : (
                                            <div
                                                style={{
                                                    height: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <div>
                                                    <Tooltip title="Upload Document">
                                                        <label>
                                                            <IconButton color="secondary" component="span" style={{ padding: '0px' }}>
                                                                <Backup style={{ fontSize: '60px' }} />
                                                                <input
                                                                    type="file"
                                                                    onChange={handleImage}
                                                                    // className="upload-btn-input-file"
                                                                    style={{ display: 'none' }}
                                                                    accept="image/*"
                                                                />
                                                            </IconButton>
                                                        </label>
                                                    </Tooltip>
                                                </div>
                                                <p>No Image Found</p>
                                            </div>
                                        )}
                                    </Grid>
                                    <span>{state?.primaryErrors?.vehicleJobPartImage}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                {/* <Grid item>
                    <div className="input-style">
                        <FormHelperText shrink className="input_label" style={{ textAlign: 'right', marginRight: '15px' }}>
                            Total
                        </FormHelperText>
                        <div className="input_value" style={{ textAlign: 'right' }}>
                            {formatters.CurrencyNumberWithPound(state.jobItemCustomerPartsPrice) || 0}
                        </div>
                    </div>
                </Grid> */}
                <Grid item xs={ScreenWidth > 500 ? 12 : 6} className="submit_btn">
                    <SecondaryButton disabled={state.readOnly} onClick={confirmAndSubmit}>
                        {selectedPurchaedPart.isLocallyUpdated || selectedPurchaedPart?.vehicleJobPartID ? 'Update' : 'Add'}
                    </SecondaryButton>
                </Grid>
                {ScreenWidth < 500 && (
                    <Grid item xs={6}>
                        <PrimaryButton disabled={state.readOnly} onClick={handleImageModal}>
                            receipt
                        </PrimaryButton>
                    </Grid>
                )}
                {partState?.matchingPartNumber.length > 0 && (
                    <Grid item xs={12}>
                        <TableContainer className="custom-scroll" style={{ maxHeight: '420px', borderRadius: '0px' }}>
                            <Table className={classes.table} style={{ minWidth: 550 }} aria-label="customized table" stickyHeader>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell> </StyledTableCell>
                                        <StyledTableCell>Part No.</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell width="20%">Cost Price</StyledTableCell>
                                        <StyledTableCell width="20%">Selling Price</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {partState?.matchingPartNumber.length > 0 ? (
                                        partState?.matchingPartNumber.map((row, i) => {
                                            return (
                                                <StyledTableRow key={row.partID}>
                                                    <StyledTableCell>
                                                        <MergeTypeIcon onClick={() => patchSelectedRow(row)} />
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.pn}</StyledTableCell>
                                                    <StyledTableCell>{row.pd}</StyledTableCell>
                                                    <StyledTableCell>{row.cp}</StyledTableCell>
                                                    <StyledTableCell>{row.sp}</StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })
                                    ) : (
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={5} height={'100%'}>
                                                <Alert severity="info">No Record Found</Alert>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                )}
            </Grid>
            {partState.showConfirmationModal ? (
                <DialogComp title="Confirmation" onClose={() => closeConfirmationModal(false)} maxWidth="sm">
                    <ConfirmationModal
                        message={`Are you sure to save part details without uploading receipt?`}
                        handleSubmit={() => addPartToLocally(selectedPurchaedPart?.vehicleJobPartID, selectedPurchaedPart.id)}
                    />
                </DialogComp>
            ) : null}
            {partState.showImageModal ? (
                <DialogComp title="Receipt" onClose={() => closeConfirmationModal(false)} maxWidth="sm" fullWidth>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {/* <InputLabel shrink error required>
                                Receipt (Primary Battery)
                            </InputLabel> */}

                            <Grid item xs={12} className="gridcontainer" style={{ height: '95%', position: 'relative', border: '1px dashed #5e87f8' }}>
                                {state?.vehicleJobPartImage && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: 0
                                        }}
                                    >
                                        <Tooltip title="Remove Receipt">
                                            <CloseIcon fontSize="small" onClick={clearImage} />
                                        </Tooltip>
                                    </span>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    style={{
                                        height: '350px'
                                    }}
                                >
                                    {state?.vehicleJobPartImage ? (
                                        <img
                                            src={state?.vehicleJobPartImage}
                                            alt={'Receipt'}
                                            style={{
                                                objectFit: 'contain',
                                                objectPosition: '50% 50%',
                                                width: '100%',
                                                height: '100%'
                                                // border: 'solid 1px #CCC'
                                            }}
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <div>
                                                <Tooltip title="Upload Document">
                                                    <label>
                                                        <IconButton color="secondary" component="span" style={{ padding: '0px' }}>
                                                            <Backup style={{ fontSize: '60px' }} />
                                                            <input
                                                                type="file"
                                                                onChange={handleImage}
                                                                // className="upload-btn-input-file"
                                                                style={{ display: 'none' }}
                                                                accept="image/*"
                                                            />
                                                        </IconButton>
                                                    </label>
                                                </Tooltip>
                                            </div>
                                            <p>No Image Found</p>
                                        </div>
                                    )}
                                </Grid>
                                <span>{state?.primaryErrors?.vehicleJobPartImage}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </>
    );
};
