import React, { useEffect, useContext, useState, lazy, Suspense, useCallback } from 'react';

import moment from 'moment';
import { getInternalDashboard } from '../../../Core/Services/Dashboard';
import { Grid } from '@material-ui/core';

const LazyComponent = lazy(() => import('../../Technician/dashboardUserInterface'));

const MainDashboard = (props) => (
    <Suspense fallback={<div>please Wait...</div>}>
        <LazyComponent {...props} />
    </Suspense>
);

const NewIntenalDashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [ServiceArea, setServiceArea] = useState([]);
    const [ServiceAreaList, setServiceAreaList] = useState({
        eventsList: [],
        jobList: [],
        ReviewList: []
    });
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        hireActive: 0,
        contractActive: 0,
        outstandingDocuments: 0,
        eventsToReview: 0,
        scheduleOverdue: 0,
        scheduleThisWeek: 0,
        scheduleNextWeek: 0,
        scheduleFuture: 0,
        jobsTotal: 0,
        onHire: 0,
        offHire: 0,
        jobsToReview: 0
    });

    const pullDashboardData = useCallback(async () => {
        try {
            setLoading(true); // Start loading
            const res = await getInternalDashboard();

            if (res.success) {
                setDashboardData(res.data.totalCounts[0]);
                setServiceArea(res.data.serviceAreas);
            } else {
                console.warn('Unexpected response:', res);
            }
        } catch (error) {
            console.error('API Error:', error.response.data);
        } finally {
            setLoading(false); // Stop loading once data is fetched
        }
    }, []);

    useEffect(() => {
        let overallTatal = [...ServiceArea];
        let overJob = overallTatal.map((item) => ({
            label: item.name,
            value: item.jobsTotal,
            path: '/serviceagent/OJ',
            search: `?serviceAgentName=${item.name}`,
            valueColor: 'red'
        }));
        let jobsToReview = overallTatal.map((item) => ({
            label: item.name,
            value: item.jobsToReview,
            path: '/serviceagent/CA',
            search: `?serviceAgentName=${item.name}`
        }));
        let fleetHired = overallTatal.map((item) => ({
            label: item.name,
            value: item.hireActive,
            path: '/Contracts_Hire_Fleet',
            search: `?agentName=${item.name}`
        }));
        let ConrteactHired = overallTatal.map((item) => ({
            label: item.name,
            value: item.contractActive,
            path: '/Contracts_Maintenance',
            search: `?agentName=${item.name}`
        }));
        setServiceAreaList((st) => ({ ...st, overJob, jobsToReview, fleetHired, ConrteactHired }));

        const totals = overallTatal.reduce(
            (acc, curr) => {
                acc.hireActive += curr.hireActive;
                acc.contractActive += curr.contractActive;
                acc.outstandingDocuments += curr.outstandingDocuments;
                acc.eventsToReview += curr.eventsToReview;
                acc.scheduleOverdue += curr.scheduleOverdue;
                acc.scheduleThisWeek += curr.scheduleThisWeek;
                acc.scheduleNextWeek += curr.scheduleNextWeek;
                acc.scheduleFuture += curr.scheduleFuture;
                acc.jobsTotal += curr.jobsTotal;
                acc.jobsToReview += curr.jobsToReview;
                acc.onHire += curr.onHire;
                acc.offHire += curr.offHire;
                acc.docNotSent += curr.docNotSent;
                acc.factoryInspectionCount += curr.factoryInspectionCount;
                acc.summerServiceCount += curr.summerServiceCount;
                acc.tachoCount += curr.tachoCount;
                acc.conformityCount += curr.conformityCount;
                acc.motCount += curr.motCount;
                acc.jobsUnallocated += curr.jobsUnallocated;
                acc.jobsNotStarted += curr.jobsNotStarted;
                acc.jobsAwaitingParts += curr.jobsAwaitingParts;
                acc.jobsReviewed += curr.jobsReviewed;
                acc.proformaCount += curr.proformaCount;

                return acc;
            },
            {
                hireActive: 0,
                contractActive: 0,
                outstandingDocuments: 0,
                eventsToReview: 0,
                scheduleOverdue: 0,
                scheduleThisWeek: 0,
                scheduleNextWeek: 0,
                scheduleFuture: 0,
                jobsTotal: 0,
                onHire: 0,
                offHire: 0,
                jobsToReview: 0,
                docNotSent: 0,
                factoryInspectionCount: 0,
                summerServiceCount: 0,
                tachoCount: 0,
                conformityCount: 0,
                motCount: 0,
                jobsUnallocated: 0,
                jobsNotStarted: 0,
                jobsAwaitingParts: 0,
                jobsReviewed: 0,
                proformaCount: 0
            }
        );

        setState((st) => ({
            ...st,
            ...totals
        }));
    }, [ServiceArea]);

    // useEffect(() => {}, [JSON.stringify(ServiceArea)]);

    useEffect(() => {
        pullDashboardData();
    }, [pullDashboardData]);

    const singleGrpupCardData = [
        {
            title: 'Overview',
            objList: [
                {
                    name: 'Current Jobs',
                    value: state.jobsTotal,
                    path: '/serviceagent/OJ'
                },
                {
                    name: 'Events Due',
                    value: state.scheduleThisWeek || 0,
                    path: 'schedule/confirmedbookings',
                    search: `?fromDash=true&start=${moment().startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment().endOf('isoWeek').format('YYYY-MM-DD')}`
                },
                {
                    name: 'Overdue events',
                    value: state.scheduleOverdue || 0,
                    path: 'schedule/confirmedbookings',
                    search: `?fromDash=true&end=${moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}`,
                    valueColor: 'red'
                },
                {
                    name: 'Breakdowns',
                    value: dashboardData.breakdowns,
                    path: '/maintenance/breakdowns/:all'
                },
                {
                    name: 'VOR',
                    value: dashboardData.vor,
                    path: '/controller/vor'
                }
            ]
        }
    ];

    let MyReportList = [
        {
            value: 'VOR',
            name: ' Reporting',
            path: '/controller/VorReporting/'
        },
        {
            value: 'Breakdown',
            name: 'Reporting ',
            path: '/maintenance/breakdown/reporting/'
        },
        {
            value: 'Job',
            name: ' Reporting ',
            path: '/job/job_Reporting'
        },
        {
            value: 'My Reports',
            name: ' Reporting ',
            path: '/reports/reportlist/'
        }
    ];

    const ListCard = [
        {
            Maintitle: `Hire Fleet `,

            overAllItems: [
                { title: 'Hired', value: dashboardData.hireActive, path: '/Contracts_Hire_Fleet' },
                { title: 'Free stock', value: dashboardData.freeStock, path: '/Contracts_Hire_Fleet', search: '?isFreeStock=true' }
            ],

            items: [
                {
                    label: 'Awaiting summer service',
                    value: dashboardData.awaitingSummerServiceCount,
                    path: `/ContractStatus/${1}`
                    // search: `?status=Awaiting summer service&isSetAll=true`
                    // params: { scheduleEventName: 'On Hire' }
                },
                {
                    label: 'Summer service completed',
                    value: dashboardData.summerServiceCompletedCount,
                    path: `/ContractStatus/${2}`
                    // search: `?status=Summer service completed&isSetAll=true`
                    // params: { scheduleEventName: 'Off Hire' }
                },
                {
                    label: 'Delivery Arranged',
                    value: dashboardData.awaitingDeliveryCount,
                    path: `/ContractStatus/${3}`
                    // search: `?status=Awaiting Delivery`
                },
                {
                    label: 'In transit',
                    value: dashboardData.collectedCount,
                    path: `/ContractStatus/${4}`
                    // search: `?status=In transit`
                },
                {
                    label: 'Vehicle on site (awaiting On hire)',
                    value: dashboardData.vehicleOnsiteCount,
                    path: `/ContractStatus/${5}`
                    // path: '/Contracts_Hire_Fleet'
                    // search: `?status=Vehicle on site`
                    // params: { scheduleEventName: 'Tachograph' }
                },
                // {
                //     label: 'Free stock',
                //     value: dashboardData.freeStock,
                //     path: '/schedule/confirmedbookings',
                //     search: `?fromDash=true&start=&end=${moment().add(4, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=Conformity Check`
                //     // params: { scheduleEventName: 'Conformity' }
                // },
                {
                    label: 'On Hire',
                    value: dashboardData.onHireCount,
                    // path: '/Contracts_Hire_Fleet',
                    // search: `?status=On Hire`
                    path: `/ContractStatus/${6}`
                    // params: { scheduleEventName: 'MOT' }
                },
                {
                    label: 'Off hired (awaiting offhire)',
                    value: dashboardData.offHireCount,
                    // path: '/Contracts_Hire_Fleet',
                    // search: `?status=Off hire`
                    path: `/ContractStatus/${7}`
                    // params: { scheduleEventName: 'MOT' }
                },
                {
                    label: 'Ready to collect',
                    value: dashboardData.readyforcollectionCount,
                    path: `/ContractStatus/${8}`
                    // path: '/Contracts_Hire_Fleet',
                    // search: `?status=Ready for collection`
                    // params: { scheduleEventName: 'MOT' }
                },
                {
                    label: 'Collection arranged',
                    value: dashboardData.collectionArrangedCount,
                    path: `/ContractStatus/${9}`
                    // path: '/Contracts_Hire_Fleet',
                    // search: `?status=Collection arranged`
                    // params: { scheduleEventName: 'MOT' }
                },
                {
                    label: 'Awaiting factory inspection',
                    value: dashboardData.awaitingFactoryInspectionCount,
                    path: `/ContractStatus/${10}`
                    // path: '/Contracts_Hire_Fleet',
                    // search: `?status=Awaiting factory inspection`
                    // params: { scheduleEventName: 'MOT' }
                }
            ]
        }
    ];

    let JobData = [
        {
            Maintitle: `Jobs `,
            overAllItems: [
                { title: 'All Jobs', value: state.jobsTotal, path: '/serviceagent/OJ' }
                // { title: 'Free stock', value: dashboardData.freeStock }
            ],

            items: ServiceAreaList.overJob,

            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 6
            }
        },
        {
            overAllItems: [
                { title: 'To be reviewed', value: state.jobsToReview, path: '/serviceagent/CA' }
                // { title: 'Free stock', value: dashboardData.freeStock }
            ],

            items: ServiceAreaList.jobsToReview,

            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 6
            }
        }
    ];

    let allocationData = [
        {
            Maintitle: `Allocations`,
            overAllItems: [
                { title: 'Hire fleet total hired', value: state.hireActive, path: '/Contracts_Hire_Fleet' }
                // { title: 'Free stock', value: dashboardData.freeStock }
            ],

            items: ServiceAreaList.fleetHired,

            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 6
            }
        },
        {
            // Maintitle: `Allocations`,
            overAllItems: [
                { title: 'Contract maintenance', value: state.contractActive, path: '/Contracts_Maintenance' }
                // { title: 'Free stock', value: dashboardData.freeStock }
            ],

            items: ServiceAreaList.ConrteactHired,

            cardSize: {
                lg: 3,
                md: 12,
                sm: 12,
                xs: 12
            },
            itemSize: {
                lg: 6,
                md: 3,
                sm: 3,
                xs: 6
            }
        }
    ];

    useEffect(() => {
        const serviceAreaNames = [
            {
                title: `Overdue`,
                value: 'scheduleOverdue',
                valueColor: 'red',
                path: 'schedule/confirmedbookings',
                search: `?fromDash=true&end=${moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}`
                // search:""
            },
            {
                title: `This Week`,
                value: `scheduleThisWeek`,
                path: 'schedule/confirmedbookings',
                search: `?fromDash=true&start=${moment().startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment().endOf('isoWeek').format('YYYY-MM-DD')}`
            },
            {
                title: `Next Week`,
                value: 'scheduleNextWeek',
                path: 'schedule/confirmedbookings',
                search: `?fromDash=true&start=${moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment()
                    .add(1, 'weeks')
                    .endOf('isoWeek')
                    .format('YYYY-MM-DD')}`
            },

            {
                title: `On / Off Hire`,
                value: 'onHire',
                value2: 'offHire',
                path: 'schedule/confirmedbookings',
                search: `?fromDash=true&scheduleEventName=on hire`,
                search2: `?fromDash=true&scheduleEventName=Off hire`,
                isCombine: true
            },
            {
                title: `Factory inspection`,
                value: 'factoryInspectionCount',
                path: '/serviceagent/OJ',
                search: `?fromDash=true&vehicleJobStatus=Factory inspection`
            },
            {
                title: `Summer service`,
                value: 'summerServiceCount',
                path: 'schedule/confirmedbookings',
                search: `?fromDash=true&scheduleEventName=Summer service`
            },
            {
                title: `MOT`,
                value: 'motCount',
                path: '/schedule/confirmedbookings',
                search: `?fromDash=true&start=&end=${moment().add(8, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=MOT`
            },
            {
                title: `Tachograpgh Calibration`,
                value: 'tachoCount',
                path: '/schedule/confirmedbookings',
                search: `?fromDash=true&start=&end=${moment().add(8, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=Tachograph Cali`
            },
            {
                title: `Conformity`,
                value: 'conformityCount',
                path: '/schedule/confirmedbookings',
                search: `?fromDash=true&start=&end=${moment().add(4, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')}&scheduleEventName=Conformity Check`
            }
        ];

        const jobNames = [
            {
                title: `All Jobs`,
                value: 'jobsTotal',
                path: '/serviceagent/OJ',
                search: `?fromDash`,
                params: { serviceAgentName: '' }
            },
            {
                title: `Not started`,
                value: `jobsNotStarted`,
                path: '/serviceagent/OJ',
                search: `?fromDash&vehicleJobStatus=Not started`,
                params: { serviceAgentName: '' }
            },
            {
                title: `Unallocated`,
                value: 'jobsUnallocated',
                path: '/serviceagent/OJ',
                search: `?fromDash&vehicleJobStatus=Unallocated`,
                params: { serviceAgentName: '' }
            },
            {
                title: `awaiting parts`,
                value: 'jobsAwaitingParts',
                path: '/serviceagent/OJ',
                search: `?fromDash&vehicleJobStatus=awaiting parts`,
                params: { serviceAgentName: '' }
            }
        ];

        const ReviewNames = [
            {
                title: `Jobs Awaiting review`,
                value: 'jobsToReview',
                path: '/serviceagent/FR',
                search: `?fromDash=true`
            },
            {
                title: `Events awaiting review`,
                value: `eventsToReview`,
                path: '/Inspection_Awaiting_Sign_Off',
                search: `?fromDash=true&start=${moment().startOf('isoWeek').format('YYYY-MM-DD')}&end=${moment().endOf('isoWeek').format('YYYY-MM-DD')}`
            },
            {
                title: `Outstanding Documents`,
                value: 'outstandingDocuments',
                valueColor: 'red',
                path: '/schedule/outstandingdocuments',
                search: `?fromDash=true`
            },
            {
                title: `Jobs Reviewed`,
                value: 'jobsReviewed',
                path: '/serviceagent/CA',
                search: `?fromDash=true`
            },
            {
                title: `Events not Sent`,
                value: 'docNotSent',
                valueColor: 'red',
                path: '/EventsSignedOff',
                search: `?fromDash=true`
            },
            {
                title: `Jobs sent for approval`,
                value: 'proformaCount',
                // valueColor: 'red',
                path: '/controller/Jobs',
                search: `?fromDash=true&vehicleJobStatus=Sent for Approval`
            }
        ];

        let eventsList = [];
        let jobList = [];
        let ReviewList = [];
        if (serviceAreaNames.length > 0) {
            serviceAreaNames.forEach((k) => {
                eventsList.push({
                    Title: k.title,
                    path: k.path,
                    search: k.search,
                    search2: k.search2,
                    total: state[k.value],
                    total2: state[k.value2] || null,
                    valueColor: k.valueColor,
                    isCombine: k.isCombine || false,
                    list: ServiceArea.map((m) => ({
                        value: m[k.value],
                        value2: m[k.value2] || null,
                        name: m.name,
                        path: k.path,
                        search: `${k.search}&agentName=${m.name} ` || null,
                        search2: `${k.search2}&agentName=${m.name}`,
                        valueColor: k.valueColor
                    }))
                });
            });
        }
        if (jobNames.length > 0) {
            jobNames.forEach((k) => {
                jobList.push({
                    Title: k.title,
                    path: k.path,
                    search: k.search,
                    total: state[k.value],
                    valueColor: k.valueColor,
                    list: ServiceArea.map((m) => ({
                        value: m[k.value],
                        name: m.name,
                        path: k.path,

                        search: `${k.search}&agentName=${m.name}`,
                        valueColor: k.valueColor,
                        params: k.params
                        // params: { serviceAgentName: m.name }
                    }))
                });
            });
        }
        if (ReviewNames.length > 0) {
            ReviewNames.forEach((k) => {
                ReviewList.push({
                    Title: k.title,
                    path: k.path,
                    search: k.search,
                    total: state[k.value],
                    valueColor: k.valueColor,
                    list: ServiceArea.map((m) => ({
                        value: m[k.value],
                        name: m.name,
                        path: k.path,
                        search: `${k.search}&agentName=${m.name}`,
                        valueColor: k.valueColor
                    }))
                });
            });
        }
        setServiceAreaList((st) => ({ ...st, eventsList, jobList, ReviewList }));
    }, [ServiceArea, state]);

    console.log(ServiceAreaList.eventsList, 'updated l;sit');

    return (
        <div style={{ padding: 10 }}>
            <MainDashboard
                isInternalUser={true}
                serviceAreaNames={ServiceAreaList.eventsList}
                JobListNames={ServiceAreaList.jobList}
                reviewListNames={ServiceAreaList.ReviewList}
                singleGrpupCardData={singleGrpupCardData}
                MyReportList={MyReportList}
                ListCard={ListCard}
                loading={loading}
                pullDashboardData={pullDashboardData}
                tableList={ServiceArea}
                allocationData={allocationData}
                JobData={JobData}
            />
        </div>
    );
};
export default NewIntenalDashboard;
