import React, { useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';
import AddUpdatePart from './PartAddUpdate';
import { useToast } from '../../../Core/Hooks/useToast';
import DialogComp from '../../../Core//Dialog/DialogComp';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { postParts } from '../../../Core/Services/Admin/partService';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GrainIcon from '@material-ui/icons/Grain';
import GetWarehouseParts from './getWarehouseParts';
import MailIcon from '@material-ui/icons/Mail';
import OrderParts from './orderParts';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Parts', active: true }
];

const basecolumns = [
    { key: 'action', name: 'Action', width: '7%' },
    {
        key: 'description',
        name: 'Description',
        width: '20%'
    },
    { key: 'partNumber', name: 'Part Number', width: '7%' },
    {
        key: 'sellingPrice',
        name: 'Retail ',
        width: '7%',
        formatter: formatters.NumberWithPound
    },
    {
        key: 'standardCost',
        name: 'Cost ',
        width: '6%',
        formatter: formatters.NumberWithPound
    },
    {
        key: 'consumable',
        name: 'Consumable',
        width: '6%',
        formatter: formatters.YesNo
    },
    {
        key: 'sqty',
        name: 'Service Area Stock',
        width: '12%'
    },
    {
        key: 'qty',
        name: 'Van Stock',
        width: '6%'
    },
    {
        key: 'minimumStockLevel',
        name: 'Minimum Level',
        width: '12%'
    },
    {
        key: 'targetStockLevel',
        name: 'Target Level',
        width: '12%'
    },
    // {
    //     key: 'totalStockQuantity',
    //     name: 'Total Stock Quantity',
    //     width: '9%'
    // },

    // {
    //     key: 'sellingPrice',
    //     name: 'Selling Price',
    //     width: '7%'
    // },

    // {
    //     key: 'sellingPrice',
    //     name: 'Retail Price',
    //     width: '7%'
    // },
    // {
    //     key: 'unitOfPricePer',
    //     name: 'Unit Of PricePer',
    //     width: '9%'
    // },
    // {
    //     key: 'standardCost',
    //     name: 'Standard Cost',
    //     width: '6%'
    // },

    {
        key: 'isActive',
        name: 'Active?',
        width: '5%'
    }
];
const Parts = () => {
    const [state, setState] = useState({
        open: false,
        partID: null,
        showWarehouseStock: false,
        data: null,
        showOrderPartMailModal: null
    });
    const { showToastSuccess, showToastError } = useToast();

    const pullApiData = async () => {
        setState((state) => ({
            ...state,
            isReload: new Date()
        }));
    };
    console.log(state, 'showWarehouseStock');
    const renderEdit = (ev, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Part"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            open: true,
                            title: 'Edit Part',
                            partID: row.partID,
                            isAdd: false
                        }));
                    }}
                />
                <CustomIconButton
                    icon={GrainIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Warehouse parts"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            showWarehouseStock: true,
                            partID: row.partID
                        }));
                    }}
                />
                {/* <CustomIconButton
                    icon={MailIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Order Part"
                    onClick={() => {
                        setState((st) => ({
                            ...st,
                            showOrderPartMailModal: true,
                            data: row
                        }));
                    }}
                /> */}
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let consumableCol = tempCols.find((e) => e.key === 'consumable');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const saveData = async (outData) => {
        let response = await postParts({
            partID: outData.partID || null,
            partNumber: outData?.partNumber,
            description: outData?.description,
            minimumUnitOfStock: outData?.minimumUnitOfStock,
            totalStockQuantity: outData?.totalStockQuantity,
            sellingPrice: outData?.sellingPrice,
            unitOfPricePer: outData?.unitOfPricePer,
            standardCost: outData?.standardCost,
            isActive: outData?.isActive
        });
        if (response) {
            response.success ? showToastSuccess(response.message) : showToastError(response.message || 'Something went wrong');
        } else {
            showToastError('Something went wrong');
        }
        pullApiData();
    };
    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            title: 'Add Part',
            isAdd: true,
            partID: null
        }));
    };
    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showWarehouseStock: false,
            showOrderPartMailModal: false
        }));
    };

    const urlState = 'AdminParts/Parts_List';
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl={urlState}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                // btnTitle="Add Part"
                // onBtnClick={() => addBtnClicked(null)}
            />
            {state.open ? (
                <DialogComp open={state.open} onClose={handleClose} title={state.title} maxWidth="md">
                    <AddUpdatePart partID={state.partID} onClose={handleClose} saveData={saveData} />
                </DialogComp>
            ) : null}
            {state.showWarehouseStock ? (
                <DialogComp open={state.showWarehouseStock} onClose={handleClose} title={'Available Parts'} maxWidth="lg">
                    <GetWarehouseParts partID={state.partID} onClose={handleClose} />
                </DialogComp>
            ) : null}
            {state.showOrderPartMailModal ? (
                <DialogComp open={state.showOrderPartMailModal} onClose={handleClose} title={'Parts Request'} maxWidth="sm">
                    <OrderParts data={state.data} onClose={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default Parts;
