import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getJobDetailsById = async (Id) => {
    let res = await getWithAuth(`VehicleJob/${Id}`);
    return res;
};
export const getAllJobDropDowns = async (Id = '') => {
    let res = await getWithAuth(`VehicleJob/Dropdowns?VehicleJobID=${Id}`);
    return res;
};
// export const getAllJobDropDowns = async (Id) => {
//     let res = await getWithAuth(`VehicleJob/Dropdowns?VehicleID=${Id}`);
//     return res;
// };
export const postJobDetails = async (data) => {
    let res = await postWithAuth('VehicleJob', data);
    return res;
};
export const getJobHistory = async (id) => {
    let res = await getWithAuth(`VehicleJob/VehicleJobHistory/${id}`);
    return res;
};
export const getUpcomingEvents = async (id) => {
    let res = await getWithAuth(`VehicleJob/VehicleUncompletedSchedules/${id}`);
    return res;
};

export const mergeJobToMaster = async (data) => {
    let res = await postWithAuth(`schedules/SchedulesAddToJob`, data);
    return res;
};
export const setCompletionDate = async (data) => {
    let res = await postWithAuth(`VehicleJob/VehicleJobsCompleteJob`, data);
    return res;
};
export const setBookingDate = async (data) => {
    let res = await postWithAuth(`VehicleJob/ChangeBookingDate`, data);
    return res;
};

export const getJobRequiredDocuments = async (id) => {
    let res = await getWithAuth(`VehicleJob/GetJobRequiredDocuments/${id}`);
    return res;
};
export const getJobRequireScheduledDocuments = async (id) => {
    let res = await getWithAuth(`Schedules/GetScheduleRequiredDocuments/${id}`);
    return res;
};

export const setVehicleArrive = async (data) => {
    let res = await postWithAuth(`VehicleJob/VehicleArrived`, data);
    return res;
};

export const postJobMileage = async (data) => {
    let res = await postWithAuth(`VehicleJob/VehicleJobMileage`, data);
    return res;
};

export const getJobEnggById = async (Id) => {
    let res = await getWithAuth(`VehicleJob/GetEngineersByServiceAreea?ServiceAgentID=${Id}`);
    return res;
};
