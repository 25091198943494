import React, { memo, useContext, useEffect, useState } from 'react';
import singleAxel from '../VehicleDiagrams/images1.png';
// import singleAxel from './VehicleDiagrams/images1.png';
import doubleAxelOne from '../VehicleDiagrams/axImage01.png';
import doubleAxelTwo from '../VehicleDiagrams/azImage02.png';
import doubleAxelThree from '../VehicleDiagrams/axImage03.png';
import Grid from '@material-ui/core/Grid';
import { SelectBox, SimpleField } from './../../../../../../Core/Controls';
import { InputLabel, Typography, FormHelperText, withStyles, TextField } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { InspectionCheCkContaxt } from './InspectionCheckProvide';

const style = {
    axOS: {
        marginTop: '45px'
    },
    axOS2: {
        marginTop: '152px'
    },
    ax2OS: {
        marginTop: '-17px'
    },
    ax2OS2: {
        marginTop: '8px'
    },
    ax2NS: { marginTop: '150px' },
    ax2NSNew: { marginTop: '140px' },
    ax2Break: { marginBottom: '5px' },
    axBreakHeading: { textAlign: 'center' }
};

const Axel = memo((props) => {
    const { classes } = props;

    const { state, handleInspection, handleInputs, getPreviousTyreData } = useContext(InspectionCheCkContaxt);

    const [show, setShow] = useState({
        showAxle1: false,
        showAxle2: false,
        showAxle3: false,
        showAxle4: false
    });

    let handleAxle = () => {
        let showAxle1 = false;
        let showAxle2 = false;
        let showAxle3 = false;
        let showAxle4 = false;
        if (state.vehicleNoOfAxles) {
            switch (state.vehicleNoOfAxles) {
                case 1:
                    showAxle1 = true;
                    break;
                case 2:
                    showAxle1 = true;
                    showAxle2 = true;
                    break;
                case 3:
                    showAxle1 = true;
                    showAxle2 = true;
                    showAxle3 = true;
                    break;
                case 4:
                    showAxle1 = true;
                    showAxle2 = true;
                    showAxle3 = true;
                    showAxle4 = true;
                    break;

                default:
                    break;
            }
        } else {
            showAxle1 = true;
            showAxle2 = true;
            showAxle3 = true;
            showAxle4 = true;
        }

        setShow((st) => ({
            ...st,
            showAxle1: showAxle1,
            showAxle2: showAxle2,
            showAxle3: showAxle3,
            showAxle4: showAxle4
        }));
    };

    useEffect(() => {
        handleAxle();
    }, [state.vehicleNoOfAxles]);

    const GlobalCss = withStyles({
        '@global': {
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        }
    })(() => null);

    return (
        <>
            <GlobalCss />
            <Grid container spacing={2} style={style.ax2Break} justify="center">
                {/* ..................Axel 1...................... */}
                {show.showAxle1 && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Grid item xs={12} sm={12}>
                            <h3 className={classes.axelTitles}>AXLE 1</h3>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container justify="space-evenly" spacing={1}>
                                <Grid item xs={3}>
                                    <InputLabel shrink error style={style.axOS}>
                                        Depth*
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        placeholder="mm"
                                        name="osTreadDepth"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        onChange={handleInputs(1)}
                                        value={state.inspectionSheetAxlesVM[0]?.osTreadDepth}
                                    />
                                    <InputLabel shrink error style={style.axOS2}>
                                        Depth*
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="nsTreadDepth"
                                        onChange={handleInputs(1)}
                                        value={state.inspectionSheetAxlesVM[0]?.nsTreadDepth}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel shrink error>
                                        Brakelining*
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="osBrakeLineRemaining"
                                        onChange={handleInputs(1)}
                                        value={state.inspectionSheetAxlesVM[0]?.osBrakeLineRemaining || null}
                                    />
                                    {/* <SimpleField
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                        }}
                                        type="number"
                                        placeholder="%"
                                        name="osBrakeLineRemaining"
                                        onChange={handleInputs(1)}
                                        value={state.inspectionSheetAxlesVM[0]?.osBrakeLineRemaining|| null}
                                    /> */}
                                    <img
                                        src={singleAxel}
                                        height="250px"
                                        width="100%"
                                        style={{
                                            marginTop: 5
                                        }}
                                    />
                                    <InputLabel shrink error>
                                        Brakelining*
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="nsBrakeLineRemaining"
                                        onChange={handleInputs(1)}
                                        value={state.inspectionSheetAxlesVM[0]?.nsBrakeLineRemaining || null}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <InputLabel shrink error style={style.axOS}>
                                                Pressure*
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                placeholder="psi"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                name="osTyrePressure"
                                                onChange={handleInputs(1)}
                                                value={state.inspectionSheetAxlesVM[0]?.osTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink error style={style.axOS}>
                                                Tyre{' '}
                                                {state.inspectionSheetAxlesVM[0]?.osTyreMaker && (
                                                    <b onClick={handleInspection} style={{ cursor: 'pointer' }}>
                                                        {'>>'}
                                                    </b>
                                                )}
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                name="osTyreMaker"
                                                onChange={handleInputs(1)}
                                                value={state.inspectionSheetAxlesVM[0]?.osTyreMaker}
                                                inputProps={{ maxLength: 2 }}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        {/* <Grid container spacing={2}> */}
                                        <Grid item xs={6}>
                                            <InputLabel shrink error style={style.ax2NSNew}>
                                                Pressure*
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                placeholder="psi"
                                                name="nsTyrePressure"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                onChange={handleInputs(1)}
                                                value={state.inspectionSheetAxlesVM[0]?.nsTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink error style={style.ax2NSNew}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                name="nsTyreMaker"
                                                inputProps={{ maxLength: 2 }}
                                                onChange={handleInputs(1)}
                                                value={state.inspectionSheetAxlesVM[0]?.nsTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <FormHelperText error style={{ textAlign: 'center' }}>
                                {state.errors?.axelone}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                )}
                {/* ..................Axel 2 ..................... */}
                {show.showAxle2 && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <h3 className={classes.axelTitles}>AXLE 2</h3>
                        <Grid item xs={12} sm={12}>
                            <Grid container xs={12} direction="row" justify="space-evenly" spacing={1}>
                                <Grid item xs={3}>
                                    <InputLabel shrink style={style.ax2OS} error>
                                        Depth*
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="osTreadDepth"
                                        onChange={handleInputs(2)}
                                        value={state.inspectionSheetAxlesVM[1]?.osTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2OS2} error>
                                        Depth*
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="osFrontTreadDepth"
                                        onChange={handleInputs(2)}
                                        value={state.inspectionSheetAxlesVM[1]?.osFrontTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2NS} error>
                                        Depth*
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="nsFrontTreadDepth"
                                        onChange={handleInputs(2)}
                                        value={state.inspectionSheetAxlesVM[1]?.nsFrontTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2OS2} error>
                                        Depth*
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="nsTreadDepth"
                                        onChange={handleInputs(2)}
                                        value={state.inspectionSheetAxlesVM[1]?.nsTreadDepth}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <img src={doubleAxelOne} height="100px" width="100%" />
                                    <InputLabel shrink error style={style.axBreakHeading}>
                                        Brakelining*
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="osBrakeLineRemaining"
                                        onChange={handleInputs(2)}
                                        value={state.inspectionSheetAxlesVM[1]?.osBrakeLineRemaining || null}
                                    />
                                    <img src={doubleAxelTwo} height="45px" width="100%" />
                                    <InputLabel shrink error style={style.axBreakHeading}>
                                        Brakelining*
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="nsBrakeLineRemaining"
                                        onChange={handleInputs(2)}
                                        value={state.inspectionSheetAxlesVM[1]?.nsBrakeLineRemaining || null}
                                        style={style.ax2Break}
                                    />
                                    <img src={doubleAxelThree} height="100px" width="100%" />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2OS} error>
                                                Pressure*
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="osTyrePressure"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.osTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2OS} error>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="osTyreMaker"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.osTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2} error>
                                                Pressure*
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="osFrontTyrePressure"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.osFrontTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2} error>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="osFrontTyreMaker"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.osFrontTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2NSNew} error>
                                                Pressure*
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="nsFrontTyrePressure"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.nsFrontTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2NSNew} error>
                                                Tyre*
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="nsFrontTyreMaker"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.nsFrontTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2} error>
                                                Pressure*
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="nsTyrePressure"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.nsTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2} error>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                type="text"
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="nsTyreMaker"
                                                onChange={handleInputs(2)}
                                                value={state.inspectionSheetAxlesVM[1]?.nsTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <FormHelperText error style={{ textAlign: 'center' }}>
                            {state.errors?.axeltwo}
                        </FormHelperText>
                    </Grid>
                )}

                {/* ..................Axel 3 ..................... */}
                {show.showAxle3 && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <h3 className={classes.axelTitles}>AXLE 3</h3>
                        <Grid item xs={12} sm={12}>
                            <Grid container xs={12} direction="row" justify="space-evenly" spacing={1}>
                                <Grid item xs={3}>
                                    <InputLabel shrink style={style.ax2OS}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="osTreadDepth"
                                        onChange={handleInputs(3)}
                                        value={state.inspectionSheetAxlesVM[2]?.osTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2OS2}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="osFrontTreadDepth"
                                        onChange={handleInputs(3)}
                                        value={state.inspectionSheetAxlesVM[2]?.osFrontTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2NS}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="nsFrontTreadDepth"
                                        onChange={handleInputs(3)}
                                        value={state.inspectionSheetAxlesVM[2]?.nsFrontTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2OS2}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="nsTreadDepth"
                                        onChange={handleInputs(3)}
                                        value={state.inspectionSheetAxlesVM[2]?.nsTreadDepth}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <img src={doubleAxelOne} height="100px" width="100%" />
                                    <InputLabel shrink style={style.axBreakHeading}>
                                        Brakelining
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="osBrakeLineRemaining"
                                        onChange={handleInputs(3)}
                                        value={state.inspectionSheetAxlesVM[2]?.osBrakeLineRemaining || null}
                                    />
                                    <img src={doubleAxelTwo} height="45px" width="100%" />
                                    <InputLabel shrink style={style.axBreakHeading}>
                                        Brakelining
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="nsBrakeLineRemaining"
                                        onChange={handleInputs(3)}
                                        value={state.inspectionSheetAxlesVM[2]?.nsBrakeLineRemaining || null}
                                        style={style.ax2Break}
                                    />
                                    <img src={doubleAxelThree} height="100px" width="100%" />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2OS}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="osTyrePressure"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.osTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2OS}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="osTyreMaker"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.osTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="osFrontTyrePressure"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.osFrontTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="osFrontTyreMaker"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.osFrontTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2NSNew}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="nsFrontTyrePressure"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.nsFrontTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2NSNew}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="nsFrontTyreMaker"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.nsFrontTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="nsTyrePressure"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.nsTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                type="text"
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="nsTyreMaker"
                                                onChange={handleInputs(3)}
                                                value={state.inspectionSheetAxlesVM[2]?.nsTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <FormHelperText error style={{ textAlign: 'center' }}>
                            {state.errors?.axelthree}
                        </FormHelperText>
                    </Grid>
                )}

                {/* ..................Axel 4 ..................... */}
                {show.showAxle4 && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <h3 className={classes.axelTitles}>AXLE 4</h3>
                        <Grid item xs={12} sm={12}>
                            <Grid container xs={12} direction="row" justify="space-evenly" spacing={1}>
                                <Grid item xs={3}>
                                    <InputLabel shrink style={style.ax2OS}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="osTreadDepth"
                                        onChange={handleInputs(4)}
                                        value={state.inspectionSheetAxlesVM[3]?.osTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2OS2}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="osFrontTreadDepth"
                                        onChange={handleInputs(4)}
                                        value={state.inspectionSheetAxlesVM[3]?.osFrontTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2NS}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="nsFrontTreadDepth"
                                        onChange={handleInputs(4)}
                                        value={state.inspectionSheetAxlesVM[3]?.nsFrontTreadDepth}
                                    />
                                    <InputLabel shrink style={style.ax2OS2}>
                                        Depth
                                    </InputLabel>
                                    <SimpleField
                                        type="number"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
                                        }}
                                        placeholder="mm"
                                        name="nsTreadDepth"
                                        onChange={handleInputs(4)}
                                        value={state.inspectionSheetAxlesVM[3]?.nsTreadDepth}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <img src={doubleAxelOne} height="100px" width="100%" />
                                    <InputLabel shrink style={style.axBreakHeading}>
                                        Brakelining
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="osBrakeLineRemaining"
                                        onChange={handleInputs(4)}
                                        value={state.inspectionSheetAxlesVM[3]?.osBrakeLineRemaining || null}
                                    />
                                    <img src={doubleAxelTwo} height="45px" width="100%" />
                                    <InputLabel shrink style={style.axBreakHeading}>
                                        Brakelining
                                    </InputLabel>
                                    <SelectBox
                                        List={state.inspectionSheetBrakeLiningsVM}
                                        name="nsBrakeLineRemaining"
                                        onChange={handleInputs(4)}
                                        value={state.inspectionSheetAxlesVM[3]?.nsBrakeLineRemaining || null}
                                        style={style.ax2Break}
                                    />
                                    <img src={doubleAxelThree} height="100px" width="100%" />
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2OS}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="osTyrePressure"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.osTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2OS}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="osTyreMaker"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.osTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="osFrontTyrePressure"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.osFrontTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="osFrontTyreMaker"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.osFrontTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2NSNew}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="nsFrontTyrePressure"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.nsFrontTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputLabel shrink style={style.ax2NSNew}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="nsFrontTyreMaker"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.nsFrontTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Pressure
                                            </InputLabel>
                                            <SimpleField
                                                type="number"
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                                                }}
                                                placeholder="psi"
                                                name="nsTyrePressure"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.nsTyrePressure}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {' '}
                                            <InputLabel shrink style={style.ax2OS2}>
                                                Tyre
                                            </InputLabel>
                                            <SimpleField
                                                type="text"
                                                placeholder="make"
                                                inputProps={{ maxLength: 2 }}
                                                name="nsTyreMaker"
                                                onChange={handleInputs(4)}
                                                value={state.inspectionSheetAxlesVM[3]?.nsTyreMaker}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <FormHelperText error style={{ textAlign: 'center' }}>
                            {state.errors?.axelfour}
                        </FormHelperText>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormHelperText error style={{ textAlign: 'center' }}>
                        {state.showTost ? state.axleErrors.message : ''}
                    </FormHelperText>
                </Grid>
            </Grid>
        </>
    );
});

export default Axel;
