import React, { useEffect, useState, useMemo } from 'react';
import { getAllVehicle } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters, CustomChip } from '../../../../Core/Controls';
import AddEditVehicle from './addEditVehicle';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import EditVehicle from './editVehicle';
import { Link } from 'react-router-dom';
import moment from 'moment';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import CustomerDetails from '../DetailsModal/customerDetails';
import DepotDetails from '../DetailsModal/depotDetails';
import ServiceAgentDetails from '../DetailsModal/serviceAgent';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import Switch from '@material-ui/core/Switch';
import { Button, ButtonGroup } from '@material-ui/core';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import AddIcon from '@material-ui/icons/Add';

const baseColumns = [
    // {
    //     key: 'action',
    //     name: 'Action',
    //     width: 80,

    //     addFileUploaderIcon: true
    // },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
    { key: 'vehicleMakeModel', name: 'Vehicle Description', width: 220 },
    {
        key: 'vehicleDateOfRegistration',
        name: 'Date Of Reg',
        formatter: formatters.Date_DDMMYYYY,
        width: 90
    },
    {
        key: 'vehicleMOTExpiry',
        name: 'MOT Expiry',
        formatter: formatters.dueDateFormatter,
        width: 90
    },
    {
        key: 'vehicleTaxDue',
        name: 'Tax Due',
        formatter: formatters.dueDateFormatter,
        width: 90
    },
    { key: 'mileage', name: 'Mileage', width: 90 },
    { key: 'driver', name: 'Driver', width: 90 },
    { key: 'customerCompanyName', name: 'Customer', width: 100 },
    { key: 'depotName', name: 'Depot', width: 100 },
    { key: 'endUser', name: 'End User', width: 100 }
];

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', path: '/controller/Vehicles/:all', active: true }
];

const VehicleScreen = (props) => {
    const { userRoles } = useLoginInfo();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastError } = useToast();
    const [state, setState] = useState({
        show: false,
        showEditPopup: false,
        showLoader: true,
        vehicleList: [],
        VehicleID: null,
        isReload: null,

        activeToggle: true
    });

    let history = useHistory();
    const isEngineer = userRoles.includes('engineer');
    const isServicearea = userRoles.includes('servicearea');
    const pullVehicleAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        let url = props.location.pathname;
        let urlIndex = url.lastIndexOf('/') + 1;
        let filterID = '';
        if (url[urlIndex] === ':' || url[urlIndex] === undefined || url[urlIndex] === null) {
            filterID = '';
        } else {
            filterID = url[urlIndex];
        }
        setState((st) => ({
            ...st,
            filterID: filterID
        }));
    }, [props.location.pathname]);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            VehicleID: record?.vehicleID
        }));
    };

    const closeVehicleModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullVehicleAndUpdateState();
    };

    const closeByBredCrumb = () => {
        setState((st) => ({
            ...st,
            show: false
        }));
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.sa}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false,
            showDepotModal: false,
            showSaModal: false
        }));
    };

    const dataFormatter = (id, val) => {
        return null;
        // return <Grid item>{/* <EditButton toolTipTitle="Edit Vehicle" onClick={() => handleEditClick(val)} /> */}</Grid>;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let url = props.location.pathname;
        let urlIndex = url.lastIndexOf('/') + 1;
        if (url[urlIndex] !== 'T' && url[urlIndex] !== 'I') {
            tempCols.splice(
                2,
                9,
                // { key: '', name: 'Asset Number', width: 110 },
                { key: 'vehicleAssetNumber', name: 'Serial Number', width: 120 },
                { key: 'customerCompanyName', name: 'Customer Name', width: 200 },
                { key: 'depotName', name: 'Depot', width: 180 },
                { key: 'vehicleDateOfRegistration', name: 'Date of Reg', formatter: formatters.Date_DDMMYYYY, width: 100 },
                { key: 'costCentres', name: 'Cost Centre', width: 110 },
                { key: 'calibrationExpiry', name: 'Calibration Expiry', formatter: formatters.dueDateFormatter, width: 140 },
                { key: 'sa', name: 'Service Area', width: 120 },
                { key: 'tech', name: 'Engineer', width: 150 },
                { key: 'vehicleCategories', name: 'Vehicle Category', width: 135 },
                { key: 'vehicleType', name: 'Vehicle Type', width: 120 },
                { key: 'vehicleMOTExpiry', name: 'MOT Expiry', formatter: formatters.dueDateFormatter, width: 100 },
                { key: 'vehicleTaxDue', name: 'Tax Due', formatter: formatters.dueDateFormatter, width: 90 },
                { key: 'ownershipType', name: 'Ownership Type', width: 130 }
            );
        }
        if (isEngineer) {
            tempCols.splice(
                0,
                tempCols.length,
                { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
                { key: 'vehicleAssetNumber', name: 'Serial Number', width: 120 },
                { key: 'customerCompanyName', name: 'Customer Name', width: 200 },
                { key: 'depotName', name: 'Depot', width: 180 },
                { key: 'ownershipType', name: 'Ownership Type', width: 130 },
                { key: 'vehicleType', name: 'Vehicle Type', width: 120 }
            );
        }
        if (isServicearea) {
            tempCols.splice(
                0,
                tempCols.length,
                { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
                { key: 'vehicleAssetNumber', name: 'Serial Number', width: 120 },
                { key: 'customerCompanyName', name: 'Customer Name', width: 200 },
                { key: 'depotName', name: 'Depot', width: 180 },
                { key: 'ownershipType', name: 'Ownership Type', width: 130 },
                { key: 'vehicleType', name: 'Vehicle Type', width: 120 },
                { key: 'vehicleDateOfRegistration', name: 'Date of Reg', formatter: formatters.Date_DDMMYYYY, width: 100 }
            );
        }
        let actionCol = tempCols.find((element) => element.key === 'action');
        let customerNameCol = tempCols.find((element) => element.key === 'customerCompanyName');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        let agentNameCol = tempCols.find((element) => element.key === 'sa');
        let regNumberCol = tempCols.find((element) => element.key === 'vehicleRegNumber');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        return tempCols;
    }, []);

    const crumbs = useMemo(() => {
        const tempCrums = [...baseCrumbs];
        let url = props.location.pathname;
        let urlIndex = url.lastIndexOf('/') + 1;
        if (url[urlIndex] === 'T') {
            tempCrums.push({ name: 'Tax Due' });
        }
        if (url[urlIndex] === 'I') {
            tempCrums.push({ name: 'Insurance' });
        }
        return tempCrums;
    }, [props.location.pathname]);

    const title = state.VehicleID ? 'Update Vehicle' : 'Add Vehicle';

    const handleChange = (e) => {
        const { name, checked } = e.target;

        setState((st) => ({
            ...st,
            [name]: checked
        }));
    };
    const handleFilter = (cond) => {
        setState((st) => ({
            ...st,
            activeToggle: cond
        }));
    };

    return (
        <div className="screen">
            {state.show ? (
                <AddEditVehicle VehicleID={state.VehicleID} onFormSubmit={() => closeVehicleModal(true)} crumbClose={closeByBredCrumb} />
            ) : (
                <>
                    {' '}
                    <Grid container justify="space-between">
                        <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            <div>
                                <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                            </div>
                            <div>&nbsp;&nbsp;</div>
                            <div>
                                <CustomChip
                                    icon={AddIcon}
                                    label="Add Vehicle"
                                    onClick={handleAdd}
                                    toolTipTitle="Add Vehicle"
                                    style={{ fontWeight: 'bold' }}
                                ></CustomChip>
                            </div>
                        </Grid>
                        <Grid component="label" item alignItems="center">
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button style={{ fontSize: 12 }} variant={state.activeToggle ? 'contained' : 'outlined'} onClick={() => handleFilter(true)}>
                                    Active
                                </Button>
                                <Button style={{ fontSize: 12 }} variant={!state.activeToggle ? 'contained' : 'outlined'} onClick={() => handleFilter(false)}>
                                    Archived
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <BreadCrumbs crumbs={crumbs} />

                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <span style={{ marginTop: '7px' }}>Archived</span>
                            <Switch checked={state.activeToggle} onChange={handleChange} value="activeToggle" name="activeToggle" />
                            <span style={{ marginTop: '7px' }}>Active</span>
                        </div>
                    </div> */}
                    <GijgoGrid
                        columns={columns}
                        getUrl={`vehicles?mode=${state.filterID}&Active=${state.activeToggle}`}
                        idFieldName={`${checkhaveAccess?.CanUploadVehicleDocuments ? 'vehicleID' : null}`}
                        fileUploaderConfigName={`${checkhaveAccess?.CanUploadVehicleDocuments ? 'VehicleScreen' : null}`}
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                        // onBtnClick={handleAdd}
                        // btnTitle={`${checkhaveAccess?.CanUpdateVehicles ? 'Add Vehicle' : ''}`}
                    />
                </>
            )}
            {state.showEditPopup ? <EditVehicle /> : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Area Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.vehiclePrimaryServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.vehicleCustomerId} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.vehicleDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default VehicleScreen;
