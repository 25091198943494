import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SyncIcon from '@material-ui/icons/Sync';
import DoneIcon from '@material-ui/icons/Done';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import './jobs.scss';
import { getAllJobDropDowns } from '../../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import AssignJobStep from './AssignStep';
import JobsWorkInProgress from './WorkInProgressStep';
import JobTechSignOff from './TechnecianSignOffStep';
import JobServiceArea from './ServiceAreaStep';
import JobAdminstration from './Administrationstep';
import { Grid } from '@material-ui/core';
import { formatters } from '../../../../../../Core/Controls';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    active: {
        '& $line': {
            borderColor: '#784af4'
        }
    },
    completed: {
        '& $line': {
            borderColor: '#784af4'
        }
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    active: {
        color: '#784af4'
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor'
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 15
    },
    active: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    completed: {
        '& $line': {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
        }
    },
    line: {
        height: 5,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1
    }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: 'black',
        width: 35,
        height: 35,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    active: {
        backgroundImage: 'linear-gradient( 136deg, #0f9b0f  30%, #0b4d0b 80%, #031a03 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        color: '#fff'
    },

    completed: {
        backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        color: '#fff'
    }
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <ReportProblemIcon style={{ fontSize: 16 }} />,
        2: <GroupAddIcon style={{ fontSize: 16 }} />,
        3: <SyncIcon style={{ fontSize: 16 }} />,
        4: <DoneIcon style={{ fontSize: 16 }} />,
        5: <QueryBuilderIcon style={{ fontSize: 16 }} />,
        6: <ImportantDevicesIcon style={{ fontSize: 16 }} />,
        7: <AssignmentTurnedInIcon style={{ fontSize: 16 }} />
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

let workInProgConfig = {
    AP: {
        label: 'Awaiting Parts',
        date: 'jobAwaitingPartsDate',
        userName: 'jobAwaitingPartsUserName'
    },
    ES: {
        label: 'Submitted for Estimate',
        date: 'jobEstimatedDate',
        userName: 'jobEstimatedUserName'
    },
    EA: {
        label: 'Estimate Approved',
        date: 'jobEstimateApprovedDate',
        userName: 'jobEstimateApprovedUserName'
    },
    JP: {
        label: 'Paused',
        date: 'jobPausedDate',
        userName: 'jobPausedUserName'
    },
    WP: {
        label: 'Work In Progress',
        date: 'jobWorkInProgressDate',
        userName: 'jobWorkInProgressUserName'
    },
    TA: { label: 'Not Started' }
};
function getSteps(val) {
    return [
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        Created
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobCreatedUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobDateCreated)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {val.jobEngineerUserID ? 'Assigned' : 'Unassigned'}
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobEngineer?.toUpperCase()}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobDateEngineerAssigned)}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>{val.jobEngineerAssignedUserName && <b>({val.jobEngineerAssignedUserName})</b>}</small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {workInProgConfig[val.jobStatusID]?.label
                            ? workInProgConfig[val.jobStatusID]?.label
                            : !val.jobEngineerUserID
                            ? 'Not Started'
                            : 'Work in Progress'}
                    </Grid>
                    {/* {val.jobStatusID === 'FI' ? (
                        <Grid item xs={12}>
                            <small style={{ fontSize: 10, fontWeight: 700 }}>
                                <b>Factory Inspection </b>
                            </small>
                        </Grid>
                    ) : ( */}
                    <>
                        <Grid item xs={12}>
                            <small style={{ fontSize: 10, fontWeight: 700 }}>
                                <b> {val[`${workInProgConfig[val.jobStatusID]?.userName}`] || val?.jobWorkInProgressUserName}</b>
                            </small>
                        </Grid>
                        <Grid item xs={12}>
                            <small style={{ fontSize: 10, fontWeight: 700 }}>
                                <b>{formatters.DateTimeFormatter(val[`${workInProgConfig[val.jobStatusID]?.date}`] || val.jobWorkInProgressDate)}</b>
                            </small>
                        </Grid>
                    </>
                    {/* )} */}
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        Technician Sign off
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobSignOffUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobSignOffDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {!val.jobCostReviewedDate ? 'Awaiting Cost Review' : 'Cost Reviewed'}
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobCostReviewedUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobCostReviewedDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        {'Administration'}
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{(val.jobStatusID === 'AS' || val.jobStatusID === 'IN') && val.vehicleJobStatus}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobStatusID === 'AS' ? val.jobProformaCreatedUserName : val.jobApprovedUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobStatusID === 'AS' ? val.jobProformaDateCreated : val.jobApprovedDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        },
        {
            label: (
                <Grid container>
                    <Grid item xs={12}>
                        Completed
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{val.jobCompletionUserName}</b>
                        </small>
                    </Grid>
                    <Grid item xs={12}>
                        <small style={{ fontSize: 10, fontWeight: 700 }}>
                            <b>{formatters.DateTimeFormatter(val.jobCompletionDate)}</b>
                        </small>
                    </Grid>
                </Grid>
            )
        }
    ];
}

export default function JobCustomizeStepper(props) {
    const screenStatus = {
        AA: 1,
        TA: 2,
        WP: props.jobDetails.outstandingLines === 0 ? 3 : 2,
        TS: 4,
        CR: 5,
        AS: 5,
        IN: 6,
        AP: 2,
        ES: 2,
        EA: 2,
        JP: 2
    };
    const { userRoles } = useLoginInfo();

    const isServiceAgent = userRoles.includes('servicearea');
    const isCustomer = userRoles.includes('customer');
    const isinternal = userRoles.includes('internal');
    const isEngineer = userRoles.includes('engineer');

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(screenStatus[props.jobDetails.jobStatusID]);
    // const [activeStep, setActiveStep] = React.useState(props.jobDetails.outstandingLines);
    const [modalStep, setModalStep] = React.useState(-1);
    const steps = getSteps(props.jobDetails || {});
    const [state, setState] = useState({
        modalIndex: 0,
        modalLabel: '',
        customersList: []
    });

    useEffect(() => {
        if (props?.jobDetails?.jobStatusID) {
            setActiveStep(screenStatus[props.jobDetails.jobStatusID]);
        }
    }, [props.jobDetails.jobStatusID]);

    const handleNext = () => {
        closeSteps();
        props.pullJobDetail();
    };

    useEffect(() => {
        setActiveStep(screenStatus[props.jobDetails.jobStatusID]);
    }, [props.jobDetails.outstandingLines]);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return null;
            case 1:
                return activeStep === 1 ? (
                    <DialogComp title={'Assign Engineer'} onClose={closeSteps} maxWidth="sm" fullWidth>
                        <AssignJobStep
                            isDisabled={!isCustomer && activeStep === 1}
                            handleNext={handleNext}
                            technicianList={state.technicianList || []}
                            serviceAgentList={state.serviceAgentList}
                            jobDetails={props.jobDetails}
                        />
                    </DialogComp>
                ) : null;
            case 2:
                return activeStep === 2 || activeStep === 3 ? (
                    <DialogComp
                        title={workInProgConfig[props.jobDetails.jobStatusID]?.label || 'Work In Progress'}
                        onClose={closeSteps}
                        maxWidth="md"
                        fullWidth
                    >
                        <JobsWorkInProgress handleNext={handleNext} jobDetails={props.jobDetails} isDisabled={!isCustomer && activeStep < 5} />
                    </DialogComp>
                ) : null;
            case 3:
                return activeStep === 3 ? (
                    <DialogComp title={'Technician Signoff'} onClose={closeSteps} maxWidth="sm" fullWidth>
                        <JobTechSignOff
                            handleNext={handleNext}
                            jobDetails={props.jobDetails}
                            isEngineer={isEngineer}
                            isServiceAgent={isServiceAgent}
                            isDisabled={!isCustomer && activeStep === 3}
                        />
                    </DialogComp>
                ) : null;
            case 4:
                return activeStep === 4 ? (
                    <DialogComp title={'Cost Review'} onClose={closeSteps} maxWidth="sm" fullWidth>
                        <JobServiceArea
                            handleNext={handleNext}
                            onClose={closeSteps}
                            jobDetails={props.jobDetails}
                            isServiceAgent={isServiceAgent}
                            isinternal={isinternal}
                            isDisabled={!isCustomer && activeStep === 4}
                        />
                    </DialogComp>
                ) : null;
            case 5:
                return activeStep === 5 || activeStep === 6 ? (
                    <DialogComp title={'Administration'} onClose={closeSteps} maxWidth="lg" fullWidth>
                        <JobAdminstration
                            handleNext={handleNext}
                            jobStatus={props.jobDetails.jobStatusID}
                            onClose={closeSteps}
                            jobDetails={props.jobDetails}
                            customersList={state.customersList}
                            isDisabled={!isCustomer && (activeStep === 4 || activeStep === 5) && isinternal}
                        />
                    </DialogComp>
                ) : null;
            default:
                return null;
        }
    }

    const handleSteps = (tabindex, tablable) => {
        try {
            setState((st) => ({ ...st, modalIndex: tabindex, modalLabel: tablable }));
            setModalStep(tabindex);
        } catch (error) {
            console.warn(error);
        }
    };

    const closeSteps = () => {
        try {
            setState((st) => ({ ...st, modalIndex: 0, modalLabel: null }));
            setModalStep(0);
        } catch (error) {
            console.warn(error);
        }
    };

    useEffect(() => {
        let fetchData = async () => {
            if (props.jobDetails.jobID) {
                try {
                    let res = await getAllJobDropDowns(props.jobDetails.jobID);
                    setState((state) => ({
                        ...state,
                        serviceAgentList: res.data?.serviceAgents || [],
                        technicianList: res.data?.technician || [],
                        customersList: res.data.customers || []
                    }));
                } catch (error) {
                    console.warn(error);
                }
            }
        };

        return fetchData();
    }, [props.VehicleID]);

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map((label, i) => (
                    <Step
                        key={label}
                        onClick={
                            props.jobDetails.jobStatusID === 'IN'
                                ? () => {}
                                : () => {
                                      handleSteps(i, label.label);
                                  }
                        }
                    >
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            {getStepContent(modalStep)}
        </div>
    );
}
